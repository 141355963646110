import React, {memo} from 'react'

import {Button, Col, Divider, Row} from 'antd'
import {FormInstance} from 'antd/lib/form'

import {useCompareEffect} from '@/utils'
import {Form, FormItem} from '@/components/Form'

import styles from './styles.module.less'

interface Props {
  deliveryInfo?: any
  onFinish?: (values: any, form: FormInstance) => void
  buttonTitle?: string
  buttonLoading?: boolean
}

const DeliveryForm = ({deliveryInfo, buttonLoading, onFinish, buttonTitle}: Props) => {
  const [form] = Form.useForm()

  const Finish = (values: any) => {
    onFinish?.(values, form)
  }

  useCompareEffect(() => {
    if (deliveryInfo != null && deliveryInfo.legalEntity != null && deliveryInfo.legalEntitySetting != null) {
      form.setFieldsValue(deliveryInfo)
      form.setFieldsValue({legalEntityName: deliveryInfo.legalEntity.name})
      form.setFieldsValue({legalEntityNamePhonetic: deliveryInfo.legalEntity.namePhonetic})
      form.setFieldsValue({handleableDeliveryCompanies: deliveryInfo.legalEntitySetting.handleableDeliveryCompanies})
    }
  }, [deliveryInfo])

  return (
    <Form layout="horizontal" colon={false} form={form} onFinish={Finish} hideRequiredMark className={styles.form}>
      <FormItem.SubTitle title="送付先情報" />
      <RowWithCol2
        col1={<FormItem.LegalEntityName noPlaceHolder readOnly />}
        col2={<FormItem.LegalEntityNamePhonetic noPlaceHolder readOnly />}
      />
      <RowWithCol2
        col1={<FormItem.FamilyName noPlaceHolder readOnly />}
        col2={<FormItem.GivenName noPlaceHolder readOnly />}
      />
      <RowWithCol3
        col1={<FormItem.Zipcode noPlaceHolder readOnly form={form} />}
        col2={<FormItem.Prefecture noPlaceHolder readOnly />}
        col3={<FormItem.City noPlaceHolder readOnly />}
      />
      <RowWithCol2
        col1={<FormItem.Street noPlaceHolder readOnly />}
        col2={<FormItem.Building noPlaceHolder readOnly />}
      />
      <FormItem.Tel noPlaceHolder readOnly />

      <FormItem.SubTitle title="取り扱い可能な配送業者" />
      <FormItem.HandleableDeliveryCompanies noPlaceHolder readOnly />
      <FormItem.SubTitle title="追跡番号情報" />
      <FormItem.TrackNumber noPlaceHolder />
      <FormItem.DeliveryCompany noPlaceHolder readOnly form={form} />
      <FormItem.OtherDeliveryCompany noPlaceHolder />
      <Divider style={{marginTop: 0}} />
      <div style={{textAlign: 'center'}}>
        <Button type="primary" htmlType="submit" className={styles.submitButton} loading={buttonLoading}>
          {buttonTitle}
        </Button>
      </div>
    </Form>
  )
}

const RowWithCol2 = ({col1, col2}: {col1: React.ReactNode; col2: React.ReactNode}) => (
  <Row className="col2">
    <Col span={8}>{col1}</Col>
    <Col flex={1}>{col2}</Col>
  </Row>
)

const RowWithCol3 = ({col1, col2, col3}: {col1: React.ReactNode; col2: React.ReactNode; col3: React.ReactNode}) => (
  <Row className="col3">
    <Col span={8}>{col1}</Col>
    <Col span={8}>{col2}</Col>
    <Col span={8}>{col3}</Col>
  </Row>
)

export default memo(DeliveryForm)
