import axios from 'axios'
import requestInterceptor from './interceptors/requestInterceptor'
import requestErrorInterceptor from './interceptors/requestErrorInterceptor'
import responseInterceptor from './interceptors/responseInterceptor'
import responseErrorInterceptor from './interceptors/responseErrorInterceptor'

const RefreshTokenKey = 'rt'
const AccessTokenKey = 'at'
const ExpireInTimeKey = 'eit'
const expireInTimeOffset = 120

class Token {
  constructor() {
    if (new.target === Token) throw new Error('cant instantiate')
  }

  static accessToken = localStorage.getItem(AccessTokenKey) || ''

  static refreshToken = localStorage.getItem(RefreshTokenKey) || ''

  static expireInTime = parseInt(localStorage.getItem(ExpireInTimeKey) as string, 10) || 0

  static updateToken = ({
    accessToken,
    refreshToken,
    expiresIn,
  }: {
    accessToken: string
    refreshToken: string
    expiresIn: string
  }): void => {
    Token.accessToken = accessToken
    Token.refreshToken = refreshToken
    Token.expireInTime = new Date().getTime() + (Number(expiresIn) - expireInTimeOffset) * 1000
    localStorage.setItem(AccessTokenKey, Token.accessToken)
    localStorage.setItem(RefreshTokenKey, Token.refreshToken)
    localStorage.setItem(ExpireInTimeKey, Token.expireInTime as any)
  }

  static clearToken = (): void => {
    Token.accessToken = ''
    Token.refreshToken = ''
    Token.expireInTime = 0
    localStorage.removeItem(AccessTokenKey)
    localStorage.removeItem(RefreshTokenKey)
    localStorage.removeItem(ExpireInTimeKey)
  }
}

const request = axios.create({
  timeout: 20 * 1000,
  headers: {nt: true, st: true}, // needToken,showToast
})

request.interceptors.request.use(requestInterceptor, requestErrorInterceptor)
request.interceptors.response.use(responseInterceptor, responseErrorInterceptor)

export {request, Token}
