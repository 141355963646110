import React, {FC, memo, useState} from 'react'
import {Button, Skeleton} from 'antd'
import {useMount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'

import classnames from 'classnames'
import _ from 'lodash'

import Image, {ImgStore} from '@/components/Image'
import {history} from '@/routes'
import {AccountActions, UIActions} from '@/actions'
import {Enums} from '@/constants'

import {AccountConstants} from '@/store/constants'
import {isMobile} from '@/utils'

import styles from './styles.module.less'

const Template: FC<{isSuccess?: boolean; loading?: boolean}> = ({children, isSuccess = true, loading}) => {
  const backLogin = () => history.push('/login')
  return (
    <Skeleton loading={loading} avatar paragraph={{rows: 1}}>
      <Image
        src={isSuccess ? ImgStore.Success : ImgStore.Warning}
        spacing={10}
        className={classnames(styles.contentContainer)}
        textClassName={classnames(styles.textContainer)}
        text={
          <div style={{padding: 5, paddingTop: 0}}>
            {children}
            {isMobile ? null : (
              <Button className={styles.button} onClick={backLogin}>
                ログイン画面へ
              </Button>
            )}
          </div>
        }
      />
    </Skeleton>
  )
}

const TemporarySuccessResult = () => {
  const dispatch = useDispatch()
  useMount(() => {
    dispatch(UIActions.resetBackBreadcrumb({backWay: '/account/register/temporary'}))
  })
  return (
    <Template>
      <h3 className={styles.title}>仮アカウントの登録が完了しました</h3>
      <p style={{marginBottom: 8}}>入力いただいたメールアドレス宛てに、仮パスワードをメール送付しました。</p>
      <p>メールに記載のあるパスワードを利用し、ログインして下さい。</p>
      <p>初回ログイン時に、パスワードの変更をお願い致します。</p>
    </Template>
  )
}

const FormalSuccessResult = () => {
  const dispatch = useDispatch()
  useMount(() => {
    dispatch(UIActions.resetBackBreadcrumb({backWay: '/account/register/formal'}))
  })
  return (
    <Template>
      <h3 className={styles.title}>本アカウントの申請が完了しました</h3>
      <p style={{marginBottom: 8}}>
        申請情報確認後、本アカウント発行完了のお知らせを登録頂いたメールアドレス宛に送付致します。
      </p>
      <p>出品機能を利用される方は、上記お知らせを受領後、クレジットカードでの 決済をお願い致します。</p>
    </Template>
  )
}

const SubAccountCreatedSuccessResult = () => {
  const dispatch = useDispatch()
  useMount(() => {
    dispatch(UIActions.resetBackBreadcrumb({backWay: '/account/subAccount'}))
  })

  return (
    <Template>
      <h3 className={styles.title} style={{marginTop: 5}}>
        サブアカウントの申請が完了しました
      </h3>
      <p style={{marginBottom: 8}}>
        申請情報確認後、本アカウント発行完了のお知らせを登録頂いたメールアドレス宛に送付致します
      </p>
    </Template>
  )
}

const PayOrderSuccessResult = () => {
  return (
    <Template>
      <h3 className={styles.title} style={{marginTop: 5}}>
        クレジットカードでのお支払いを受け付けました
      </h3>
      <p style={{marginBottom: 8}}>査定依頼の機能が利用できるようになりました</p>

      <p style={{marginBottom: 8}}>アプリにログインし、査定依頼ボタンをタップして、当該機能をご利用下さい</p>
    </Template>
  )
}

const PaypalReturnResult = () => {
  const dispatch = useDispatch()
  useMount(() => {
    dispatch(UIActions.resetBackBreadcrumb({backWay: '/login'}))
  })
  return (
    <Template>
      <h3 className={styles.title}>ご購入ありがとうございました</h3>
      <p style={{marginBottom: 8}}>Paypalでのお支払いを受け付けました</p>
    </Template>
  )
}

const PaypalCancelResult = () => {
  const dispatch = useDispatch()
  useMount(() => {
    dispatch(UIActions.resetBackBreadcrumb({backWay: '/login'}))
  })
  return (
    <Template>
      <h3 className={styles.title}>Paypal決済処理をキャンセルしました</h3>
      <p style={{marginBottom: 8}}>再度、支払い手付きを行う場合は、アプリに戻り再度手続きを御願い致します</p>
    </Template>
  )
}

const EmailVerifyResult = () => {
  const dispatch = useDispatch()
  const {search} = useLocation()
  const [isResultSuccess, setIsResultSuccess] = useState(false)
  const loading = useSelector((state) => _.get(state, ['loading', AccountConstants.VERIFY_EMAIL]))

  useMount(() => {
    dispatch(UIActions.resetBackBreadcrumb({backWay: `/login`}))
    const urlParams = new URLSearchParams(search)
    const token = urlParams.get('token')
    const verifyType = (urlParams.get('verifyType') ||
      Enums.MailVerifyTypeEnum.CHANGE_EMAIL) as Enums.MailVerifyTypeEnum
    if (token) {
      setIsResultSuccess(false)
      dispatch(
        AccountActions.verifyEmail({
          token,
          verifyType,
          onSuccess: () => setIsResultSuccess(true),
          onFail: () => setIsResultSuccess(false),
        })
      )
    }
  })
  return (
    <Template isSuccess={isResultSuccess} loading={loading}>
      <h3 className={styles.title} style={{marginTop: 5}}>
        {isResultSuccess ? 'メールアドレスの認証に成功しました' : 'メールアドレスの認証に失敗しました'}
      </h3>
    </Template>
  )
}

export const resultRouter = [
  {
    path: '/account/register/temporary/success',
    Component: memo(TemporarySuccessResult),
  },
  {
    path: '/account/register/formal/success',
    Component: memo(FormalSuccessResult),
  },
  {
    path: '/account/subAccount/success',
    Component: memo(SubAccountCreatedSuccessResult),
  },
  {
    path: '/account/order/pay/success',
    Component: memo(PayOrderSuccessResult),
  },
  {
    path: '/payment/paypal/return',
    Component: memo(PaypalReturnResult),
  },
  {
    path: '/payment/paypal/cancel',
    Component: memo(PaypalCancelResult),
  },
  {
    path: '/account/email/verify',
    Component: memo(EmailVerifyResult),
  },
]
