import React, {useEffect} from 'react'
import {Button, Checkbox} from 'antd'
import {useBoolean, useMount, useUnmount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'

import SinglePage from '@/components/SinglePage'
import {UIActions, AccountActions} from '@/actions'
import {AccountConstants} from '@/store/constants'
import {FormItem, Form} from '@/components/Form'
import {useCompareEffect} from '@/utils'
import {Enums} from '@/constants'

import _ from 'lodash'
import Text from 'antd/lib/typography/Text'

const Register = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [isAgree, {toggle}] = useBoolean(false)
  const [isConfirming, {setTrue: setIsConfirming, setFalse: setIsNotConfirming}] = useBoolean(false)
  const {buttonLoading, myAccountCreation, me} = useSelector(
    (state) => ({
      buttonLoading:
        _.get(state, ['loading', AccountConstants.VERIFY_UPGRADE_ME]) ||
        _.get(state, ['loading', AccountConstants.UPGRADE_ME]),
      myAccountCreation: _.get(state, ['account', 'myAccountCreation'], {}),
      me: _.get(state, ['account', 'me'], {}),
    }),
    _.isEqual
  )

  useCompareEffect(() => {
    // Photos must be re-uploaded
    const accountWithFileIds = _.omit({...me, ...me.profile, ...myAccountCreation}, [
      'identityVerificationPhoto',
      'idCardBack',
      'idCardFront',
      'idCardSide',
      'antiqueLicensePhoto',
      'specialInternationalRegistrationNumberPhoto',
    ])
    form.setFieldsValue(accountWithFileIds)
    form.setFieldsValue({bankBranchCode: accountWithFileIds.bankBranchCode})
  }, [myAccountCreation, me])

  useMount(() => {
    dispatch(UIActions.resetHeaderTrailing({headerTrailing: '本アカウント申請'}))
    dispatch(UIActions.resetBackBreadcrumb({backWay: '/', backTitle: '戻る'}))
    dispatch(AccountActions.getMyAccountCreation())
    dispatch(AccountActions.getMe())
  })

  useUnmount(() => {
    dispatch(UIActions.resetHeaderTrailing({}))
    dispatch(UIActions.resetBackBreadcrumb({}))
  })

  useEffect(() => {
    document.body.scrollIntoView?.()
  }, [isConfirming])

  const backToNotConfirming = () => {
    setIsNotConfirming()
    dispatch(UIActions.resetBackBreadcrumb({backWay: '/', backTitle: '戻る'}))
  }

  const setBackBreadcrumbWhenIsComfiming = () => {
    dispatch(UIActions.resetBackBreadcrumb({backWay: backToNotConfirming}))
  }

  const onFinish = (values: {[field: string]: string}) => {
    const account = values
    if (isConfirming) {
      dispatch(AccountActions.upgradeMe({account}))
    } else {
      dispatch(
        AccountActions.verifyUpgradeMe({
          account,
          onSuccess: () => {
            setIsConfirming()
            setBackBreadcrumbWhenIsComfiming()
          },
        })
      )
    }
  }

  const isAwaitingCheck = myAccountCreation.status === Enums.AccountCreationStatusEnum.AwaitingCheck
  const formItemReadOnly = isConfirming || isAwaitingCheck

  const BottomComponent = () => {
    if (isAwaitingCheck) {
      return <div>申請資料を審査中です、審査終わるまで少々お待ちください</div>
    }
    return (
      <div className="flexVertical">
        {isConfirming ? null : (
          <Text style={{marginBottom: 20}}>
            <a
              href="https://sateicom-web-test.s3-ap-northeast-1.amazonaws.com/term/index.html"
              rel="noopener noreferrer"
              target="_blank">
              利用規約を見る
            </a>
          </Text>
        )}
        {isConfirming ? null : (
          <Checkbox checked={isAgree} onChange={() => toggle()} style={{marginBottom: 30}}>
            利用規約に同意する
          </Checkbox>
        )}
        <div className="buttonGroup">
          {isConfirming ? <Button onClick={backToNotConfirming}>戻る</Button> : null}
          <Button
            loading={buttonLoading}
            disabled={!isAgree}
            type="primary"
            htmlType="submit"
            form="account_register_formal">
            {isConfirming ? '申請' : '確認'}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <SinglePage title={isConfirming ? '本アカウント申請確認' : '本アカウント申請'} bottom={<BottomComponent />}>
        <Form defaultStyle="around" form={form} name="account_register_formal" layout="vertical" onFinish={onFinish}>
          <FormItem.SubTitle title="申請者情報" />
          <FormItem.FamilyName readOnly={formItemReadOnly} />
          <FormItem.GivenName readOnly={formItemReadOnly} />
          <FormItem.PlaceHolder inline />
          <FormItem.PlaceHolder block />
          <FormItem.FamilyNamePhonetic readOnly={formItemReadOnly} />
          <FormItem.GivenNamePhonetic readOnly={formItemReadOnly} />
          <FormItem.PlaceHolder inline />
          <FormItem.PlaceHolder block />
          <FormItem.Email readOnly={formItemReadOnly} />
          <FormItem.PlaceHolder block />
          <FormItem.IdentityVerificationPhoto readOnly={formItemReadOnly} />
          <FormItem.IdCardPhoto readOnly={formItemReadOnly} />
          <FormItem.PlaceHolder inline />
          <FormItem.SubTitle title="会社情報" />
          <FormItem.IsIndividual readOnly={formItemReadOnly} form={form} />
          <FormItem.PlaceHolder block />
          <FormItem.LegalEntityName readOnly={formItemReadOnly} />
          <FormItem.LegalEntityNamePhonetic readOnly={formItemReadOnly} />
          <FormItem.PlaceHolder inline />
          <FormItem.PlaceHolder block />
          <FormItem.DirectorFamilyName readOnly={formItemReadOnly} />
          <FormItem.DirectorGivenName readOnly={formItemReadOnly} />
          <FormItem.PlaceHolder inline />
          <FormItem.PlaceHolder block />
          <FormItem.DirectorFamilyNamePhonetic readOnly={formItemReadOnly} />
          <FormItem.DirectorGivenNamePhonetic readOnly={formItemReadOnly} />
          <FormItem.PlaceHolder inline />
          <FormItem.PlaceHolder block />
          <FormItem.Tel readOnly={formItemReadOnly} />
          <FormItem.PlaceHolder block />
          <FormItem.AntiqueLicenseNumber form={form} readOnly={formItemReadOnly} />
          <FormItem.SpecialInternationalRegistrationNumber readOnly={formItemReadOnly} />
          <FormItem.PlaceHolder inline />
          <FormItem.PlaceHolder block />
          <FormItem.AntiqueLicensePhoto readOnly={formItemReadOnly} />
          <FormItem.SpecialInternationalRegistrationNumberPhoto readOnly={formItemReadOnly} />
          <FormItem.PlaceHolder inline />
          <FormItem.SubTitle title="会社住所情報" />
          <FormItem.Zipcode readOnly={formItemReadOnly} form={form} />
          <FormItem.Prefecture readOnly={formItemReadOnly} />
          <FormItem.City readOnly={formItemReadOnly} />
          <FormItem.Street readOnly={formItemReadOnly} />
          <FormItem.Building readOnly={formItemReadOnly} />
          <FormItem.PlaceHolder inline />
          <FormItem.SubTitle title="入金口座情報" />
          <FormItem.BankCode readOnly={formItemReadOnly} />
          <FormItem.BankBranchCode readOnly={formItemReadOnly} form={form} />
          <FormItem.PlaceHolder inline />
          <FormItem.PlaceHolder block />
          <FormItem.BankAccountType readOnly={formItemReadOnly} />
          <FormItem.BankAccountNo readOnly={formItemReadOnly} />
          <FormItem.PlaceHolder inline />
          <FormItem.PlaceHolder block />
          <FormItem.SubTitle title="買取り条件" />
          <FormItem.TermsOfPurchase readOnly={formItemReadOnly} />
          <FormItem.SubTitle title="プラン情報" />
          <FormItem.IsAuction readOnly={formItemReadOnly} form={form} />
          <FormItem.PlanId readOnly={formItemReadOnly} form={form} />
          <FormItem.PlaceHolder inline />
        </Form>
      </SinglePage>
    </div>
  )
}

export default Register
