import React, {FC, useRef, useState, useMemo} from 'react'
import {Carousel, Image as AntdImage} from 'antd'
import dayjs from 'dayjs'
import classnames from 'classnames'
import _ from 'lodash'

import Image, {ImgStore} from '@/components/Image'
import Countdown from '@/components/Countdown'
import {Enums} from '@/constants'

import {getDeadlineUsed, getStatusText} from './function'

import styles from './styles.module.less'

const SampleNextArrow = (props: {className: any; style: any; onClick: any}) => {
  const {className, style, onClick} = props
  return (
    <span className={className} style={{...style}} onClick={onClick}>
      <Image src={ImgStore.ArrowRight} />
    </span>
  )
}

const SamplePrevArrow = (props: {className: any; style: any; onClick: any}) => {
  const {className, style, onClick} = props
  return (
    <span className={className} style={{...style}} onClick={onClick}>
      <Image src={ImgStore.ArrowLeft} />
    </span>
  )
}

export const Item: FC<{label: string; value: string; labelWidth: number}> = ({label, value, labelWidth}) => {
  return (
    <div className={styles.item}>
      <span className={styles.label} style={{width: labelWidth}}>
        {label}
      </span>
      <span>{value}</span>
    </div>
  )
}

export const Title: FC = ({children}) => <p className={styles.title}>{children}</p>

export const AssessmentInfo: FC<{
  assessmentRequestInfo: any
  assessmentReplyInfo?: any
  transactionInfo?: any
  transactionStatusText?: any
}> = ({assessmentRequestInfo, assessmentReplyInfo, transactionInfo, transactionStatusText}) => {
  const {status: requestStatus, createdAt, assessmentReplyNum} = assessmentRequestInfo || {}
  const {status: replyStatus} = assessmentReplyInfo || {}
  const {status: transactionStatus, price, purchasePrice, flowType} = transactionInfo || {}

  const statusText = getStatusText(transactionStatus, requestStatus, replyStatus, transactionStatusText)
  const createdAtText = dayjs.unix(createdAt).format('YYYY年MM月DD日 HH:mm')
  const priceText = Number(price).toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'})
  const purchasePriceText = Number(purchasePrice).toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'})
  const {deadlineUsed, deadlineUsedPrefixText = ''} = getDeadlineUsed(assessmentRequestInfo, assessmentReplyInfo)
  return (
    <div className={styles.assessmentRequestInfoContainer}>
      <div>
        <Item label="依頼日時" value={createdAtText} labelWidth={165} />
        {flowType === Enums.TransactionFlowTypeEnum.NormalTransaction || purchasePrice === '0' ? (
          <Item label="取引査定額" value={priceText} labelWidth={165} />
        ) : (
          <Item label="取引査定額" value={purchasePriceText} labelWidth={165} />
        )}
        <Item label="査定数" value={assessmentReplyNum} labelWidth={165} />
      </div>
      <div>
        <div className={styles.status}>{statusText}</div>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          <span>{deadlineUsedPrefixText}</span>
          <Countdown finishRender=" 査定終了" className={styles.countdown} value={deadlineUsed} />
        </div>
      </div>
    </div>
  )
}

export const CommodityInfo: FC<{info: any}> = ({info = {}}) => {
  const mainSlider = useRef<any>()
  const subSlider = useRef<any>()

  const [currentIndex, setCurrentIndex] = useState(0)

  const {itemImages = [], itemProperties = [], mainImage = {}} = info
  const onChange = (from: number, to: number, fromClickItem = false) => {
    if (from === to) return
    if (fromClickItem) mainSlider.current?.slick?.slickGoTo(to)
    setCurrentIndex(to)
  }

  const imgList = useMemo(() => {
    if (itemImages.length) return itemImages
    if (mainImage.fileId) return [mainImage]
    return []
  }, [itemImages.length, mainImage.fileId])

  return (
    <div className={styles.commodityInfoContainer}>
      <div className={styles.carouselContainer}>
        <Carousel
          lazyLoad="ondemand"
          infinite={false}
          ref={mainSlider}
          className={styles.mainSliderContainer}
          draggable
          dots={false}
          beforeChange={onChange}
          asNavFor={subSlider.current}>
          {imgList.map(({thumbUrl, url, fileId}: any) => (
            <AntdImage
              src={url || thumbUrl}
              width={334}
              height={334}
              key={fileId}
              placeholder={<AntdImage src={thumbUrl || url} width={334} height={334} placeholder preview={false} />}
            />
          ))}
        </Carousel>
        {imgList.length ? <div className={styles.tip}>{`${currentIndex + 1}/${imgList.length}`}</div> : null}
        <Carousel
          arrows
          // @ts-ignore
          nextArrow={<SampleNextArrow />}
          // @ts-ignore
          prevArrow={<SamplePrevArrow />}
          infinite={false}
          className={styles.subSliderContainer}
          asNavFor={mainSlider.current}
          ref={subSlider}
          dots={false}
          slidesToShow={4}
          slidesToScroll={4}
          swipeToSlide
          focusOnSelect>
          {imgList.map(({thumbUrl, url, fileId}: any, index: number) => (
            <div
              key={fileId}
              className={classnames(styles.subSliderItem, index === currentIndex && styles.active)}
              onClick={() => onChange(currentIndex, index, true)}>
              <Image src={thumbUrl || url} width={58} height={58} />
            </div>
          ))}
        </Carousel>
      </div>
      <div>
        {itemProperties.map(({id, categoryPropertyName, categoryPropertyValue = {}, textPropertyValue}: any) => (
          <Item
            label={categoryPropertyName}
            // when categoryPropertyName = "手動入力欄(TEST)", value is not categoryPropertyValue?.name but textPropertyValue
            value={categoryPropertyValue?.name || textPropertyValue}
            labelWidth={165}
            key={id}
          />
        ))}
      </div>
    </div>
  )
}

export const EntityInfo: FC<{sellerInfo: any; buyerInfo: any}> = ({sellerInfo = {}, buyerInfo = {}}) => {
  const seller = _.get(sellerInfo, ['displayId'], '--')
  const buyer = _.get(buyerInfo, ['userDisplayId'], '--')
  return (
    <div>
      <Item label="出品者" value={seller} labelWidth={50} />
      <Item label="買取者" value={buyer} labelWidth={50} />
    </div>
  )
}
