import React, {memo} from 'react'
import {Divider} from 'antd'
import classnames from 'classnames'
import {isMobile} from '@/utils'

import styles from './styles.module.less'

interface Props {
  title: string
  children?: React.ReactNode
  bottom?: React.ReactNode
}

const SinglePage = ({title, children, bottom}: Props) => {
  return (
    <div>
      {isMobile ? null : (
        <>
          <h1 className={styles.title}>{title}</h1>
          <Divider dashed className={classnames(styles.divider, isMobile && styles.isMobile)} />
        </>
      )}
      <div className={styles.contentContainer}>{children}</div>
      <Divider dashed className={classnames(styles.divider, isMobile && styles.isMobile)} />
      <div className={classnames(styles.bottomContainer, isMobile && styles.isMobile)}>{bottom}</div>
    </div>
  )
}

export default memo(SinglePage)
