import {createAction} from 'redux-actions'

import {OrderConstants} from '@/store/constants'
import {ActionCallback} from '@/utils'

export const OrderActions = {
  getOrder: createAction<{id: string}>(OrderConstants.GET_ORDER),
  refundOrder: createAction<{orderId: string; refundAmount: string; version: string} & ActionCallback>(
    OrderConstants.REFUND_ORDER
  ),

  setOrder: createAction(OrderConstants.SET_ORDER),
}
