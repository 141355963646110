import React, {FC, memo} from 'react'
import {Layout, Menu as AntdMenu} from 'antd'
import {NavLink} from 'react-router-dom'
import classnames from 'classnames'
import {useDispatch, useSelector} from 'react-redux'
import {useMount} from 'ahooks'
import _ from 'lodash'
import {Enums} from '@/constants'

import {isMobile} from '@/utils'
import {AccountActions} from '@/actions'

import {menuList} from '../constant'
import styles from './styles.module.less'

const {Sider} = Layout

interface IProp {
  children: React.ReactNode
}

const LayoutSiderMenu: FC<IProp> = ({children}) => {
  return (
    <Layout>
      <Sider
        theme="light"
        breakpoint="md"
        collapsedWidth="0"
        className={styles.sider}
        zeroWidthTriggerStyle={{visibility: isMobile ? 'hidden' : undefined}}>
        <Menu />
      </Sider>
      {children}
    </Layout>
  )
}

export const Menu = () => <MenuContent />

const MenuContent = () => {
  const dispatch = useDispatch()
  const {accountStatus, isManager} = useSelector((state) => ({
    accountStatus: _.get(state, ['account', 'me', 'accountStatus']),
    isManager: _.get(state, ['account', 'me', 'isManager']),
  }))
  useMount(() => {
    dispatch(AccountActions.getMe())
  })

  const isSubAccountOrNotPermanent =
    isManager === false || accountStatus !== Enums.AccountStatusEnum.PermanentRegistered

  return (
    <AntdMenu style={{height: '100%', borderRight: 0}} className={styles.menuContainer}>
      {menuList.map(({title, key, menuItems = [], to: mainTo}) => (
        <AntdMenu.ItemGroup
          key={key}
          title={
            <NavLink to={mainTo || menuItems?.[0]?.to} activeClassName={classnames(mainTo && styles.activeLink)}>
              {title}
            </NavLink>
          }>
          {menuItems.map(({key: itemKey, subTitle, to}) => {
            if (isSubAccountOrNotPermanent && to === '/account/subAccount') return null
            return (
              <AntdMenu.Item key={itemKey}>
                <NavLink to={to} activeClassName={styles.activeLink}>
                  {subTitle}
                </NavLink>
              </AntdMenu.Item>
            )
          })}
        </AntdMenu.ItemGroup>
      ))}
    </AntdMenu>
  )
}

export default memo(LayoutSiderMenu)
