import {Action} from 'redux-actions'
import {takeLatest, call, put} from 'redux-saga/effects'
import {AccountAPI, ContractAPI} from '@/service'
import {ContractActions} from '@/actions'
import {Enums} from '@/constants'

import {ContractConstants} from '../constants'

function* getPlansList({payload}: Action<{planType: Enums.PlanTypeEnum}>) {
  try {
    const {planType} = payload
    const res = yield call(ContractAPI.listContractPlans, planType)
    yield put(ContractActions.setPlansList({list: res.contractPlans, planType}))
  } catch (err) {}
}
function* getSelectablePlansList() {
  try {
    const accountInfo = yield call(AccountAPI.getMe)
    const planType = accountInfo.legalEntity.isIndividual
      ? Enums.PlanTypeEnum.INDIVIDUAL
      : Enums.PlanTypeEnum.CORPORATION
    const res = yield call(ContractAPI.listContractPlans, planType)
    yield put(ContractActions.setSelectablePlansList({list: res.contractPlans}))
  } catch (err) {}
}
export const contractSaga = [
  takeLatest(ContractConstants.GET_PLANS_LIST, getPlansList),
  takeLatest(ContractConstants.GET_SELECTABLE_PLANS_LIST, getSelectablePlansList),
]
