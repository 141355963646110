import React, {FC, memo} from 'react'
import {Layout} from 'antd'
import {ErrorBoundary} from '@/components/ErrorBoundaries'

import styles from './styles.module.less'

const ContainerLayout: FC = ({children}) => (
  <Layout className={styles.layout}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </Layout>
)

export default memo(ContainerLayout)
