/* eslint-disable react/jsx-props-no-spreading */
import React, {FC} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'

import {buildType} from '@/utils'

import adminRoutes from './admin'
import customerRoutes from './customer'
import {history} from './history'

export interface IRoutesConfig {
  path: string
  component?: any
  props?: any
  redirect?: string
  routes?: IRoutesConfig[]
  exact?: boolean
  wrappers?: any[]
}

const extraRoutes = () => {
  if (buildType === 'ADMIN') return adminRoutes
  if (buildType === 'CUSTOMER') return customerRoutes
  return [...adminRoutes, ...customerRoutes]
}

const routesConfig: IRoutesConfig[] = extraRoutes()

const DefaultFragment: FC = ({children}) => <>{children}</>

const buildWrappers = (_wrappers: any[]): FC => ({children, ...props}) =>
  _wrappers.reduce((lastChild, Wrapper) => <Wrapper {...props}>{lastChild}</Wrapper>, children)

const SwitchComponent = ({config}: {config: IRoutesConfig[]}) => {
  if (!config.length) return null
  return (
    <Switch>
      {config.map(
        ({
          component: MainComponent = DefaultFragment,
          props: MainComponentProps,
          wrappers = [DefaultFragment],
          routes = [],
          path,
          redirect,
          exact,
        }) => {
          if (redirect) return <Redirect from={path} to={redirect} key={path} exact={exact} />
          const WrappersComponent = buildWrappers(wrappers)
          return (
            <Route
              path={path}
              exact={exact}
              key={path}
              render={(routeComponentProps) => (
                <WrappersComponent {...routeComponentProps}>
                  <MainComponent {...MainComponentProps}>
                    <SwitchComponent config={routes} />
                  </MainComponent>
                </WrappersComponent>
              )}
            />
          )
        }
      )}
    </Switch>
  )
}

export {SwitchComponent, routesConfig, adminRoutes, customerRoutes, history}
