import {handleActions} from '@/utils'
import {ContentConstants} from '@/store/constants'

const ContentState = {
  bankList: [],
  bankBranchList: {} as any,
  deliveryCompaniesList: [],
  informationList: [],
  informationDetails: {} as any,
}

const ContentReducer = handleActions(
  {
    [ContentConstants.SET_LIST_BANK]: (state, {payload}) => {
      const {list = []} = payload
      state.bankList = list
    },
    [ContentConstants.SET_LIST_BANK_BRANCH]: (state, {payload}) => {
      const {list = [], bankCode} = payload
      state.bankBranchList[bankCode] = list
    },
    [ContentConstants.SET_LIST_DELIVERY_COMPANIES]: (state, {payload}) => {
      const {list = []} = payload
      state.deliveryCompaniesList = list
    },
    [ContentConstants.SET_LIST_INFORMATION]: (state, {payload}) => {
      const {list = []} = payload
      state.informationList = list
    },
    [ContentConstants.SET_GET_INFORMATION]: (state, {payload}) => {
      const {information = {}} = payload
      state.informationDetails[information.id] = information
    },
  },
  ContentState
)

export {ContentReducer}
