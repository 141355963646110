import React, {useCallback} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import Table from '@/components/Table'
import {history} from '@/routes'
import {AssessmentActions} from '@/actions'
import {AssessmentConstants} from '@/store/constants'

import RefreshBar from '../components/RefreshBar'
import {columns} from './colConfig'

export default () => {
  const dispatch = useDispatch()
  const dataSource = useSelector((state) => _.get(state, ['assessment', 'sellHistories'], []))

  const getData = () => dispatch(AssessmentActions.listMyTransactionHistoriesAsSeller({}))
  const onRow = useCallback((record) => ({onClick: () => history.push(`/sellHistories/${record.item?.itemId}`)}), [])

  return (
    <>
      <RefreshBar actionType={AssessmentConstants.LIST_MY_TRANSACTION_HISTORIES_AS_SELLER} />
      <Table
        onRow={onRow}
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record.item?.itemId}
        actionType={AssessmentConstants.LIST_MY_TRANSACTION_HISTORIES_AS_SELLER}
        onChange={getData}
        csvDownload
        csvFilename="出品履歴"
      />
    </>
  )
}
