import React, {FC} from 'react'

import styles from './styles.module.less'

interface Props {
  title: string
}

const LikeTabContainer: FC<Props> = ({children, title}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>{title}</div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default LikeTabContainer
