import React, {memo, useMemo, CSSProperties} from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import * as ImgStore from './ImgStore'
import styles from './styles.module.less'

export {ImgStore}

interface Props {
  textPostion?: 'right' | 'left' | 'bottom' | 'top'
  alt?: string
  width?: number | string
  height?: number | string
  src: string
  text?: React.ReactNode
  textWidth?: string | number
  spacing?: number
  textClassName?: string
  className?: string
  defaultSrc?: string
  style?: CSSProperties
  imageStyle?: CSSProperties
  textStyle?: CSSProperties
  inline?: boolean
}

const CustomImage = ({
  textPostion = 'right',
  alt = '',
  width,
  height,
  text,
  textWidth,
  spacing = 8,
  textClassName,
  className,
  src,
  defaultSrc,
  imageStyle,
  textStyle = {},
  style: ContainerStyle,
  inline,
}: Props): React.ReactElement => {
  const onError = _.once((e) => {
    e.target.onerror = null
    e.target.src = defaultSrc
  })
  const defalutTextStyle = useMemo(() => {
    const style: CSSProperties = {}
    if (!spacing || (Number(text) !== 0 && !text)) return style
    if (textPostion === 'left') {
      style.marginRight = spacing
    }
    if (textPostion === 'right') {
      style.marginLeft = spacing
    }
    if (textPostion === 'top') {
      style.marginBottom = spacing
    }
    if (textPostion === 'bottom') {
      style.marginTop = spacing
    }
    return style
  }, [spacing, textPostion])

  return (
    <div
      className={classnames(styles[textPostion], className, styles.imageContainer, inline && styles.inline)}
      style={ContainerStyle}>
      <img style={imageStyle} height={height} width={width} alt={alt} src={src} onError={onError} />
      <span
        className={classnames('text-truncate', textClassName)}
        style={{maxWidth: textWidth, ...defalutTextStyle, ...textStyle}}>
        {text}
      </span>
    </div>
  )
}

export default memo(CustomImage)
