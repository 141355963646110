import {handleActions} from '@/utils'
import {OrderConstants} from '@/store/constants'

const ContractState = {
  orderDetails: {} as any,
}

const OrderReducer = handleActions(
  {
    [OrderConstants.SET_ORDER]: (state, {payload}) => {
      const {order = {}} = payload
      state.orderDetails[order.id] = order
    },
  },
  ContractState
)

export {OrderReducer}
