import {request} from '@/service/request'
import {InformationTypeEnum, ImportanceEnum} from '@/constants/enums'

export const ContentAPI = {
  listBank: () => request.get('/api/v1/content/bank', {headers: {nt: false}}),
  listBankBranch: ({bankCode}: {bankCode: string}) =>
    request.get(`/api/v1/content/bank/${bankCode}/branch`, {headers: {nt: false}}),
  listDeliveryCompanies: () => request.get('/api/v1/content/deliveryCompany', {headers: {nt: false}}),
  listInformation: () => request.get('/api/v1/content/information'),
  getInformation: ({id}: {id: string}) => request.get(`/api/v1/content/information/${id}`),
  updateInformation: (payload: any) => request.put('/api/v1/content/information', {information: payload}),
  createInformation: (payload: any) =>
    request.post('/api/v1/content/information', {
      information: {
        ...payload,
        informationType: InformationTypeEnum.AllUsers,
        importance: ImportanceEnum.Important,
      },
    }),
}
