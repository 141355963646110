import {createAction} from 'redux-actions'

import {OtherConstants} from '@/store/constants'
import {ActionCallback} from '@/utils'

export const OtherActions = {
  payOrder: createAction<{id: string; values: any} & ActionCallback>(OtherConstants.PAY_ORDER),
  zipCloud: createAction<{zipCode: string} & ActionCallback>(OtherConstants.ZIP_CLOUD),
  payOrderConfirm: createAction<{orderId: string; version: string} & ActionCallback>(OtherConstants.PAY_ORDER_CONFIRM),
}
