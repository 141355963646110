import React, {memo, FC, useCallback} from 'react'
import {useMount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {Divider, Button} from 'antd'
import _ from 'lodash'

import LikeTabContainer from '@/components/LikeTabContainer'
import Table from '@/components/Table'
import {AssessmentConstants} from '@/store/constants'
import {AssessmentActions} from '@/actions'
import {EnumsText} from '@/constants'

import {CommodityInfo, AssessmentInfo, Title, canSellerDeciding} from '@/pages/customer/components/TransactionUtils'
import {columns} from './colConfig'

const SellDetails = () => {
  const dispatch = useDispatch()
  const {id}: any = useParams()
  const {detail} = useSelector((state) => ({detail: _.get(state, ['assessment', 'itemDetails', id], {})}), _.isEqual)
  const canSellerDecidingd = canSellerDeciding(detail.assessmentRequest)
  const assessmentRequestId = detail.assessmentRequest?.id

  useMount(() => {
    id && dispatch(AssessmentActions.getItemDetail({id}))
  })

  return (
    <LikeTabContainer title="出品履歴詳細">
      <AssessmentInfo
        assessmentRequestInfo={detail.assessmentRequest}
        assessmentReplyInfo={detail.currentBuyerReply}
        transactionInfo={detail.transaction}
        transactionStatusText={EnumsText.TransactionStatusEnum.FOR_SELLER}
      />
      <Divider />
      <Title>商品情報</Title>
      <CommodityInfo info={detail.item} />
      <Divider />
      <Title>査定対応会社</Title>
      <RequestEntityInfo
        info={detail.assessmentReplies}
        assessmentRequestId={assessmentRequestId}
        itemId={id}
        canSellerDecidingd={canSellerDecidingd}
      />
    </LikeTabContainer>
  )
}

const RequestEntityInfo: FC<{
  info: any
  assessmentRequestId: string
  itemId: string
  canSellerDecidingd: boolean
}> = ({info = [], assessmentRequestId, itemId, canSellerDecidingd}) => {
  const dataSource = info
  const dispatch = useDispatch()
  const {declineLoading, decideBuyerLoading} = useSelector(
    (state) => ({
      declineLoading: _.get(state, ['loading', AssessmentConstants.DECLINE_ASSESSMENT]),
      decideBuyerLoading: _.get(state, ['loading', AssessmentConstants.DECIDE_BUYER]),
    }),
    _.isEqual
  )

  const decline = useCallback(() => {
    assessmentRequestId && dispatch(AssessmentActions.declineAssessment({id: assessmentRequestId, itemId}))
  }, [assessmentRequestId])

  const decideBuyer = useCallback(
    (assessmentReplyId: string) => () => {
      dispatch(AssessmentActions.decideBuyer({id: assessmentRequestId, assessmentReplyId, itemId}))
    },
    [assessmentRequestId]
  )

  // TODO: check button disable condition
  return (
    <div style={{textAlign: 'right'}}>
      <Table
        columns={columns(decideBuyer, canSellerDecidingd, decideBuyerLoading)}
        dataSource={dataSource}
        rowKey="id"
        pagination={false}
      />
      <Button
        type="primary"
        style={{marginRight: 20, marginTop: 20}}
        onClick={decline}
        loading={declineLoading}
        disabled={!canSellerDecidingd}>
        辞退
      </Button>
    </div>
  )
}

export default memo(SellDetails)
