/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-use-before-define */
import {FormInstance} from 'antd/lib/form'

export const convertFields = ['antiqueLicenseNumber']
export type convertFields = ['antiqueLicenseNumber']
export type valueof<T> = T extends any[] ? T[number] : T[keyof T]

export const handleTransform = (field: valueof<convertFields>) => (value: string) => convert(value, field, false)

export const handleBlur = (field: valueof<convertFields>, form?: FormInstance) => () => {
  const convertValue = convert(form?.getFieldValue(field), field, true)
  form?.setFieldsValue({[field]: convertValue})
  if (convertValue !== undefined) form?.validateFields([field])
}

export const handleFocus = (field: valueof<convertFields>, form?: FormInstance) => () => {
  const convertValue = convert(form?.getFieldValue(field), field, false)
  form?.setFieldsValue({[field]: convertValue})
  if (convertValue !== undefined) form?.validateFields([field])
}

export const getConvertValues = (filedsValue: {[field: string]: string} | undefined, isConvert = false) => {
  const temp: {[field: string]: string} = {}
  filedsValue &&
    convertFields.forEach((field) => {
      const convertResult = convert(filedsValue[field], field, isConvert)
      if (convertResult === undefined) return
      temp[field] = convertResult
    })
  return temp
}

export const buildPlaceHolder = ({
  type,
  noPlaceHolder,
}: {field?: string; type?: 'input' | 'select'; name?: string; noPlaceHolder?: boolean} = {}) => {
  if (noPlaceHolder) return undefined
  if (type === 'select') {
    return '選んでください'
  }
  return '入力してください'
}

export const convert = (value: string, type: string, isConvert: boolean) => {
  if (!value) return value
  let convertValue = value
  switch (type) {
    case 'antiqueLicenseNumber':
      convertValue = isConvert ? `第 ${convertValue.replace(/[^\d]/g, '')} 号` : convertValue.replace(/[^\d]/g, '')
      break
    default:
      break
  }
  return convertValue
}

export const validateMessages = {
  required: '${label}を入力してください',
  types: {
    email: '正しい${label}を入力してください',
  },
}
