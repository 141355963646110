import {Enums} from './index'

export const EnumsText = {
  UploadTypeEnum: {
    [Enums.UploadTypeEnum.Private]: 'private',
    [Enums.UploadTypeEnum.Public]: 'public-read',
  },
  BankAccountTypeEnum: {
    [Enums.BankAccountTypeEnum.Ordinary]: '普通預金',
    [Enums.BankAccountTypeEnum.Checking]: '当座預金',
    [Enums.BankAccountTypeEnum.Saving]: '貯蓄預金',
  },
  AccountStatusEnum: {
    [Enums.AccountStatusEnum.TemporaryRegistered]: '仮登録',
    [Enums.AccountStatusEnum.Application]: '本登録申込',
    [Enums.AccountStatusEnum.Incomplete]: '不備',
    [Enums.AccountStatusEnum.ReApplication]: '再申請中',
    [Enums.AccountStatusEnum.Created]: '開設完了',
    [Enums.AccountStatusEnum.PermanentRegistered]: '本登録済み',
    [Enums.AccountStatusEnum.ApplicationCancel]: '申込キャンセル',
    [Enums.AccountStatusEnum.Rejected]: '開設拒否',
    [Enums.AccountStatusEnum.Canceled]: '口座解約',
  },
  InformationTypeEnum: {
    [Enums.InformationTypeEnum.AllUsers]: '全顧客',
    [Enums.InformationTypeEnum.UserSpecification]: '顧客指定',
  },
  AssessmentRequestStatusEnum: {
    [Enums.AssessmentRequestStatusEnum.Ongoing]: '査定中',
    [Enums.AssessmentRequestStatusEnum.Dealt]: '取引決定',
    [Enums.AssessmentRequestStatusEnum.Declined]: '取引辞退',
    [Enums.AssessmentRequestStatusEnum.TransactionDone]: '取引完了',
    [Enums.AssessmentRequestStatusEnum.AssessmentFailedNoReply]: '取引不成立（査定入力がない）',
    [Enums.AssessmentRequestStatusEnum.AssessmentFailedDecisionExpired]: '取引不成立（買取者選択期限切れ）',
    [Enums.AssessmentRequestStatusEnum.TransactionFailedRecalled]: '取引不成立（現物査定返品のため）',
  },
  AssessmentReplyStatusEnum: {
    [Enums.AssessmentReplyStatusEnum.FirstTimeReplied]: '初回査定入力済み',
    [Enums.AssessmentReplyStatusEnum.ReplyPostponed]: '[長期のみ]後で査定',
    [Enums.AssessmentReplyStatusEnum.OneChanceReplied]: 'ワンチャン査定済み',
    [Enums.AssessmentReplyStatusEnum.Dealt]: '落札成功',
    [Enums.AssessmentReplyStatusEnum.BidLost]: '落札失敗（他の人が選ばれたため）',
    [Enums.AssessmentReplyStatusEnum.Declined]: '出品者辞退',
  },
  PaymentMethodEnum: {
    [Enums.PaymentMethodEnum.Bank]: '銀行',
    [Enums.PaymentMethodEnum.Paypal]: 'Paypal',
    [Enums.PaymentMethodEnum.CreditCard]: 'CreditCard',
  },
  PostingStatusEnum: {
    [Enums.PostingStatusEnum.BeforePosting]: '表示前',
    [Enums.PostingStatusEnum.Posting]: '表示中',
    [Enums.PostingStatusEnum.Termination]: '表示終了',
  },
  // 取引ステータス
  TransactionStatusEnum: {
    FOR_ADMIN: {
      [Enums.TransactionStatusEnum.Unknown]: '取引不成立',
      [Enums.TransactionStatusEnum.AwaitingTransaction]: '買取手続き中',
      [Enums.TransactionStatusEnum.DepositApplied]: '入金確認待ち',
      [Enums.TransactionStatusEnum.Deposited]: '配送中',
      [Enums.TransactionStatusEnum.SellerDeliveryToBuyerArranged]: '配送中',
      [Enums.TransactionStatusEnum.PlatformDeliveryToBuyerArranged]: '買取へ配送手配完了',
      [Enums.TransactionStatusEnum.TransactionConfirmed]: '入金待ち',
      [Enums.TransactionStatusEnum.withdrawn]: '振込完了',
      [Enums.TransactionStatusEnum.TransactionCompleted]: '取引完了',
      [Enums.TransactionStatusEnum.SellerDeliveryToPlatformArranged]: '配送中',
      [Enums.TransactionStatusEnum.AwaitingAssessment]: '現物確認中',
      [Enums.TransactionStatusEnum.Bargain]: '売却検討中',
      [Enums.TransactionStatusEnum.TransactionCanceled]: '買取者取消',
      [Enums.TransactionStatusEnum.TransactionReject]: '出品者辞退',
      [Enums.TransactionStatusEnum.BuyerDeliveryToPlatformArranged]: '出品者へ返送待ち',
      [Enums.TransactionStatusEnum.PlatformDeliveryToSellerArranged]: '出品へ返送手配完了',
      [Enums.TransactionStatusEnum.ItemReturned]: '返金待ち',
      [Enums.TransactionStatusEnum.DepositRefunded]: '買取者へ返金完了',
      [Enums.TransactionStatusEnum.TransactionFailed]: '取引キャンセル',
    },
    FOR_BUYER: {
      [Enums.TransactionStatusEnum.Unknown]: '取引不成立',
      [Enums.TransactionStatusEnum.AwaitingTransaction]: '入金待ち',
      [Enums.TransactionStatusEnum.DepositApplied]: '入金確認待ち',
      [Enums.TransactionStatusEnum.Deposited]: '配送中',
      [Enums.TransactionStatusEnum.SellerDeliveryToBuyerArranged]: '配送中',
      [Enums.TransactionStatusEnum.PlatformDeliveryToBuyerArranged]: '運営者配送完了',
      [Enums.TransactionStatusEnum.TransactionConfirmed]: '取引完了',
      [Enums.TransactionStatusEnum.withdrawn]: '取引完了',
      [Enums.TransactionStatusEnum.TransactionCompleted]: '取引完了',
      [Enums.TransactionStatusEnum.SellerDeliveryToPlatformArranged]: '配送中',
      [Enums.TransactionStatusEnum.AwaitingAssessment]: '現物確認中',
      [Enums.TransactionStatusEnum.Bargain]: '売却検討中',
      [Enums.TransactionStatusEnum.TransactionCanceled]: '返送待ち',
      [Enums.TransactionStatusEnum.TransactionReject]: '',
      [Enums.TransactionStatusEnum.BuyerDeliveryToPlatformArranged]: '返送手配完了',
      [Enums.TransactionStatusEnum.PlatformDeliveryToSellerArranged]: '運営者返送手配完了',
      [Enums.TransactionStatusEnum.ItemReturned]: '返金待ち',
      [Enums.TransactionStatusEnum.DepositRefunded]: '返金確認中',
      [Enums.TransactionStatusEnum.TransactionFailed]: '取引キャンセル',
    },
    FOR_SELLER: {
      [Enums.TransactionStatusEnum.Unknown]: '取引不成立',
      [Enums.TransactionStatusEnum.AwaitingTransaction]: '買取手続き中',
      [Enums.TransactionStatusEnum.DepositApplied]: '買取手続き中',
      [Enums.TransactionStatusEnum.Deposited]: '配送待ち',
      [Enums.TransactionStatusEnum.SellerDeliveryToBuyerArranged]: '配送完了',
      [Enums.TransactionStatusEnum.PlatformDeliveryToBuyerArranged]: '運営者配送完了',
      [Enums.TransactionStatusEnum.TransactionConfirmed]: '入金待ち',
      [Enums.TransactionStatusEnum.withdrawn]: '入金確認待ち',
      [Enums.TransactionStatusEnum.TransactionCompleted]: '取引完了',
      [Enums.TransactionStatusEnum.SellerDeliveryToPlatformArranged]: '配送手配完了',
      [Enums.TransactionStatusEnum.AwaitingAssessment]: '現物確認中',
      [Enums.TransactionStatusEnum.Bargain]: '売却検討中',
      [Enums.TransactionStatusEnum.TransactionCanceled]: '返送中',
      [Enums.TransactionStatusEnum.TransactionReject]: '',
      [Enums.TransactionStatusEnum.BuyerDeliveryToPlatformArranged]: '返送中',
      [Enums.TransactionStatusEnum.PlatformDeliveryToSellerArranged]: '返送中',
      [Enums.TransactionStatusEnum.ItemReturned]: '取引キャンセル',
      [Enums.TransactionStatusEnum.DepositRefunded]: '取引キャンセル',
      [Enums.TransactionStatusEnum.TransactionFailed]: '取引キャンセル',
    },
  },
}
