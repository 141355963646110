import {handleActions} from '@/utils'
import {PaymentConstants} from '@/store/constants'
import _ from 'lodash'

const PaymentState = {
  list_awaiting_refund: [],
  list_awaiting_confirm_payment: [],
}

const PaymentReducer = handleActions(
  {
    [PaymentConstants.SET_LIST_AWAITING_REFUND_DATA]: (state, {payload}) => {
      state.list_awaiting_refund =
        payload?.list?.map((item: any) => {
          const itemId = _.get(item, ['content', 'transactionContent', 'itemId'])
          return {
            ...item,
            itemImageUrl: _.get(payload?.urls, [itemId, 'thumbnailUrl']) || _.get(payload?.urls, [itemId, 'url'], ''),
          }
        }) || []
    },
    [PaymentConstants.SET_LIST_AWAITING_CONFIRM_PAYMENT_DATA]: (state, {payload}) => {
      state.list_awaiting_confirm_payment =
        payload?.list?.map((item: any) => {
          const itemId = _.get(item, ['content', 'transactionContent', 'itemId'])
          return {
            ...item,
            itemImageUrl: _.get(payload?.urls, [itemId, 'thumbnailUrl']) || _.get(payload?.urls, [itemId, 'url'], ''),
          }
        }) || []
    },
  },
  PaymentState
)

export {PaymentReducer}
