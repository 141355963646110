import {createAction} from 'redux-actions'

import {AuthConstants} from '@/store/constants'
import {ActionCallback} from '@/utils'

export const AuthActions = {
  login: createAction<{email: string; password: string}>(AuthConstants.LOGIN_START),
  loginSuccess: createAction<{user: any}>(AuthConstants.LOGIN_SUCCESS),
  loginFail: createAction(AuthConstants.LOGIN_FAIL),
  getMe: createAction(AuthConstants.GET_ME_START),
  logout: createAction(AuthConstants.LOGOUT_START),
  resetPassword: createAction<{token: string; newPassword: string}>(AuthConstants.RESET_PASSWORD),
  changePassword: createAction<{password: string; newPassword: string}>(AuthConstants.CHANGE_PASSWORD),
  requireResetPassword: createAction<{email: string} & ActionCallback>(AuthConstants.REQUIRE_RESET_PASSWORD),
}
