import React, {memo} from 'react'

import {useUnmount} from 'ahooks'
import {useDispatch} from 'react-redux'
import {Route, Switch} from 'react-router-dom'

import classnames from 'classnames'
import {UIActions} from '@/actions'
import {isMobile} from '@/utils'

import {resultRouter} from './resultRouter'
import styles from './styles.module.less'

const Result = () => {
  const dispatch = useDispatch()

  useUnmount(() => {
    dispatch(UIActions.resetBackBreadcrumb({}))
  })

  return (
    <div className={classnames(styles.container, isMobile && styles.isMobile)}>
      <Switch>
        {resultRouter.map(({path, Component}) => (
          <Route path={path} key={path}>
            <Component />
          </Route>
        ))}
      </Switch>
    </div>
  )
}

export default memo(Result)
