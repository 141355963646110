import {Action} from 'redux-actions'
import {produce} from 'immer'

export const LoadingReducer = produce((state: {[actionName: string]: boolean} = {}, action: Action<any>) => {
  const {type} = action
  const matches = /(.*)@@LOADING_(START|END)$/g.exec(type)
  if (!matches) return state
  const [, actionType, loadingStatus] = matches
  state[actionType] = loadingStatus === 'START'
  return state
})
