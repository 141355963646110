import React, {memo, useState} from 'react'
import {Button, Modal, Input} from 'antd'
import {useBoolean, useMount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import SinglePage from '@/components/SinglePage'
import {AccountActions} from '@/actions'
import {AccountConstants} from '@/store/constants'

import {AccountCreationStatusEnum} from '@/constants/enums'

import _ from 'lodash'

import styles from './styles.module.less'
import AccountForm from '../../components/AccountForm'

const RegisterDetails = () => {
  const dispatch = useDispatch()
  const {id}: any = useParams()
  const [rejectModalVisible, {setTrue: openRejectModal, setFalse: closeRejectModal}] = useBoolean(false)
  const {account, rejectLoading, approveLoading} = useSelector((state) => ({
    account: _.get(state, ['account', 'details', id]),
    rejectLoading: _.get(state, ['loading', AccountConstants.REJECT_ACCOUNT_CREATION]),
    approveLoading: _.get(state, ['loading', AccountConstants.APPROVE_ACCOUNT_CREATION]),
  }))

  useMount(() => {
    id && dispatch(AccountActions.getAccountCreation({id}))
  })

  const handleReject = (reason: string) => {
    dispatch(
      AccountActions.rejectAccountCreation({
        id,
        version: account?.version,
        rejectReason: reason,
        onSuccess: closeRejectModal,
      })
    )
  }
  const handleApprove = () => {
    dispatch(AccountActions.approveAccountCreation({id, version: account?.version}))
  }

  const isAccountNotAwaitingCheck = account?.status !== AccountCreationStatusEnum.AwaitingCheck

  return (
    <div>
      <SinglePage
        title="申請情報詳細"
        bottom={
          <div>
            <Button className={styles.rejectButton} onClick={openRejectModal} disabled={isAccountNotAwaitingCheck}>
              差戻し
            </Button>
            <Button
              type="primary"
              loading={approveLoading}
              onClick={handleApprove}
              disabled={isAccountNotAwaitingCheck}>
              本アカウント発行
            </Button>
          </div>
        }>
        <AccountForm account={account} />
      </SinglePage>
      <RejectModal
        visible={rejectModalVisible}
        onCancel={closeRejectModal}
        onOk={handleReject}
        loading={rejectLoading}
      />
    </div>
  )
}

const RejectModal = ({
  visible,
  onOk,
  onCancel,
  loading,
}: {
  visible: boolean
  onOk: (reason: string) => void
  onCancel: () => void
  loading?: boolean
}) => {
  const [value, setValue] = useState('')
  return (
    <Modal
      title="却下理由"
      visible={visible}
      onOk={() => onOk(value)}
      onCancel={onCancel}
      okText="確認"
      cancelText="取消"
      confirmLoading={loading}
      closable={false}>
      <Input.TextArea placeholder="却下理由" autoSize onChange={(e) => setValue(e.target.value)} autoFocus />
    </Modal>
  )
}

export default memo(RegisterDetails)
