import React, {memo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Modal} from 'antd'
import _ from 'lodash'

import {Form, FormItem} from '@/components/Form'
import {AuthActions} from '@/actions'
import {AuthConstants} from '@/store/constants'

import styles from './styles.module.less'

interface IProps {
  visible: boolean
  onCancel: () => void
}

const SendResetPasswordTokenModal = ({visible, onCancel}: IProps) => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => _.get(state, ['loading', AuthConstants.REQUIRE_RESET_PASSWORD]))

  const onFinish = (values: any) => {
    dispatch(
      AuthActions.requireResetPassword({
        ...values,
        onSuccess: onCancel,
      })
    )
  }
  return (
    <Modal
      visible={visible}
      className={styles.sendResetPasswordTokenModal}
      closable={false}
      footer={null}
      onCancel={onCancel}
      centered>
      <p>アカウント登録時に使用したメールアドレスを入力してください。</p>
      <p>パスワードリセットの手順をメールにて送付いたします。</p>
      <Form className={styles.loginForm} hideRequiredMark layout="vertical" onFinish={onFinish}>
        <FormItem.Email />
        <Form.Item style={{textAlign: 'center'}}>
          <Button type="primary" className={styles.sendEmail} htmlType="submit" loading={loading}>
            送信
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default memo(SendResetPasswordTokenModal)
