import React, {useEffect} from 'react'
import {useMount, useUnmount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'

import {UIActions, AccountActions} from '@/actions'
import {AccountConstants} from '@/store/constants'

import _ from 'lodash'
import {history} from '@/routes'
import PlanForm from '@/pages/customer/components/PlanForm'
import {Button} from 'antd'
import SinglePage from '@/components/SinglePage'
import styles from '@/pages/common/order/styles.module.less'

const PlanConfirm = () => {
  const dispatch = useDispatch()

  const {myContractInfo} = useSelector(
    (state) => ({
      loading: _.get(state, ['loading', AccountConstants.GET_LEGAL_ENTITY_CONTRACT]),
      myContractInfo: _.get(state, ['account', 'myContractInfo'], {}),
    }),
    _.isEqual
  )

  useMount(() => {
    dispatch(AccountActions.getLegalEntityContract())
    dispatch(UIActions.resetBackBreadcrumb({backWay: '/', backTitle: '戻る'}))
  })
  useUnmount(() => {
    dispatch(UIActions.resetBackBreadcrumb({}))
  })

  useEffect(() => {
    document.body.scrollIntoView?.()
  }, [])

  const onClick = () => history.push(`/account/planChange`)

  const resendMail = () => {
    dispatch(AccountActions.resendLegalEntityContractOrder())
  }

  if (myContractInfo != null && myContractInfo.currentPlan == null) {
    return (
      <div>
        <SinglePage title="プラン確認">
          <div className="flexVertical">
            プラン決済が完了していないようです。
            <br />
            決済リンクをメールアドレスに再送しますか？
            <br />
            <Button type="link" onClick={resendMail} className={styles.resendMailLink}>
              プラン決済用のURLを再発行する
            </Button>
          </div>
        </SinglePage>
      </div>
    )
  }

  return <PlanForm onFinish={onClick} information={myContractInfo} />
}

export default PlanConfirm
