import {Action} from 'redux-actions'
import {call, put, takeLeading, select, takeLatest} from 'redux-saga/effects'
import _ from 'lodash'

import {AssessmentAPI} from '@/service'
import {AssessmentActions, TableConfigActions, UIActions} from '@/actions'
import {Enums} from '@/constants'
import {ActionCallback} from '@/utils'

import {AssessmentConstants} from '../constants'

function* listMyTransactionHistoriesAsSeller() {
  try {
    const paging = yield select((state) =>
      _.get(state, ['tableConfig', AssessmentConstants.LIST_MY_TRANSACTION_HISTORIES_AS_SELLER, 'paging'], {})
    )
    const res = yield call(AssessmentAPI.listMyTransactionHistories, {
      paging,
      viewType: Enums.TransactionHistoryViewTypeEnum.Seller,
      viewStatus: Enums.TransactionHistoryViewStatusEnum.All,
    })
    yield put(AssessmentActions.setListTransactionHistories({list: res.items, type: 'sellHistories'}))
    yield put(
      TableConfigActions.setTableConfig({
        type: AssessmentConstants.LIST_MY_TRANSACTION_HISTORIES_AS_SELLER,
        config: {pagination: {total: res.paging?.totalNumber}},
      })
    )
  } catch (err) {}
}

function* listMyTransactionHistoriesAsBuyer() {
  try {
    const paging = yield select((state) =>
      _.get(state, ['tableConfig', AssessmentConstants.LIST_MY_TRANSACTION_HISTORIES_AS_BUYER, 'paging'], {})
    )
    const res = yield call(AssessmentAPI.listMyTransactionHistories, {
      paging,
      viewType: Enums.TransactionHistoryViewTypeEnum.Buyer,
      viewStatus: Enums.TransactionHistoryViewStatusEnum.All,
    })
    yield put(AssessmentActions.setListTransactionHistories({list: res.items, type: 'buyHistories'}))
    yield put(
      TableConfigActions.setTableConfig({
        type: AssessmentConstants.LIST_MY_TRANSACTION_HISTORIES_AS_BUYER,
        config: {pagination: {total: res.paging?.totalNumber}},
      })
    )
  } catch (err) {}
}

function* listAdminTransactionHistories() {
  try {
    const paging = yield select((state) =>
      _.get(state, ['tableConfig', AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES, 'paging'], {})
    )
    const res = yield call(AssessmentAPI.listTransactionHistories, {
      paging,
      viewType: Enums.TransactionHistoryViewTypeEnum.Seller,
      viewStatus: Enums.TransactionHistoryViewStatusEnum.All,
    })
    yield put(AssessmentActions.setListTransactionHistories({list: res.items, type: 'adminHistories'}))
    yield put(
      TableConfigActions.setTableConfig({
        type: AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES,
        config: {pagination: {total: res.paging?.totalNumber}},
      })
    )
  } catch (err) {}
}

function* listAdminTransactionHistoriesForWithdrawal() {
  try {
    const paging = yield select((state) =>
      _.get(state, ['tableConfig', AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_WITHDRAWAL, 'paging'], {})
    )
    const res = yield call(AssessmentAPI.listTransactionHistories, {
      paging,
      viewType: Enums.TransactionHistoryViewTypeEnum.Seller,
      viewStatus: Enums.TransactionHistoryViewStatusEnum.All,
      transactionStatus: Enums.TransactionStatusEnum.TransactionConfirmed,
    })
    yield put(AssessmentActions.setListTransactionHistories({list: res.items, type: 'adminHistoriesForWithdrawal'}))
    yield put(
      TableConfigActions.setTableConfig({
        type: AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_WITHDRAWAL,
        config: {pagination: {total: res.paging?.totalNumber}},
      })
    )
  } catch (err) {}
}

function* listAdminTransactionHistoriesForPlatformDeliveryToBuyer() {
  try {
    const paging = yield select((state) =>
      _.get(
        state,
        ['tableConfig', AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_PLATFORM_DELIVERY_TO_BUYER, 'paging'],
        {}
      )
    )
    const res = yield call(AssessmentAPI.listTransactionHistories, {
      paging,
      'sorting.orders': 'created_at desc',
      viewType: Enums.TransactionHistoryViewTypeEnum.Seller,
      viewStatus: Enums.TransactionHistoryViewStatusEnum.All,
      transactionStatus: Enums.TransactionStatusEnum.SellerDeliveryToPlatformArranged,
    })
    yield put(
      AssessmentActions.setListTransactionHistories({list: res.items, type: 'adminHistoriesForPlatformDeliveryToBuyer'})
    )
    yield put(
      TableConfigActions.setTableConfig({
        type: AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_PLATFORM_DELIVERY_TO_BUYER,
        config: {pagination: {total: res.paging?.totalNumber}},
      })
    )
  } catch (err) {}
}

function* listAdminTransactionHistoriesForPlatformDeliveryToSeller() {
  try {
    const paging = yield select((state) =>
      _.get(
        state,
        ['tableConfig', AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_PLATFORM_DELIVERY_TO_SELLER, 'paging'],
        {}
      )
    )
    const res = yield call(AssessmentAPI.listTransactionHistories, {
      paging,
      viewType: Enums.TransactionHistoryViewTypeEnum.Seller,
      viewStatus: Enums.TransactionHistoryViewStatusEnum.All,
      transactionStatus: Enums.TransactionStatusEnum.BuyerDeliveryToPlatformArranged,
    })
    yield put(
      AssessmentActions.setListTransactionHistories({
        list: res.items,
        type: 'adminHistoriesForPlatformDeliveryToSeller',
      })
    )
    yield put(
      TableConfigActions.setTableConfig({
        type: AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_PLATFORM_DELIVERY_TO_SELLER,
        config: {pagination: {total: res.paging?.totalNumber}},
      })
    )
  } catch (err) {}
}

function* getAssessmentDetailForTransaction({payload}: Action<{id: string}>) {
  try {
    const {id} = payload
    const res = yield call(AssessmentAPI.getAssessmentDetailForTransaction, {id})
    yield put(AssessmentActions.setAssessmentDetailForTransaction({detail: res}))
  } catch (err) {}
}

function* getMyAssessmentDetailForTransaction({payload}: Action<{id: string; isSeller: boolean}>) {
  try {
    const {id, isSeller} = payload
    const res = yield call(AssessmentAPI.getMyAssessmentDetailForTransaction, {id, isSeller})
    yield put(AssessmentActions.setAssessmentDetailForTransaction({detail: res}))
  } catch (err) {}
}

function* getItemDetail({payload}: Action<{id: string}>) {
  try {
    const {id} = payload
    const res = yield call(AssessmentAPI.getItemDetail, {id})
    yield put(AssessmentActions.setItemDetail({detail: res}))
  } catch (err) {}
}

function* declineAssessment({payload}: Action<{itemId: string; id: string}>) {
  try {
    const {id, itemId} = payload
    yield call(AssessmentAPI.declineAssessment, {id})
    // TODO: message
    yield put(UIActions.showMessage({type: 'success', message: 'Success'}))
    yield put(AssessmentActions.getItemDetail({id: itemId}))
  } catch (err) {}
}

function* decideBuyer({payload}: Action<{itemId: string; id: string; assessmentReplyId: string}>) {
  try {
    const {id, assessmentReplyId, itemId} = payload
    yield call(AssessmentAPI.decideBuyer, {id, assessmentReplyId})
    // TODO: message
    yield put(UIActions.showMessage({type: 'success', message: 'Success'}))
    yield put(AssessmentActions.getItemDetail({id: itemId}))
  } catch (err) {}
}

function* withdrawal({payload}: Action<ActionCallback & {transactionId: string; version: string}>) {
  const {transactionId, version, onSuccess} = payload
  try {
    yield call(AssessmentAPI.withdrawal, {transactionId, version})
    yield put(UIActions.showMessage({type: 'success', message: '出金済みへ変更しました'}))
    onSuccess?.()
  } catch (error) {}
}

function* platformDeliveryToBuyer({
  payload,
}: Action<
  ActionCallback & {
    transactionId: string
    version: string
    trackNumber: string
    deliveryCompanyId: string
    deliveryCompanyOther: string
  }
>) {
  const {transactionId, version, trackNumber, deliveryCompanyId, deliveryCompanyOther, onSuccess} = payload
  try {
    yield call(AssessmentAPI.platformDeliveryToBuyer, {
      transactionId,
      version,
      trackNumber,
      deliveryCompanyId,
      deliveryCompanyOther,
    })
    yield put(UIActions.showMessage({type: 'success', message: '配送手配完了を更新しました'}))
    onSuccess?.()
  } catch (error) {}
}

function* platformDeliveryToSeller({
  payload,
}: Action<
  ActionCallback & {
    transactionId: string
    version: string
    trackNumber: string
    deliveryCompanyId: string
    deliveryCompanyOther: string
  }
>) {
  const {transactionId, version, trackNumber, deliveryCompanyId, deliveryCompanyOther, onSuccess} = payload
  try {
    yield call(AssessmentAPI.platformDeliveryToSeller, {
      transactionId,
      version,
      trackNumber,
      deliveryCompanyId,
      deliveryCompanyOther,
    })
    yield put(UIActions.showMessage({type: 'success', message: '返送手配完了を更新しました'}))
    onSuccess?.()
  } catch (error) {}
}

function* getDeliveryHistoryInfo({payload}: Action<ActionCallback & {transactionId: string}>) {
  const {transactionId} = payload
  try {
    const res = yield call(AssessmentAPI.getDeliveryHistoryInfo, {transactionId})
    if (!res) return
    yield put(AssessmentActions.setDeliveryHistoryInfo({[transactionId]: res}))
  } catch (error) {}
}

function* getBuyerDeliveryAddress({payload}: Action<ActionCallback & {transactionId: string}>) {
  const {transactionId} = payload
  try {
    const res = yield call(AssessmentAPI.getBuyerDeliveryAddress, {transactionId})
    if (!res) return
    yield put(AssessmentActions.setDeliveryInfo(res))
  } catch (error) {}
}

function* getSellerDeliveryAddress({payload}: Action<ActionCallback & {transactionId: string}>) {
  const {transactionId} = payload
  try {
    const res = yield call(AssessmentAPI.getSellerDeliveryAddress, {transactionId})
    if (!res) return
    yield put(AssessmentActions.setDeliveryInfo(res))
  } catch (error) {}
}

export const assessmentSaga = [
  takeLeading(AssessmentConstants.LIST_MY_TRANSACTION_HISTORIES_AS_SELLER, listMyTransactionHistoriesAsSeller),
  takeLeading(AssessmentConstants.LIST_MY_TRANSACTION_HISTORIES_AS_BUYER, listMyTransactionHistoriesAsBuyer),
  takeLeading(AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES, listAdminTransactionHistories),
  takeLeading(AssessmentConstants.GET_MY_ASSESSMENT_DETAIL_FOR_TRANSACTION, getMyAssessmentDetailForTransaction),
  takeLeading(AssessmentConstants.GET_ASSESSMENT_DETAIL_FOR_TRANSACTION, getAssessmentDetailForTransaction),
  takeLeading(AssessmentConstants.GET_ITEM_DETAIL, getItemDetail),
  takeLeading(AssessmentConstants.DECLINE_ASSESSMENT, declineAssessment),
  takeLeading(AssessmentConstants.DECIDE_BUYER, decideBuyer),
  takeLeading(
    AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_WITHDRAWAL,
    listAdminTransactionHistoriesForWithdrawal
  ),
  takeLeading(
    AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_PLATFORM_DELIVERY_TO_BUYER,
    listAdminTransactionHistoriesForPlatformDeliveryToBuyer
  ),
  takeLeading(
    AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_PLATFORM_DELIVERY_TO_SELLER,
    listAdminTransactionHistoriesForPlatformDeliveryToSeller
  ),
  takeLatest(AssessmentConstants.WITHDRAWAL, withdrawal),
  takeLatest(AssessmentConstants.PLATFORM_DELIVERY_TO_BUYER, platformDeliveryToBuyer),
  takeLatest(AssessmentConstants.PLATFORM_DELIVERY_TO_SELLER, platformDeliveryToSeller),
  takeLatest(AssessmentConstants.GET_DELIVERY_HISTORY_INFO, getDeliveryHistoryInfo),
  takeLatest(AssessmentConstants.GET_BUYER_DELIVERY_INFO, getBuyerDeliveryAddress),
  takeLatest(AssessmentConstants.GET_SELLER_DELIVERY_INFO, getSellerDeliveryAddress),
]
