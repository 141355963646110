import React, {memo, FC, useCallback, useEffect} from 'react'
import {useUnmount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import {Enums} from '@/constants'
import {PollingActions, UIActions} from '@/actions'

import styles from './styles.module.less'

interface IProps {
  actionType: string
  time?: number
}

const RefreshBar: FC<IProps> = memo(({actionType, time = 10 * 1000}) => {
  const dispatch = useDispatch()
  const {accountStatus} = useSelector((state) => ({
    accountStatus: _.get(state, ['account', 'me', 'accountStatus']),
  }))

  const cancelPollingWhenIsNotPermanent = useCallback(() => {
    const isNotPermanent = accountStatus !== Enums.AccountStatusEnum.PermanentRegistered
    if (isNotPermanent) {
      dispatch(PollingActions.pollingStop({type: actionType}))
    }
  }, [accountStatus])

  const onRefresh = () => {
    dispatch(PollingActions.pollingStart({time, type: actionType}))
    cancelPollingWhenIsNotPermanent()
  }

  const Bar = (
    <div className={styles.bar}>
      <span>画面自動更新({time / 1000}秒毎)</span>｜
      <span onClick={onRefresh} className={styles.refresh}>
        手動更新
      </span>
    </div>
  )

  useEffect(() => {
    if (accountStatus === undefined) return
    dispatch(UIActions.resetBackBreadcrumb({trailing: Bar}))
    dispatch(PollingActions.pollingStart({immediate: false, time, type: actionType}))
    cancelPollingWhenIsNotPermanent()
  }, [accountStatus])

  useUnmount(() => {
    dispatch(UIActions.resetBackBreadcrumb({}))
    dispatch(PollingActions.pollingStop({type: actionType}))
  })

  return null
})

export default RefreshBar
