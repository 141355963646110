import {handleActions} from '@/utils'
import {message as AntdMessage} from 'antd'
import {UIConstants} from '@/store/constants'
import _ from 'lodash'

const UIState = {
  backBreadcrumbTrailing: null,
  backBreadcrumbTitle: '',
  backBreadcrumbBackWay: '',
  headerTrailing: '',
}

const UIReducer = handleActions(
  {
    [UIConstants.SHOW_MESSAGE]: _.debounce<(__: any, action: any) => void>((__, {payload}) => {
      const {message, type} = payload
      if (type === 'success') {
        AntdMessage.success(message)
      } else if (type === 'error') {
        AntdMessage.error(message)
      } else if (type === 'warning') {
        AntdMessage.warning(message)
      } else {
        AntdMessage.info(message)
      }
    }, 0),
    [UIConstants.RESET_BACK_BREADCRUMB]: (state, {payload = {}}) => {
      const {backBreadcrumbTitle = '', backBreadcrumbBackWay = '', backBreadcrumbTrailing = null} = payload
      state.backBreadcrumbTitle = backBreadcrumbTitle
      state.backBreadcrumbBackWay = backBreadcrumbBackWay
      state.backBreadcrumbTrailing = backBreadcrumbTrailing
    },
    [UIConstants.RESET_HEADER_TRAILING]: (state, {payload = {}}) => {
      const {headerTrailing = ''} = payload
      state.headerTrailing = headerTrailing
    },
  },
  UIState
)

export {UIReducer}
