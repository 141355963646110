import React, {FC, memo, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import dayjs from 'dayjs'
import _ from 'lodash'
import {ColumnProps} from 'antd/lib/table'

import Image from '@/components/Image'
import {Button} from 'antd'
import {AssessmentActions, ContentActions} from '@/actions'
import {AssessmentConstants} from '@/store/constants'

export const columns: (moveToDeliveryInput: (record: any) => () => void) => ColumnProps<any>[] = (
  moveToDeliveryInput
) => [
  {
    title: '買取者の返送日時',
    dataIndex: 'updatedAt',
    render: (text) => (
      <>
        {dayjs.unix(text).format('YYYY/MM/DD')}
        <br />
        {dayjs.unix(text).format('HH:mm')}
      </>
    ),
  },
  {
    title: '商品画像',
    dataIndex: ['item', 'mainImage', 'thumbUrl'],
    render: (url) => <Image src={url} width={60} height={60} inline />,
  },
  {
    title: '出品業者',
    dataIndex: ['assessmentRequest', 'sellerLegalEntityName'],
  },
  {
    title: '買取業者',
    dataIndex: ['assessmentRequest', 'buyerLegalEntityName'],
  },
  {
    title: '追跡番号情報',
    dataIndex: 'transactionId',
    render: (transactionId) => <RenderDeliveryInfo transactionId={transactionId} />,
  },
  {
    dataIndex: 'transactionId',
    render: (transactionId, record) => (
      <Button type="primary" style={{marginRight: 4}} onClick={moveToDeliveryInput(record)}>
        配送情報入力
      </Button>
    ),
  },
]

const RenderDeliveryInfo: FC<{transactionId: string}> = memo(({transactionId}) => {
  const dispatch = useDispatch()
  const {deliveryInfo, loading, deliveryCompaniesList} = useSelector((state) => ({
    deliveryInfo: _.get(state, ['assessment', 'deliveryInfo', transactionId, 'buyerDeliveryInfo']),
    deliveryCompaniesList: _.get(state, ['content', 'deliveryCompaniesList'], []),
    loading: _.get(state, ['loading', AssessmentConstants.GET_DELIVERY_HISTORY_INFO]),
  }))

  const onClick = useCallback(() => {
    dispatch(AssessmentActions.getDeliveryHistoryInfo({transactionId}))
    if (deliveryCompaniesList.length === 0) {
      dispatch(ContentActions.listDeliveryCompanies())
    }
  }, [transactionId])

  let deliveryCompanyName = deliveryInfo?.deliveryCompanyOther
  if (deliveryInfo?.deliveryCompanyOther === '') {
    deliveryCompaniesList.forEach((item: any) => {
      if (item.id === deliveryInfo?.deliveryCompanyId) {
        deliveryCompanyName = item.name
      }
    })
  }

  const trackNumberInfo = `${deliveryCompanyName} 追跡番号：${deliveryInfo?.trackNumber}` || '--'

  if (!deliveryInfo) {
    return (
      <Button type="primary" onClick={onClick} loading={loading}>
        追跡番号表示
      </Button>
    )
  }
  return <span>{trackNumberInfo}</span>
})
