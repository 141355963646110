import React, {useEffect} from 'react'
import {Checkbox, Button} from 'antd'
import {useBoolean, useMount, useUnmount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'

import {Form, FormItem} from '@/components/Form'

import SinglePage from '@/components/SinglePage'
import {AccountActions, UIActions} from '@/actions'
import {AccountConstants} from '@/store/constants'
import {APP_JAVASCRIPT_CHANNEL_NAME} from '@/constants'

import _ from 'lodash'
import Text from 'antd/lib/typography/Text'

const TempRegister = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [isAgree, {toggle}] = useBoolean(false)
  const [isConfirming, {setTrue: setIsConfirming, setFalse: setIsNotConfirming}] = useBoolean(false)
  const loading = useSelector((state) => _.get(state, ['loading', AccountConstants.REGISTER_START]))
  const backWayTo = '/login'

  useMount(() => {
    dispatch(UIActions.resetHeaderTrailing({headerTrailing: 'アカウント申請'}))
    dispatch(UIActions.resetBackBreadcrumb({backWay: backWayTo, backTitle: '戻る'}))
  })
  useUnmount(() => {
    dispatch(UIActions.resetHeaderTrailing({}))
    dispatch(UIActions.resetBackBreadcrumb({}))
  })
  useEffect(() => {
    document.body.scrollIntoView?.()
  }, [isConfirming])

  const backToNotConfirming = () => {
    setIsNotConfirming()
    dispatch(UIActions.resetBackBreadcrumb({backWay: backWayTo, backTitle: '戻る'}))
  }

  const setBackBreadcrumbWhenIsComfiming = () => {
    dispatch(UIActions.resetBackBreadcrumb({backTitle: '戻る', backWay: backToNotConfirming}))
  }

  const onFinish = (values: {[field: string]: string}) => {
    if (isConfirming) {
      dispatch(
        AccountActions.register({
          ...values,
          onSuccess: () => {
            // call app's function when open in app
            // @ts-ignore
            window?.[APP_JAVASCRIPT_CHANNEL_NAME.CloseWebViwe]?.postMessage?.('登録が成功する')
          },
        })
      )
    } else {
      setIsConfirming()
      setBackBreadcrumbWhenIsComfiming()
    }
  }

  return (
    <div>
      <SinglePage
        title={isConfirming ? '仮アカウント申請確認' : '仮アカウント申請'}
        bottom={
          <div className="flexVertical">
            {isConfirming ? null : (
              <Text style={{marginBottom: 20}}>
                <a
                  href="https://sateicom-web-test.s3-ap-northeast-1.amazonaws.com/term/index.html"
                  rel="noopener noreferrer"
                  target="_blank">
                  利用規約を見る
                </a>
              </Text>
            )}
            {isConfirming ? null : (
              <Checkbox checked={isAgree} onChange={() => toggle()} style={{marginBottom: 30}}>
                利用規約に同意する
              </Checkbox>
            )}
            <div className="buttonGroup">
              {isConfirming ? <Button onClick={backToNotConfirming}>戻る</Button> : null}
              <Button
                loading={loading}
                disabled={!isAgree}
                type="primary"
                htmlType="submit"
                form="account_register_temporary">
                {isConfirming ? '申請' : '確認'}
              </Button>
            </div>
          </div>
        }>
        <Form form={form} name="account_register_temporary" layout="vertical" onFinish={onFinish} defaultStyle="around">
          <FormItem.SubTitle title="申請者情報" />
          <FormItem.FamilyName readOnly={isConfirming} />
          <FormItem.GivenName readOnly={isConfirming} />
          <FormItem.PlaceHolder inline />
          <FormItem.PlaceHolder block />
          <FormItem.Email readOnly={isConfirming} />
          <FormItem.SubTitle title="会社情報" />
          <FormItem.LegalEntityName readOnly={isConfirming} />
          <FormItem.PlaceHolder block />
          <FormItem.Tel readOnly={isConfirming} />
          <FormItem.PlaceHolder block />
          <FormItem.AntiqueLicenseNumber form={form} readOnly={isConfirming} />
          <FormItem.SpecialInternationalRegistrationNumber readOnly={isConfirming} />
          <FormItem.PlaceHolder inline />
          <FormItem.SubTitle title="会社住所情報" />
          <FormItem.Zipcode readOnly={isConfirming} form={form} />
          <FormItem.Prefecture readOnly={isConfirming} />
          <FormItem.City readOnly={isConfirming} />
          <FormItem.Street readOnly={isConfirming} />
          <FormItem.Building readOnly={isConfirming} />
          <FormItem.PlaceHolder inline />
        </Form>
      </SinglePage>
    </div>
  )
}

export default TempRegister
