import {Action} from 'redux-actions'
import {put, takeEvery, race, take, delay, call, putResolve} from 'redux-saga/effects'

import {PollingConstants} from '../constants'

function* pollingTask({payload, type, time, immediate}: any) {
  if (!immediate) yield delay(time)
  while (true) {
    yield putResolve({type, payload})
    yield delay(time)
  }
}

function* pollingStart({
  payload: pollingPayload,
}: Action<{payload: any; type: string; time: number; immediate?: boolean}>) {
  const {payload, type, time = 5 * 1000, immediate = true} = pollingPayload
  yield put({type: `${PollingConstants.POLLING_STOP}/${type}`})
  yield race({
    task: call(pollingTask, {payload, type, time, immediate}),
    cancel: take(`${PollingConstants.POLLING_STOP}/${type}`),
  })
}

function* pollingStop({payload = {}}: Action<any>) {
  const {type} = payload
  yield put({type: `${PollingConstants.POLLING_STOP}/${type}`})
}

export const pollingSaga = [
  takeEvery(PollingConstants.POLLING_START, pollingStart),
  takeEvery(PollingConstants.POLLING_STOP, pollingStop),
]
