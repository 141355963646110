import React, {memo, useEffect} from 'react'
import {useMount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {Divider} from 'antd'
import _ from 'lodash'

import LikeTabContainer from '@/components/LikeTabContainer'
import {EnumsText} from '@/constants'
import {AssessmentActions} from '@/actions'

import {CommodityInfo, AssessmentInfo, Title, EntityInfo} from '@/pages/customer/components/TransactionUtils'

const TransactionDetails = () => {
  const dispatch = useDispatch()
  const {id}: any = useParams()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {transactionDetails, itemDetails, itemId} = useSelector((state) => {
    const tempTransactionDetails = _.get(state, ['assessment', 'transactionDetails', id], {})
    const tempItemId = tempTransactionDetails?.item?.id
    const tempItemDetails = _.get(state, ['assessment', 'itemDetails', tempItemId], {})
    return {
      itemId: tempItemId,
      transactionDetails: tempTransactionDetails,
      itemDetails: tempItemDetails,
    }
  }, _.isEqual)

  useMount(() => {
    id && dispatch(AssessmentActions.getAssessmentDetailForTransaction({id}))
  })

  useEffect(() => {
    itemId && dispatch(AssessmentActions.getItemDetail({id: itemId}))
  }, [itemId])

  return (
    <LikeTabContainer title="取引詳細">
      <AssessmentInfo
        assessmentRequestInfo={transactionDetails.assessmentRequest}
        assessmentReplyInfo={transactionDetails.buyerReply}
        transactionInfo={transactionDetails.transaction}
        transactionStatusText={EnumsText.TransactionStatusEnum.FOR_ADMIN}
      />
      <Divider />
      <Title>商品情報</Title>
      <CommodityInfo info={transactionDetails.item} />
      <Divider />
      <Title>取引会社情報</Title>
      <EntityInfo buyerInfo={transactionDetails.buyerReply} sellerInfo={transactionDetails.assessmentRequest} />
      <Divider />
      <Title>取引履歴</Title>
    </LikeTabContainer>
  )
}

export default memo(TransactionDetails)
