import {AxiosResponse} from 'axios'

type ResponseInterceptor = (value: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>
const responseInterceptor: ResponseInterceptor = (response) => {
  // do something before response
  console.log('%c SUCCESS ', 'color: white; background-color: #95B46A', response.data)
  return response.data
}

export default responseInterceptor
