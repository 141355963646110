import {takeLatest, call, put, takeLeading} from 'redux-saga/effects'
import {Action} from 'redux-actions'
import {ContentAPI} from '@/service'
import {ContentActions, UIActions} from '@/actions'

import {ContentConstants} from '../constants'

function* getBankList() {
  try {
    const res = yield call(ContentAPI.listBank)
    yield put(ContentActions.setListBank({list: res.banks}))
  } catch (err) {}
}

function* getBranchBankList({payload}: Action<{bankCode: string}>) {
  try {
    const {bankCode} = payload
    const res = yield call(ContentAPI.listBankBranch, {bankCode})
    yield put(ContentActions.setListBankBranch({list: res.bankBranches, bankCode}))
  } catch (err) {}
}

function* getListDeliveryCompanies() {
  try {
    const res = yield call(ContentAPI.listDeliveryCompanies)
    yield put(ContentActions.setListDeliveryCompanies({list: res.deliveryCompanies}))
  } catch (err) {}
}

function* listInformation() {
  try {
    const res = yield call(ContentAPI.listInformation)
    yield put(ContentActions.setListInformation({list: res.informationList}))
  } catch (err) {}
}

function* getInformation({payload}: Action<{id: string}>) {
  try {
    const {id} = payload
    const res = yield call(ContentAPI.getInformation, {id})
    yield put(ContentActions.setGetInformation(res))
  } catch (err) {}
}

function* createInformation({payload}: Action<{information: any; onSuccess: () => void}>) {
  try {
    const {information, onSuccess} = payload
    yield call(ContentAPI.createInformation, information)
    yield put(UIActions.showMessage({type: 'success', message: 'create success'}))
    onSuccess?.()
  } catch (err) {}
}

function* updateInformation({payload}: Action<{information: any; onSuccess: () => void}>) {
  try {
    const {information, onSuccess} = payload
    yield call(ContentAPI.updateInformation, information)
    yield put(UIActions.showMessage({type: 'success', message: 'update success'}))
    yield put(ContentActions.getInformation({id: information.id}))
    onSuccess?.()
  } catch (err) {}
}

export const contentSaga = [
  takeLeading(ContentConstants.LIST_BANK, getBankList),
  takeLatest(ContentConstants.LIST_BANK_BRANCH, getBranchBankList),
  takeLeading(ContentConstants.LIST_DELIVERY_COMPANIES, getListDeliveryCompanies),
  takeLeading(ContentConstants.LIST_INFORMATION, listInformation),
  takeLatest(ContentConstants.GET_INFORMATION, getInformation),
  takeLatest(ContentConstants.CREATE_INFORMATION, createInformation),
  takeLatest(ContentConstants.UPDATE_INFORMATION, updateInformation),
]
