import {Action} from 'redux-actions'
import {call, takeLeading, put, takeLatest} from 'redux-saga/effects'
import {OrderAPI} from '@/service'
import {OrderActions, UIActions} from '@/actions'
import {ActionCallback} from '@/utils'

import {OrderConstants} from '../constants'

function* getOrder({payload = {}}: Action<any>) {
  try {
    const {id} = payload
    const res = yield call(OrderAPI.getOrder, {id})
    yield put(OrderActions.setOrder(res))
  } catch (err) {}
}

function* refundOrder({payload}: Action<{orderId: string; refundAmount: string; version: string} & ActionCallback>) {
  try {
    const {orderId, refundAmount, version, onSuccess} = payload
    yield call(OrderAPI.refundOrder, {orderId, refundAmount, version})
    yield put(UIActions.showMessage({type: 'success', message: '返金済みへ変更しました'}))
    onSuccess?.()
  } catch (err) {}
}

export const orderSaga = [
  takeLeading(OrderConstants.GET_ORDER, getOrder),
  takeLatest(OrderConstants.REFUND_ORDER, refundOrder),
]
