import React, {memo} from 'react'
import {FormInstance} from 'antd/lib/form'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import {ContentActions} from '@/actions'
import {ContentConstants} from '@/store/constants'

import InformationForm from '../../components/InformationForm'

const InformationCreate = () => {
  const dispatch = useDispatch()

  const loading = useSelector((state) => _.get(state, ['loading', ContentConstants.CREATE_INFORMATION]))

  const onFinish = (values: any, form: FormInstance) => {
    dispatch(ContentActions.createInformation({information: values, onSuccess: () => form.resetFields()}))
  }

  return <InformationForm title="お知らせ登録" buttonLoading={loading} onFinish={onFinish} buttonTitle="登録" />
}

export default memo(InformationCreate)
