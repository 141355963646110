import {handleActions} from '@/utils'
import {PollingConstants} from '@/store/constants'

const PollingState: {
  [actionName: string]: any
} = {}

const PollingReducer = handleActions(
  {
    [PollingConstants.POLLING_START]: (state, {payload: pollingType}) => {
      const {type, payload = {}, ...pollingOption} = pollingType
      state[type] = {payload, pollingOption}
    },
    [PollingConstants.POLLING_STOP]: (state, {payload = {}}) => {
      const {type} = payload
      state[type] = false
    },
  },
  PollingState
)

export {PollingReducer}
