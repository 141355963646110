import produce, {Draft} from 'immer'
import {useState, useCallback, useRef, useEffect} from 'react'
import {handleActions as raHandleActions, ReduxCompatibleReducer} from 'redux-actions'
import _ from 'lodash'

export const getCssVariable = (variable: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(variable).trim()

type ReducerMap<T> = {[action: string]: (state: T, action: {type: string; payload?: any}) => void}
export const handleActions: <T>(actions: ReducerMap<T>, initState: T) => ReduxCompatibleReducer<T, any> = (
  actions,
  initState
) =>
  raHandleActions(
    Object.keys(actions).reduce((acc: any, key) => {
      acc[key] = produce(actions[key] as any)
      return acc
    }, {}),
    initState
  )

export function useImmer<S = any>(initialValue: S | (() => S)): [S, (f: (draft: Draft<S>) => void | S) => void]
export function useImmer(initialValue: any) {
  const [val, updateValue] = useState(initialValue)
  return [
    val,
    useCallback((updater) => {
      updateValue(produce(updater))
    }, []),
  ]
}
export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
export function backLogin(replace = false) {
  if (replace) {
    const callback = window.location.pathname + window.location.search
    window.location.replace(`/login?callback=${callback}`)
  } else {
    window.location.href = '/login'
  }
}

export function getEnumTextByEnum(Enum: any, EnumText: any, defaultText = '-') {
  return EnumText[Enum] || defaultText
}

export const buildType = process.env.REACT_APP_BUILD_TYPE as 'ADMIN' | 'CUSTOMER' | undefined

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  window?.navigator?.userAgent
)

export function flatObj(obj: any) {
  const temp: any = {}
  const queue = [{prop: '', value: obj}]
  while (queue.length) {
    const {prop, value} = queue.shift() || {}
    Object.keys(value).forEach((key) => {
      if (typeof value[key] === 'object') {
        queue.push({prop: `${prop}${prop && '.'}${key}`, value: value[key]})
      } else {
        temp[`${prop}${prop && '.'}${key}`] = value[key]
      }
    })
  }
  return temp
}

export function convertTableParams(payload: any) {
  const {paging = {}, ...rest} = payload
  return {...flatObj({paging}), ...rest}
}
export interface ActionCallback {
  onSuccess?: (...rest: any[]) => void
  onFail?: (...rest: any[]) => void
}

export function useCompareEffect(effect: React.EffectCallback, dependencies?: React.DependencyList) {
  function deepCompareDependencies(value: any) {
    const ref = useRef()

    if (!_.isEqual(value, ref.current)) {
      ref.current = value
    }

    return ref.current
  }

  useEffect(effect, deepCompareDependencies(dependencies))
}
