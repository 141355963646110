import React, {FC, memo} from 'react'
import {Layout, Button, Dropdown} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'
import _ from 'lodash'

import {isMobile} from '@/utils'
import {AuthActions} from '@/actions'
import Image, {ImgStore} from '@/components/Image'
import {ErrorBoundary} from '@/components/ErrorBoundaries'
import {Menu} from '@/wrappers/SiderMenu'

import styles from './styles.module.less'

const {Header} = Layout

const LoginInfo = memo(({email}: {email: string}) => {
  const dispatch = useDispatch()
  const logout = () => dispatch(AuthActions.logout())
  return (
    <>
      <span>{email}</span>
      <Button onClick={logout} type="primary" className={styles.logoutButton}>
        ログアウト
      </Button>
    </>
  )
})

const LayoutHeader: FC = ({children}) => {
  const {trailing, email} = useSelector((state) => ({
    trailing: _.get(state, ['ui', 'headerTrailing']),
    email: _.get(state, ['auth', 'account', 'email'], ''),
  }))

  return (
    <Layout style={{backgroundColor: 'transparent'}}>
      {isMobile ? null : (
        <Header className={classnames(styles.header, isMobile && styles.isMobile)}>
          {isMobile ? (
            <MobileHeaderContent trailing={trailing} />
          ) : (
            <>
              <div className={classnames(styles.logo)}>
                <Image src={ImgStore.PCLogo} />
              </div>
              <div>{email ? <LoginInfo email={email} /> : trailing}</div>
            </>
          )}
        </Header>
      )}
      <ErrorBoundary>{children}</ErrorBoundary>
    </Layout>
  )
}

const MobileHeaderContent: FC<{trailing: string}> = ({trailing}) => {
  return (
    <>
      <Dropdown overlay={Menu} trigger={['click']} overlayClassName={styles.dropdownContainer}>
        <div className={styles.menuOutlinedContainer}>
          <span className={styles.menuOutlined}>
            <i />
            <i />
            <i />
          </span>
        </div>
      </Dropdown>
      <Image src={ImgStore.MobileLogo} className={styles.aboluteCenter} />
      <span>{trailing}</span>
    </>
  )
}

export default memo(LayoutHeader)
