import React, {memo} from 'react'
import {FormInstance} from 'antd/lib/form'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import {AssessmentActions} from '@/actions'
import {AssessmentConstants} from '@/store/constants'

import {useParams} from 'react-router-dom'
import {useMount} from 'ahooks'
import SinglePage from '@/components/SinglePage'
import DeliveryForm from '../components/DeliveryForm'

const PlatformDeliveryInputToSeller = () => {
  const dispatch = useDispatch()
  const {transactionId, version}: any = useParams()
  const {sellerDeliveryInfo, loading} = useSelector((state) => ({
    sellerDeliveryInfo: _.get(state, ['assessment', 'deliveryInfo']),
    loading: _.get(state, ['loading', AssessmentConstants.GET_SELLER_DELIVERY_INFO]),
  }))

  useMount(() => {
    transactionId && dispatch(AssessmentActions.getSellerDeliveryInfo({transactionId}))
  })

  const onFinish = (values: any, form: FormInstance) => {
    dispatch(
      AssessmentActions.platformDeliveryToSeller({
        transactionId,
        version,
        trackNumber: values.trackNumber,
        deliveryCompanyId: values.deliveryCompanyId,
        deliveryCompanyOther: values.deliveryCompanyOther,
        onSuccess: () => form.resetFields(),
      })
    )
  }

  return (
    <div>
      <SinglePage title="配送情報入力">
        <DeliveryForm
          deliveryInfo={sellerDeliveryInfo}
          buttonLoading={loading}
          onFinish={onFinish}
          buttonTitle="登録"
        />
      </SinglePage>
    </div>
  )
}

export default memo(PlatformDeliveryInputToSeller)
