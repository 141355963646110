import React, {useCallback} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import Table from '@/components/Table'
import {history} from '@/routes'
import {AssessmentActions} from '@/actions'
import {AssessmentConstants} from '@/store/constants'

import RefreshBar from '../components/RefreshBar'
import {columns} from './colConfig'

export default () => {
  const dispatch = useDispatch()
  const dataSource = useSelector((state) => _.get(state, ['assessment', 'buyHistories'], []))

  const getData = () => dispatch(AssessmentActions.listMyTransactionHistoriesAsBuyer({}))
  const onRow = useCallback(
    (record) => ({
      onClick: () => record.status && history.push(`/buyHistories/${record.assessmentRequest?.id}`),
    }),
    []
  )

  return (
    <>
      <RefreshBar actionType={AssessmentConstants.LIST_MY_TRANSACTION_HISTORIES_AS_BUYER} />
      <Table
        onRow={onRow}
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record.item?.itemId}
        actionType={AssessmentConstants.LIST_MY_TRANSACTION_HISTORIES_AS_BUYER}
        onChange={getData}
        csvDownload
        csvFilename="査定履歴"
        rowClassName={(record) => record.status || 'cursor-default'}
      />
    </>
  )
}
