import {createAction} from 'redux-actions'
import {AccountConstants} from '@/store/constants'
import {ActionCallback} from '@/utils'
import {MailVerifyTypeEnum} from '@/constants/enums'

export const AccountActions = {
  register: createAction<{[field: string]: any} & ActionCallback>(AccountConstants.REGISTER_START),
  registerSuccess: createAction(AccountConstants.REGISTER_SUCCESS),
  upgradeMe: createAction<{account: any}>(AccountConstants.UPGRADE_ME),
  verifyUpgradeMe: createAction<{account: any} & ActionCallback>(AccountConstants.VERIFY_UPGRADE_ME),
  getMe: createAction(AccountConstants.GET_ME),
  verifyEmail: createAction<{token: string; verifyType: MailVerifyTypeEnum} & ActionCallback>(
    AccountConstants.VERIFY_EMAIL
  ),
  changeMyEmail: createAction<{email: string}>(AccountConstants.CHANGE_MY_EMAIL),
  updateMe: createAction<{account: any} & ActionCallback>(AccountConstants.UPDATE_ME),
  getMyAccountCreation: createAction(AccountConstants.GET_MY_ACCOUNT_CREATION),
  listLegalEntityAccounts: createAction(AccountConstants.LIST_LEGAL_ENTITY_ACCOUNTS),
  listAccounts: createAction(AccountConstants.LIST_ACCOUNTS),
  getAccountFullInfo: createAction<{id: string} & ActionCallback>(AccountConstants.GET_ACCOUNT_FULL_INFO),
  listAccountCreation: createAction(AccountConstants.LIST_ACCOUNT_CREATION),
  getAccountCreation: createAction<{id: string} & ActionCallback>(AccountConstants.GET_ACCOUNT_CREATION),
  approveAccountCreation: createAction<{id: string; version: number} & ActionCallback>(
    AccountConstants.APPROVE_ACCOUNT_CREATION
  ),
  rejectAccountCreation: createAction<{id: string; version: number; rejectReason: string} & ActionCallback>(
    AccountConstants.REJECT_ACCOUNT_CREATION
  ),
  createSubAccount: createAction<{subAccountInfo: any} & ActionCallback>(AccountConstants.CREATE_SUB_ACCOUNT),
  getLegalEntityContract: createAction(AccountConstants.GET_LEGAL_ENTITY_CONTRACT),
  resendLegalEntityContractOrder: createAction(AccountConstants.RESEND_LEGAL_ENTITY_CONTRACT_ORDER),

  setAccountCreation: createAction(AccountConstants.SET_ACCOUNT_CREATION),
  setListAccountCreation: createAction<{accounts: any}>(AccountConstants.SET_LIST_ACCOUNT_CREATION),
  setListAccounts: createAction<{accounts: any}>(AccountConstants.SET_LIST_ACCOUNTS),
  setLegalEntityContract: createAction<{contractInfo: any}>(AccountConstants.SET_LEGAL_ENTITY_CONTRACT),
  setAccountFullInfo: createAction(AccountConstants.SET_ACCOUNT_FULL_INFO),
  setMe: createAction<{account: any; legalEntity: any}>(AccountConstants.SET_ME),
  setMyAccountCreation: createAction(AccountConstants.SET_MY_ACCOUNT_CREATION),
  changePlan: createAction<{planId: string} & ActionCallback>(AccountConstants.CHANGE_PLAN),
}
