import SellHistories from '@/pages/customer/sellHistories'
import BuyHistories from '@/pages/customer/buyHistories'
import SellDetails from '@/pages/customer/sellHistories/details'
import BuyDetails from '@/pages/customer/buyHistories/details'

import Email from '@/pages/common/email'
import Password from '@/pages/common/password'
import NotFound from '@/pages/common/exception/notFound'
import Login from '@/pages/common/login'
import SubAccount from '@/pages/common/subAccount'
import Register from '@/pages/common/register'
import TempRegister from '@/pages/common/tempRegister'
import Result from '@/pages/common/result'
import Order from '@/pages/common/order'

import AuthWrapper from '@/wrappers/Auth'
import ContentWrapper from '@/wrappers/Content'
import BreadcrumbWrapper from '@/wrappers/Breadcrumb'
import SiderMenuWrapper from '@/wrappers/SiderMenu'
import HeaderWrapper from '@/wrappers/Header'
import LayoutWrapper from '@/wrappers/Layout'

import {IRoutesConfig} from '@/routes'
import PlanConfirm from '@/pages/customer/plan'
import PlanChange from '@/pages/customer/planChange'

const accountWrappers = [ContentWrapper, BreadcrumbWrapper, HeaderWrapper, LayoutWrapper]
const accountWrappersWithAuth = [ContentWrapper, BreadcrumbWrapper, HeaderWrapper, LayoutWrapper, AuthWrapper]

const routes: IRoutesConfig[] = [
  {path: '/', redirect: '/sellHistories', exact: true},
  {path: '/account/password', redirect: '/account/password/reset', exact: true},
  {path: '/account/email', redirect: '/account/email/change', exact: true},

  {path: '/notFound', component: NotFound, wrappers: [HeaderWrapper, LayoutWrapper]},
  {path: '/login', component: Login, wrappers: [HeaderWrapper, LayoutWrapper]},

  {path: '/account/register/temporary/success', wrappers: accountWrappers, component: Result},
  {path: '/account/register/temporary', wrappers: accountWrappers, component: TempRegister},
  {path: '/account/password/reset', wrappers: accountWrappers, component: Password},
  {path: '/account/password/change', wrappers: accountWrappers, component: Password},
  {path: '/account/email/verify', wrappers: accountWrappers, component: Result},
  {path: '/payment/paypal/return', wrappers: accountWrappers, component: Result},
  {path: '/payment/paypal/cancel', wrappers: accountWrappers, component: Result},
  {path: '/account/plan', wrappers: accountWrappers, component: PlanConfirm},
  {path: '/account/planChange', wrappers: accountWrappers, component: PlanChange},
  {
    path: '/account',
    wrappers: accountWrappersWithAuth,
    routes: [
      {path: '/account/register/formal/success', component: Result},
      {path: '/account/register/formal', component: Register},
      {path: '/account/subAccount/success', component: Result},
      {path: '/account/subAccount', component: SubAccount},
      {path: '/account/email/change', component: Email},
      {path: '/account/order/pay/success', component: Result},
      {path: '/account/order/:id/pay', component: Order},
      {path: '*', redirect: '/notFound'},
    ],
  },

  {
    path: '/',
    wrappers: [ContentWrapper, BreadcrumbWrapper, SiderMenuWrapper, HeaderWrapper, LayoutWrapper, AuthWrapper],
    routes: [
      {path: '/sellHistories/:id', component: SellDetails},
      {path: '/buyHistories/:id', component: BuyDetails},
      {path: '/sellHistories', component: SellHistories},
      {path: '/buyHistories', component: BuyHistories},
      {path: '*', redirect: '/notFound'},
    ],
  },
]

export default routes
