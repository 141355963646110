import React from 'react'
import {ColumnProps} from 'antd/lib/table'
import dayjs from 'dayjs'
import {Enums} from '@/constants'
import {Button} from 'antd'

export const columns: (
  onDecideBuyer: (assessmentReplyId: string) => () => void,
  canSellerDeciding: boolean,
  decideBuyerLoading?: boolean
) => ColumnProps<any>[] = (onDecideBuyer, canSellerDeciding, decideBuyerLoading) => [
  {
    title: '会社名',
    dataIndex: 'userDisplayId',
    render: (text, {userPrefecture}: any) => (
      <>
        {text}
        <br />
        {userPrefecture}
      </>
    ),
  },
  {
    title: 'レスポンス日時',
    dataIndex: 'assessedAt',
    render: (text) => (
      <>
        {dayjs.unix(text).format('YYYY/MM/DD')}
        <br />
        {dayjs.unix(text).format('HH:mm')}
      </>
    ),
  },
  {
    title: '現物確認',
    dataIndex: 'needActualConfirm',
    // TODO: render when needActualConfirm = false
    render: (text) => (text ? '現物確認希望' : '-'),
  },
  {
    title: '送落札時の送料負担',
    dataIndex: 'bearDeliveryFeeOnBid',
    render: (text) => (text ? '買取者が負担' : '出品者が負担'),
  },
  {
    title: '入金条件',
    dataIndex: 'daysToPayment',
    render: (text) => `取引成立から${text}日以内`,
  },
  {
    title: '査定額',
    dataIndex: 'status',
    render: (text, {firstBiddingPrice, oneChanceBiddingPrice}: any) => {
      // TODO: check
      if (text === Enums.AssessmentReplyStatusEnum.FirstTimeReplied) {
        return Number(firstBiddingPrice).toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'})
      }
      if (text === Enums.AssessmentReplyStatusEnum.OneChanceReplied) {
        return Number(oneChanceBiddingPrice).toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'})
      }
      return '-'
    },
  },
  {
    title: '取扱い可能な配送業者',
    dataIndex: 'handleableDeliveryCompanies',
    render: (text = []) => text.map((company: {name: any}) => company.name).join('/'),
  },
  {
    title: '備考',
    dataIndex: 'note',
  },
  {
    align: 'right',
    render: (__, {id}) => (
      <Button
        type="primary"
        style={{marginRight: 4}}
        onClick={onDecideBuyer(id)}
        loading={decideBuyerLoading}
        disabled={!canSellerDeciding}>
        売却
      </Button>
    ),
  },
]
