import {createAction} from 'redux-actions'

import {UIConstants} from '@/store/constants'

export const UIActions = {
  showMessage: createAction<{message: string; type?: 'success' | 'error' | 'warning'}>(UIConstants.SHOW_MESSAGE),
  resetHeaderTrailing: createAction<{headerTrailing?: React.ReactNode}>(UIConstants.RESET_HEADER_TRAILING),
  resetBackBreadcrumb: createAction(
    UIConstants.RESET_BACK_BREADCRUMB,
    ({
      backTitle,
      backWay,
      trailing,
    }: {
      backTitle?: string
      backWay?: string | (() => void)
      trailing?: React.ReactNode
    }) => ({
      backBreadcrumbTitle: backTitle,
      backBreadcrumbBackWay: backWay,
      backBreadcrumbTrailing: trailing,
    })
  ),
}
