import React, {useEffect} from 'react'
import {Button} from 'antd'
import {useMount, useUnmount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'
import {Form, FormItem} from '@/components/Form'

import SinglePage from '@/components/SinglePage'
import {AccountActions, UIActions} from '@/actions'
import {AccountConstants} from '@/store/constants'

import _ from 'lodash'

const PlanChange = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const {loading, myContractInfo} = useSelector(
    (state) => ({
      loading: _.get(state, ['loading', AccountConstants.CHANGE_PLAN]),
      myContractInfo: _.get(state, ['account', 'myContractInfo'], {}),
    }),
    _.isEqual
  )
  useMount(() => {
    dispatch(UIActions.resetBackBreadcrumb({backWay: '/', backTitle: '戻る'}))
  })
  useUnmount(() => {
    dispatch(UIActions.resetBackBreadcrumb({}))
  })

  useEffect(() => {
    document.body.scrollIntoView?.()
  }, [])

  const onFinish = (values: {[field: string]: string}) => {
    let planId = ''
    if (values.isAuction) {
      planId = values.planId
    }
    dispatch(AccountActions.changePlan({planId}))
  }

  let planName = 'プランなし'

  if (myContractInfo != null && myContractInfo.currentPlan != null) {
    planName = myContractInfo.currentPlan.planName
  }

  return (
    <div>
      <SinglePage
        title="プラン変更"
        bottom={
          <div className="flexVertical">
            <Button loading={loading} type="primary" htmlType="submit" form="change_plan">
              変更
            </Button>
          </div>
        }>
        <Form form={form} name="change_plan" layout="vertical" onFinish={onFinish} defaultStyle="around">
          <FormItem.SubTitle title="現在のプラン" />
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-control">{planName}</div>
          </div>
          <FormItem.PlaceHolder inline />
          <FormItem.PlaceHolder block />
          <FormItem.SubTitle title="変更後のプラン" />
          <FormItem.IsAuction noPlaceHolder form={form} />
          <FormItem.PlaceHolder inline />
          <FormItem.PlaceHolder block />
          <FormItem.SelectPlanId noPlaceHolder form={form} />
        </Form>
      </SinglePage>
    </div>
  )
}

export default PlanChange
