import {handleActions} from '@/utils'
import {AssessmentConstants} from '@/store/constants'
import _ from 'lodash'

const AssessmentState = {
  buyHistories: [],
  sellHistories: [],
  adminHistories: [],
  adminHistoriesForWithdrawal: [],
  adminHistoriesForPlatformDeliveryToBuyer: [],
  adminHistoriesForPlatformDeliveryToSeller: [],
  transactionDetails: {} as any,
  itemDetails: {} as any,
  deliveryInfo: {} as any,
}

const AssessmentReducer = handleActions(
  {
    [AssessmentConstants.SET_LIST_TRANSACTION_HISTORIES]: (state, {payload}) => {
      const {list = [], type} = payload as {
        type:
          | 'buyHistories'
          | 'sellHistories'
          | 'adminHistories'
          | 'adminHistoriesForWithdrawal'
          | 'adminHistoriesForPlatformDeliveryToBuyer'
          | 'adminHistoriesForPlatformDeliveryToSeller'
        list: never[]
      }
      state[type] = list
    },
    [AssessmentConstants.SET_ASSESSMENT_DETAIL_FOR_TRANSACTION]: (state, {payload}) => {
      const {detail = {}} = payload
      state.transactionDetails[detail.assessmentRequest?.id] = detail
    },
    [AssessmentConstants.SET_ITEM_DETAIL]: (state, {payload}) => {
      const {detail = {}} = payload
      state.itemDetails[detail.item?.id] = detail
    },
    [AssessmentConstants.SET_DELIVERY_HISTORY_INFO]: (state, {payload}) => {
      _.merge(state.deliveryInfo, payload)
    },
    [AssessmentConstants.SET_DELIVERY_INFO]: (state, {payload}) => {
      _.merge(state.deliveryInfo, payload)
    },
  },
  AssessmentState
)

export {AssessmentReducer}
