import React from 'react'
import {Router} from 'react-router-dom'
import {Provider} from 'react-redux'
import {ConfigProvider} from 'antd'
import dayjs from 'dayjs'
import jaJP from 'antd/es/locale/ja_JP'
import 'dayjs/locale/ja'

import {SwitchComponent, routesConfig, history} from '@/routes'

import {store} from './store'

dayjs.locale('ja')

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <ConfigProvider locale={jaJP}>
          <SwitchComponent config={routesConfig} />
        </ConfigProvider>
      </Router>
    </Provider>
  )
}

export default App
