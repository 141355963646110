/* eslint-disable no-param-reassign */
/* eslint-disable complexity,no-console  */
import _ from 'lodash'
import {store} from '@/store'
import {UIActions} from '@/actions'
import {backLogin} from '@/utils'

const ReqeustErrorText = {
  UNDEFINED_ERROR: 'Undefined Error',
  UNKNOWN_ERROR: 'Unknown Error',
  TIMEOUT_ERROR: 'Request Timeout',
  NETWORK_ERROR: 'Network Error',
  NOT_LOGIN_ERROR: 'ログインしてください',
}
type ResponseErrorInterceptor = (error: any) => any
const responseErrorInterceptor: ResponseErrorInterceptor = (error) => {
  if (error.response) {
    const errMessage =
      _.get(error, 'response.data.message') || _.get(error, 'response.statusText', ReqeustErrorText.UNDEFINED_ERROR)
    if (errMessage === ReqeustErrorText.UNDEFINED_ERROR) {
      console.log('%c ERROR ', 'color: white; background-color: #df3314', error.response.status, error.response.data)
    }
    const showToast = _.get(error, 'response.config.headers.st')
    if (showToast) {
      store.dispatch(UIActions.showMessage({message: errMessage, type: 'error'}))
    }
    error.errCode = _.get(error, 'response.data.details[0].code', 0)
    error.message = errMessage
    if (error.response?.status === 401) backLogin(true)
  } else {
    console.log('%c ERROR ', 'color: white; background-color: #df3314', 'Error', error.message)
    if (error.message.includes('timeout')) {
      store.dispatch(UIActions.showMessage({message: ReqeustErrorText.TIMEOUT_ERROR, type: 'error'}))
    } else if (error.message.includes('Network')) {
      store.dispatch(UIActions.showMessage({message: ReqeustErrorText.NETWORK_ERROR, type: 'error'}))
    } else if (error.message.includes('NotLogin')) {
      store.dispatch(UIActions.showMessage({message: ReqeustErrorText.NOT_LOGIN_ERROR, type: 'warning'}))
      backLogin(true)
    } else {
      store.dispatch(UIActions.showMessage({message: error.message || ReqeustErrorText.UNKNOWN_ERROR, type: 'error'}))
    }
  }
  // do something if response error
  throw error
}
export default responseErrorInterceptor
