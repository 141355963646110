import {Action} from 'redux-actions'
import {call, takeLeading, put, takeLatest} from 'redux-saga/effects'
import {OtherAPI} from '@/service'
import {UIActions} from '@/actions'
import {history} from '@/routes'
import {ActionCallback} from '@/utils'

import {OtherConstants} from '../constants'

function* payOrder({payload = {}}: Action<any>) {
  const {id, values, onFail} = payload
  try {
    const {cardNumber, cardLimitYear, cardLimitMonth, cardCode} = values
    const res = yield call(OtherAPI.getCreditCardToken, {
      cardNumber,
      cardExpire: `${cardLimitMonth}/${cardLimitYear.substr(2)}`,
      securityCode: cardCode,
    })
    yield call(OtherAPI.payOrder, {id, cardToken: res.token})
    yield put(UIActions.showMessage({type: 'success', message: '支払い成功する'}))
    history.push('/account/order/pay/success')
  } catch (err) {
    onFail?.(err.message)
  }
}

function* zipCloud({payload = {}}: Action<any>) {
  const {zipCode, onSuccess, onFail} = payload
  try {
    const res = yield call(OtherAPI.zipCloud, {zipCode})
    if (res?.status === 200) {
      onSuccess?.(res.results)
    } else {
      onFail?.(res.message)
    }
  } catch (err) {
    onFail?.(err.message)
  }
}

function* payOrderConfirm({payload}: Action<{orderId: string; version: string} & ActionCallback>) {
  try {
    const {onSuccess, orderId, version} = payload
    yield call(OtherAPI.payOrderConfirm, {orderId, version})
    yield put(UIActions.showMessage({type: 'success', message: '入金成功を承認しました'}))
    onSuccess?.()
  } catch (err) {}
}

export const otherSaga = [
  takeLeading(OtherConstants.PAY_ORDER, payOrder),
  takeLatest(OtherConstants.ZIP_CLOUD, zipCloud),
  takeLatest(OtherConstants.PAY_ORDER_CONFIRM, payOrderConfirm),
]
