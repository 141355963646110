// copy and format from message.proto

// アカウントステータス
export enum AccountStatusEnum {
  Unknown = 0,
  // 仮登録
  TemporaryRegistered = 1,
  // 本登録申込（使わない）
  Application = 2,
  // 不備（使わない）
  Incomplete = 3,
  // 再申請中（使わない）
  ReApplication = 4,
  // 開設完了（使わない）
  Created = 5,
  // 本登録済み
  PermanentRegistered = 6,
  // 申込キャンセル（使わない）
  ApplicationCancel = 7,
  // 開設拒否
  Rejected = 8,
  // 口座解約
  Canceled = 9,
  // 利用禁止（管理者経由）
  Disabled = 10,
}

// 組織タイプ
export enum LegalEntityTypeEnum {
  Unknown = 0,
  // システム
  System = 1,
  // 古物商
  Customer = 2,
}

// 銀行口座種別
export enum BankAccountTypeEnum {
  Unknown = 0,
  // 普通預金
  Ordinary = 1,
  // 当座預金
  Checking = 2,
  // 貯蓄預金
  Saving = 3,
}

// 買取現物確認回答まで時間タイプ
export enum PurchaseConfirmTimeTypeEnum {
  Unknown = 0,
  // 12時間以内
  Fast = 1,
  // 24時間以内
  Medium = 2,
  // 48時間以内
  Slow = 3,
}

// 身分証明書種類
export enum IDCardTypeEnum {
  Unknown = 0,
  // 運転免許証
  DriversLicence = 1,
  // パスポート
  Passport = 2,
  // 個人番号カード
  IndividualNumberCard = 3,
  // 住民基本台帳カード
  JukiCard = 4,
  // 在留カード
  ResidenceCard = 5,
  // 特別永住者証明書
  SpecialPermanentResidentCertificate = 6,
  // その他
  Other = 7,
}

// 本アカウント申請ステータス
export enum AccountCreationStatusEnum {
  Unknown = 0,
  // 審査待ち
  AwaitingCheck = 1,
  // 発行済み
  Confirmed = 2,
  // 却下
  Rejected = 3,
  // 取り消し
  Canceled = 4,
}

// 査定依頼期限タイプ
export enum PeriodTypeEnum {
  Unknown = 0,
  // 5分以内
  Period5Min = 1,
  // 1日後
  Period1Day = 2,
  // 2日後
  Period2Day = 3,
}

// 査定依頼状態
export enum AssessmentRequestStatusEnum {
  Unknown = 0,
  // 査定中
  Ongoing = 1,
  // 取引決定
  Dealt = 2,
  // 取引辞退
  Declined = 3,
  // 取引完了
  TransactionDone = 4,
  // 取引不成立（査定入力がない）
  AssessmentFailedNoReply = 5,
  // 取引不成立（買取者選択期限切れ）
  AssessmentFailedDecisionExpired = 6,
  // 取引不成立（現物査定返品のため）
  TransactionFailedRecalled = 7,
}

// 買取側査定入力ステータス
export enum AssessmentReplyStatusEnum {
  Unknown = 0,
  // 初回査定入力済み
  FirstTimeReplied = 1,
  // [長期のみ]後で査定
  ReplyPostponed = 2,
  // ワンチャン査定済み
  OneChanceReplied = 3,
  // 落札成功（完了状態）
  Dealt = 4,
  // 落札失敗（他の人が選ばれたため）（完了状態）
  BidLost = 5,
  // 出品者辞退
  Declined = 6,
}

// カテゴリプロパティタイプ
export enum CategoryPropertyTypeEnum {
  Unknown = 0,
  // select
  Select = 1,
  // text
  Text = 2,
}

// メール確認種別
export enum MailVerifyTypeEnum {
  UNKNOWN = 0,
  CHANGE_EMAIL = 1,
}

// 認証タイプ
export enum GrantTypeEnum {
  UNKNOWN = 0,
  PASSWORD = 1,
  REFRESH_TOKEN = 2,
}

// 言語種別
export enum LangTypeEnum {
  All = 0,
  Jp = 1,
  En = 2,
}

// プランのグレード
export enum PlanGradeEnum {
  Unknown = 0,
  // お試しプラン10
  Basic = 1,
  // プラン30
  Standard = 2,
  // プラン無制限
  Unlimited = 3,
}

// プラン単位
export enum PlanUnitEnum {
  Unknown = 0,
  // 日
  Day = 1,
  // 月
  Month = 2,
  // 年
  Year = 3,
}

// ファイルアップロードタイプ
export enum UploadTypeEnum {
  Unknown = 0,
  Private = 1,
  Public = 2,
}

// ファイルアクセス範囲
export enum FileVisibilityEnum {
  Unknown = 0,
  // 公開
  Public = 1,
  // 当該ユーザーと社内運営だけ
  User = 2,
  // 社内運営だけ
  System = 3,
}

// シーケンスタイプ
export enum SequenceTypeEnum {
  UNKNOWN = 0,
  // アカウントDisplayID
  AccountDisplayID = 1,
}

// 設定コード
export enum ApplicationPropertyCodeEnum {
  Unknown = 0,
  // カテゴリーversion
  CategoryVersion = 1,
  // 言語セッティングversion
  LanguagesVersion = 2,
  // 消費税レート
  TaxRate = 3,
  // 配送業者情報version
  DeliveryCompanyVersion = 4,
  // 取引相手決定期限(hour)
  BuyerDecideTimeLimit = 5,
  // カスタマサポートメールアドレス
  CustomerSupportMailAddress = 6,
  // 現物査定取引交渉期限(hour)
  SellerBargainDecideTimeLimit = 7,
  // 契約プラン支払い期限(hour)
  ContractPlanOrderTimeLimit = 8,
  // 取引支払い期限(hour)
  TransactionOrderTimeLimit = 9,
  // 取引カウントダウン待ち期限(hour)
  TransactionAwaitingCountdownTimeLimit = 10,
  // カウトダウン発動可能時間(プラットフォームが発送後、X時間)(hour)
  CountdownTimeAfterPlatformDeliveryToBuyer = 11,
}

// 設定コード
export enum MasterCodeEnum {
  Unknown = 0,
  // お問合せ種類
  ContactType = 1,
  // 取引決済方法
  TransactionPaymentMethod = 2,
  // カウントダウンプラン
  CountdownPlan = 3,
}

// 設定タイプ
export enum ApplicationPropertyTypeEnum {
  Unknown = 0,
  Int = 1,
  Float = 2,
  String = 3,
  Boolean = 4,
}

// デバイスタイプ
export enum DeviceTypeEnum {
  UNKNOWN = 0,
  IOS = 1,
  ANDROID = 2,
  BROWSER = 3,
}

// 通知カテゴリ
export enum NotificationCategoryEnum {
  Unknown = 0,
  // 一般、アプリ入るタイミングでリセットする
  General = 1,
}

// 掲載状態
export enum PostingStatusEnum {
  Unknown = 0,
  // 掲載前
  BeforePosting = 1,
  // 掲載中
  Posting = 2,
  // 掲載終了
  Termination = 3,
}

// 重要度
export enum ImportanceEnum {
  Unknown = 0,
  // 通常
  Normal = 1,
  // 重要
  Important = 2,
}

// お知らせ種別
export enum InformationTypeEnum {
  Unknown = 0,
  // 全顧客
  AllUsers = 1,
  // 顧客指定
  UserSpecification = 2,
}

// 注文タイプ
export enum OrderTypeEnum {
  Unknown = 0,
  // プラン利用料
  ContractPlan = 1,
  // 入札
  Bid = 2,
  // 担保金
  Collateral = 3,
}

// 注文ステータス
export enum OrderStatusEnum {
  Unknown = 0,
  // 新規作成
  NewOrder = 1,
  // 決済申請完了
  PaymentApplied = 2,
  // 決済完了
  PaymentDone = 3,
  // キャンセル
  Canceled = 4,
  // 決済失敗
  PaymentFailed = 5,
  // 返金申請完了
  RefundApplied = 6,
  // 部分返金済み
  PartlyRefunded = 7,
  // 返金済み
  Refunded = 8,
  // 失敗
  Failed = 9,
}

// 決済ステータス
export enum PaymentStatusEnum {
  Unknown = 0,
  // 決済待ち
  AwaitingPayment = 1,
  // キャンセル
  Canceled = 2,
  // 期限切れ
  Expired = 3,
  // 決済完了
  Done = 4,
  // 失敗
  Failed = 5,
}

// 返金ステータス
export enum RefundStatusEnum {
  Unknown = 0,
  // 返金待ち
  AwaitingRefund = 1,
  // キャンセル
  Canceled = 2,
  // 期限切れ
  Expired = 3,
  // 返金完了
  Done = 4,
}

// 銀行タイプ
export enum BankTypeEnum {
  Unknown = 0,
  // 銀行
  Bank = 1,
  // 銀行支店
  BankBranch = 2,
}

// 取引ステータス
export enum TransactionStatusEnum {
  Unknown = 0,
  // 決済処理中（買取）
  AwaitingTransaction = 1,
  // 入金確認中（運営）
  DepositApplied = 2,
  // 入金確認完了
  Deposited = 3,
  // 配送手配済み（出品者から買取者に郵送）
  SellerDeliveryToBuyerArranged = 4,
  // 配送手配済み（プラットフォームから買取者に郵送）
  PlatformDeliveryToBuyerArranged = 5,
  // 取引成約
  TransactionConfirmed = 6,
  // 出品者へ出金済み
  withdrawn = 7,

  // 取引完了
  TransactionCompleted = 8,
  // 配送手配済み（出品者からプラットフォームに郵送）
  SellerDeliveryToPlatformArranged = 9,
  // 現物査定
  AwaitingAssessment = 10,
  // 売却確認
  Bargain = 11,
  // 買取者より取り消し
  TransactionCanceled = 12,
  // 出品者より辞退
  TransactionReject = 13,
  // 配送手配済み（買取者からプラットフォームに郵送）
  BuyerDeliveryToPlatformArranged = 14,
  // 配送手配済み（プラットフォームから出品者に郵送）
  PlatformDeliveryToSellerArranged = 15,
  // 返品完了
  ItemReturned = 16,
  // 保証金返金
  DepositRefunded = 17,
  // 取引キャンセル
  TransactionFailed = 18,
}

// 取引フロータイプ
export enum TransactionFlowTypeEnum {
  Unknown = 0,
  // 普通取引
  NormalTransaction = 1,
  // 要現物確認
  ActualConfirmTransaction = 2,
}

// 取引一覧 - 出品/査定タイプ
export enum TransactionHistoryViewTypeEnum {
  All = 0,
  // 出品（出品側）
  Seller = 1,
  // 査定（買取側）
  Buyer = 2,
}

// 取引一覧 - ステータス
export enum TransactionHistoryViewStatusEnum {
  All = 0,
  // 出品中
  InAssess = 1,
  // 取引中
  InTransaction = 2,
  // 取引完了
  TransactionDone = 3,
}

// 配送タイプ
export enum DeliveryTypeEnum {
  Unknown = 0,
  // 正常取引郵送-出品者から買取者に郵送
  SellerToBuyer = 1,
  // 現物取引-出品者からプラットフォームに郵送
  SellerToPlatform = 2,
  // 現物取引-プラットフォームから買取者に郵送
  PlatformToBuyer = 3,
  // 現物取引（返送）-買取者からプラットフォームに郵送
  BuyerToPlatform = 4,
  // 現物取引（返送）-プラットフォームから出品者に郵送
  PlatformToSeller = 5,
}

// 配送ステータス
export enum DeliveryStatusEnum {
  Unknown = 0,
  // 未発送
  Unsent = 1,
  // 発送済み
  Sent = 2,
  // 受取済み；
  Received = 3,
}

// 決済方法
export enum PaymentMethodEnum {
  Unknown = 0,
  // 銀行
  Bank = 1,
  // Paypal
  Paypal = 2,
  // CreditCard
  CreditCard = 3,
}

// プランタイプ
export enum PlanTypeEnum {
  UNKNOWN = 0,
  INDIVIDUAL = 1,
  CORPORATION = 2,
}
