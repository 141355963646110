/* eslint-disable react/jsx-fragments, @typescript-eslint/no-unused-vars */
import React, {FC, memo, useState, useEffect, CSSProperties} from 'react'
import {Form, Input, Radio, Select, Checkbox, Row, Col, DatePicker as AntdDatePicker, Spin, Card} from 'antd'
import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons'
import {FormItemProps, FormInstance} from 'antd/lib/form'
import classnames from 'classnames'
import {useDispatch, useSelector} from 'react-redux'
import {useMount} from 'ahooks'
import _ from 'lodash'
import dayjs from 'dayjs'

import {
  UploadTypeEnum,
  PurchaseConfirmTimeTypeEnum,
  BankAccountTypeEnum,
  IDCardTypeEnum,
  PlanGradeEnum,
} from '@/constants/enums'
import Upload from '@/components/Upload'
import {ContentConstants, ContractConstants} from '@/store/constants'
import {FieldRules, Regions, EnumsText, Enums} from '@/constants'
import {ContractActions, ContentActions, OtherActions} from '@/actions'
import {isMobile} from '@/utils'

import {handleTransform, handleBlur, handleFocus, buildPlaceHolder} from './FormUtil'
import styles from './item.module.less'

type ItemProps = FormItemProps & {
  readOnly?: boolean
  noPlaceHolder?: boolean
  childProps?: any
}
type ItemPropsWithForm = ItemProps & {form: FormInstance}

interface PlaceHolderProps {
  inline?: boolean
  block?: boolean
}
interface SubTitleProps {
  title: React.ReactNode
  withPrefix?: boolean
  style?: CSSProperties
}

const PlaceHolder: FC<PlaceHolderProps> = ({block = false}) => {
  return <Form.Item style={{height: 0, margin: 0, width: block ? '100%' : ''}} />
}

const SubTitle: FC<SubTitleProps> = ({style, title, withPrefix = true}) => (
  <Form.Item style={{width: '100%', marginBottom: 0}}>
    <h3
      className={classnames(styles.title, withPrefix && styles.withPrefix, isMobile && styles.isMobile)}
      style={style}>
      {title}
    </h3>
  </Form.Item>
)

const FamilyName: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="familyName" rules={[{required: true}]} label="姓" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const GivenName: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="givenName" required rules={[{required: true}]} label="名" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const FamilyNamePhonetic: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="familyNamePhonetic" rules={[{required: true}]} label="姓（カナ）" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const GivenNamePhonetic: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="givenNamePhonetic" rules={[{required: true}]} label="名（カナ）" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const Email: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="email" rules={[{required: true}, {type: 'email'}]} label="メールアドレス" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const LoginEmail: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="email" rules={[{required: true}, {type: 'email'}]} label="メールアドレス" {...itemProps}>
    <Input placeholder="登録したメールアドレスを入力" readOnly={readOnly} />
  </Form.Item>
)

const IdentityVerificationPhoto: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item
    label="本人確認画像"
    name="identityVerificationPhoto"
    valuePropName="values"
    rules={[{required: true}]}
    {...itemProps}>
    <Upload uploadType={UploadTypeEnum.Private} label="本人画像" disabled={readOnly} />
  </Form.Item>
)

const IsIndividual: FC<ItemPropsWithForm> = ({readOnly, noPlaceHolder, form, ...itemProps}) => (
  <Form.Item name="isIndividual" initialValue={false} {...itemProps}>
    <Radio.Group
      className={classnames(readOnly && styles.readOnly)}
      onChange={() => {
        form?.setFieldsValue({planId: undefined})
      }}>
      <Radio value={false}>法人</Radio>
      <Radio value>個人事業主</Radio>
    </Radio.Group>
  </Form.Item>
)

const LegalEntityName: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="legalEntityName" label="会社名" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const LegalEntityNamePhonetic: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="legalEntityNamePhonetic" label="会社名（カナ）" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const DirectorFamilyName: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="directorFamilyName" rules={[{required: true}]} label="代表者姓" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const DirectorGivenName: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="directorGivenName" rules={[{required: true}]} label="代表者名" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const DirectorFamilyNamePhonetic: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="directorFamilyNamePhonetic" rules={[{required: true}]} label="代表者姓（カナ）" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const DirectorGivenNamePhonetic: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="directorGivenNamePhonetic" rules={[{required: true}]} label="代表者名（カナ）" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const Tel: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item
    name="tel"
    rules={[{required: true}, {pattern: FieldRules.tel, message: '電話番号が不正です、半角数字を入力してください'}]}
    label="電話番号"
    {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const AntiqueLicenseNumber: FC<ItemPropsWithForm> = ({readOnly, noPlaceHolder, form, ...itemProps}) => (
  <Form.Item
    name="antiqueLicenseNumber"
    rules={[
      {required: true},
      {
        transform: handleTransform('antiqueLicenseNumber'),
        pattern: FieldRules.antiqueLicenseNumber,
        message: '古物番号文字列不正です、12桁半角数字を入力してください',
      },
    ]}
    label="古物番号"
    {...itemProps}>
    <Input
      placeholder={buildPlaceHolder({noPlaceHolder})}
      readOnly={readOnly}
      onBlur={handleBlur('antiqueLicenseNumber', form)}
      onFocus={handleFocus('antiqueLicenseNumber', form)}
    />
  </Form.Item>
)

const AntiqueLicensePhoto: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item
    label="古物商許可証"
    name="antiqueLicensePhoto"
    valuePropName="values"
    rules={[{required: true}]}
    {...itemProps}>
    <Upload uploadType={UploadTypeEnum.Private} label="古物商許可証" disabled={readOnly} />
  </Form.Item>
)

const SpecialInternationalRegistrationNumber: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item
    name="specialInternationalRegistrationNumber"
    extra="·登録事業者でない場合は象牙などの取引はできません"
    label="特別国際種事業者登録番号"
    {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const SpecialInternationalRegistrationNumberPhoto: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item
    label="特別国際種事業者"
    name="specialInternationalRegistrationNumberPhoto"
    valuePropName="values"
    dependencies={['specialInternationalRegistrationNumber']}
    rules={[(form) => ({required: form.getFieldValue('specialInternationalRegistrationNumber')})]}
    {...itemProps}>
    <Upload uploadType={UploadTypeEnum.Private} label="特別国際種事業者" disabled={readOnly} />
  </Form.Item>
)

const Zipcode: FC<ItemPropsWithForm> = ({readOnly, form, noPlaceHolder, ...itemProps}) => {
  const dispatch = useDispatch()
  return (
    <Form.Item
      name="zipcode"
      rules={[
        {required: true},
        {
          pattern: FieldRules.zipcode,
          message: '郵便番号が不正です、ハイフンなしの７桁半角数字を入力してください',
        },
      ]}
      normalize={(value, prevValue, prevValues) => {
        if (value?.length === 7) {
          dispatch(
            OtherActions.zipCloud({
              zipCode: value,
              onSuccess: (results) => {
                if (results?.length) {
                  form.setFieldsValue({
                    prefecture: results[0].address1,
                    city: results[0].address2,
                    street: results[0].address3,
                  })
                }
              },
              // onFail: (errMessage) => {},
            })
          )
        }
        return value
      }}
      label="郵便番号"
      {...itemProps}>
      <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} maxLength={7} />
    </Form.Item>
  )
}
const Prefecture: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="prefecture" rules={[{required: true}]} label="都道府県" {...itemProps}>
    <Select
      placeholder={buildPlaceHolder({type: 'select', noPlaceHolder})}
      className={classnames(readOnly && styles.readOnly)}>
      {Regions.map((prefecture) => (
        <Select.Option value={prefecture} key={prefecture}>
          {prefecture}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
)

const City: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="city" rules={[{required: true}]} label="市区町村" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const Street: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="street" rules={[{required: true}]} label="番地" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const Building: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="building" label="建物名" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const BankCode: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => {
  const dispatch = useDispatch()
  const {bankList, loading} = useSelector(
    (state) => ({
      bankList: _.get(state, ['content', 'bankList'], []),
      loading: _.get(state, ['loading', ContentConstants.LIST_BANK]),
    }),
    _.isEqual
  )
  useMount(() => {
    if (bankList.length === 0) dispatch(ContentActions.listBank())
  })
  return (
    <Form.Item name="bankCode" rules={[{required: true}]} label="金融機関" {...itemProps}>
      <Select
        notFoundContent={loading ? <Spin size="small" /> : null}
        showSearch
        optionFilterProp="children"
        placeholder={buildPlaceHolder({type: 'select', noPlaceHolder})}
        optionLabelProp="label"
        filterOption={(input, option) => option?.children?.indexOf(input) >= 0}
        className={classnames(readOnly && styles.readOnly)}>
        {bankList.map((bank: {bankCode: string; name: string}) => (
          <Select.Option value={bank.bankCode} key={bank.bankCode} label={bank.name}>
            {`${bank.name} ${bank.bankCode}`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
const BankBranchCode: FC<ItemPropsWithForm> = ({readOnly, noPlaceHolder, form, ...itemProps}) => {
  const [bankCode, setBankCode] = useState('')
  const dispatch = useDispatch()
  const {bankBranchList, loading} = useSelector(
    (state) => ({
      bankBranchList: _.get(state, ['content', 'bankBranchList', bankCode], []),
      loading: _.get(state, ['loading', ContentConstants.LIST_BANK_BRANCH]),
    }),
    _.isEqual
  )

  const shouldUpdate = (prevValues: any, curValues: any) => {
    if (prevValues.bankCode !== curValues.bankCode) {
      setBankCode(curValues.bankCode)
      form.setFieldsValue({bankBranchCode: undefined})
    }
    return false
  }

  useEffect(() => {
    if (bankCode && bankBranchList.length === 0) {
      dispatch(ContentActions.listBankBranch({bankCode}))
    }
  }, [bankCode])

  return (
    <Form.Item
      name="bankBranchCode"
      rules={[{required: true}]}
      label="支店名"
      extra="·支店番号を入力すると、自動で支店名が入力されます"
      shouldUpdate={shouldUpdate}
      {...itemProps}>
      <Select
        notFoundContent={loading ? <Spin size="small" /> : null}
        showSearch
        showArrow={false}
        optionFilterProp="children"
        placeholder={buildPlaceHolder({type: 'select', noPlaceHolder})}
        optionLabelProp="label"
        filterOption={(input, option) => option?.children?.indexOf(input) >= 0}
        className={classnames(readOnly && styles.readOnly)}>
        {bankBranchList.map((bank: {bankBranchCode: string; name: string}) => (
          <Select.Option value={bank.bankBranchCode} key={bank.bankBranchCode} label={bank.name}>
            {`${bank.name} ${bank.bankBranchCode}`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

const BankAccountType: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="bankAccountType" rules={[{required: true}]} label="口座種別" {...itemProps}>
    <Select
      placeholder={buildPlaceHolder({type: 'select', noPlaceHolder})}
      className={classnames(readOnly && styles.readOnly)}>
      <Select.Option value={BankAccountTypeEnum.Ordinary}>
        {EnumsText.BankAccountTypeEnum[BankAccountTypeEnum.Ordinary]}
      </Select.Option>
      <Select.Option value={BankAccountTypeEnum.Checking}>
        {EnumsText.BankAccountTypeEnum[BankAccountTypeEnum.Checking]}
      </Select.Option>
      <Select.Option value={BankAccountTypeEnum.Saving}>
        {EnumsText.BankAccountTypeEnum[BankAccountTypeEnum.Saving]}
      </Select.Option>
    </Select>
  </Form.Item>
)

const BankAccountNo: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="bankAccountNo" label="口座番号" rules={[{required: true}]} {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const BearDeliveryFeeOnBid: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="bearDeliveryFeeOnBid" noStyle initialValue {...itemProps}>
    <Radio.Group className={classnames(readOnly && styles.readOnly)}>
      <Radio value>する</Radio>
      <Radio value={false}>しない</Radio>
    </Radio.Group>
  </Form.Item>
)

const ActualConfirmWithin: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="actualConfirmWithin" noStyle {...itemProps}>
    <Radio.Group className={classnames(readOnly && styles.readOnly)}>
      <Radio value={PurchaseConfirmTimeTypeEnum.Fast}>12時間以内</Radio>
      <Radio value={PurchaseConfirmTimeTypeEnum.Medium}>24時間以内</Radio>
      <Radio value={PurchaseConfirmTimeTypeEnum.Slow}>48時間以内</Radio>
    </Radio.Group>
  </Form.Item>
)

const HandleableDeliveryCompanies: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => {
  const dispatch = useDispatch()
  const {deliveryCompaniesList} = useSelector(
    (state) => ({
      deliveryCompaniesList: _.get(state, ['content', 'deliveryCompaniesList'], []),
      loading: _.get(state, ['loading', ContentConstants.LIST_DELIVERY_COMPANIES]),
    }),
    _.isEqual
  )

  useMount(() => {
    if (deliveryCompaniesList.length === 0) {
      dispatch(ContentActions.listDeliveryCompanies())
    }
  })

  return (
    <Form.Item
      name="handleableDeliveryCompanies"
      noStyle
      getValueProps={(props) => ({value: props?.map((item: {id: string}) => item.id)})}
      getValueFromEvent={(props) => props?.map((id: string) => ({id}))}
      {...itemProps}>
      <Checkbox.Group className={classnames(readOnly && styles.readOnly)}>
        <Row>
          {deliveryCompaniesList.length
            ? deliveryCompaniesList.map((item: any, index: number) => (
                <Col span={index === deliveryCompaniesList.length - 1 && index % 2 === 0 ? 24 : 12} key={item.key}>
                  <Checkbox value={item.id}>{item.name}</Checkbox>
                </Col>
              ))
            : '指定なし'}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  )
}

const DaysToPayment: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item
    name="daysToPayment"
    {...itemProps}
    rules={[
      {required: true, message: '入金までの日数を入力してください'},
      {
        pattern: FieldRules.number,
        message: '半角数字で入力して下さい',
      },
    ]}
    label=""
    getValueFromEvent={(e) => e.target.value?.replace(/[^\d]/g, '')}>
    <Input
      placeholder={buildPlaceHolder({noPlaceHolder})}
      readOnly={readOnly}
      style={{borderBottomWidth: readOnly ? 0 : 1}}
    />
  </Form.Item>
)

const IsAuction: FC<ItemPropsWithForm> = ({readOnly, noPlaceHolder, form, ...itemProps}) => (
  <Form.Item name="isAuction" label="出品有無" initialValue {...itemProps}>
    <Radio.Group
      className={classnames(readOnly && styles.readOnly)}
      onChange={() => {
        form?.setFieldsValue({planId: undefined})
      }}>
      <Radio value>あり</Radio>
      <Radio value={false}>なし</Radio>
    </Radio.Group>
  </Form.Item>
)

const PlanId: FC<ItemPropsWithForm> = ({readOnly, form, noPlaceHolder, ...itemProps}) => {
  const dispatch = useDispatch()
  const [isAuction, setIsAuction] = useState(true)
  const [isIndividual, setIsIndividual] = useState(false)
  const planType = isIndividual ? Enums.PlanTypeEnum.INDIVIDUAL : Enums.PlanTypeEnum.CORPORATION
  const {plansList, loading} = useSelector(
    (state) => ({
      plansList: _.get(state, ['contract', 'plansList', planType], []),
      loading: _.get(state, ['loading', ContractConstants.GET_PLANS_LIST]),
    }),
    _.isEqual
  )

  useEffect(() => {
    if (plansList.length === 0) {
      dispatch(ContractActions.getPlansList({planType}))
    }
  }, [isIndividual])

  const shouldUpdate = (prevValues: any, curValues: any) => {
    if (prevValues.isAuction !== curValues.isAuction) {
      setIsAuction(curValues.isAuction)
    }
    if (prevValues.isIndividual !== curValues.isIndividual) {
      setIsIndividual(curValues.isIndividual)
    }
    if (prevValues.planId !== curValues.planId) return true
    return false
  }

  return (
    <Form.Item
      name="planId"
      rules={[{required: isAuction, message: '出品プランを入力してください'}]}
      label="出品プラン"
      shouldUpdate={shouldUpdate}
      className={classnames(styles.customSelect)}
      {...itemProps}>
      <Select
        notFoundContent={loading ? <Spin size="small" /> : null}
        disabled={!isAuction}
        placeholder={buildPlaceHolder({type: 'select', noPlaceHolder: noPlaceHolder || !isAuction})}
        className={classnames({[styles.readOnly]: readOnly || !isAuction}, styles.customSelect)}>
        {plansList.map((plan: any) => (
          <Select.Option value={plan.id} key={plan.id}>
            {plan.planGrade !== PlanGradeEnum.Unlimited
              ? `${plan.planName}(月に${plan.monthlyPutUpNumber}アイテム出品可能/月額${
                  plan.usageFee * 2
                }円)→キャンペーン中につき50%OFFの${plan.usageFee}円（税込${Math.floor(plan.usageFee * 1.1)}円）`
              : `${plan.planName}(無制限でアイテム出品可能/月額${plan.usageFee * 2}円)→キャンペーン中につき50%OFFの${
                  plan.usageFee
                }円（税込${Math.floor(plan.usageFee * 1.1)}円）`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

const SelectPlanId: FC<ItemPropsWithForm> = ({readOnly, form, noPlaceHolder, ...itemProps}) => {
  const dispatch = useDispatch()
  const [isAuction, setIsAuction] = useState(true)
  const [isIndividual, setIsIndividual] = useState(false)
  const {plansList, loading} = useSelector(
    (state) => ({
      plansList: _.get(state, ['contract', 'plansList', 0], []),
      loading: _.get(state, ['loading', ContractConstants.GET_SELECTABLE_PLANS_LIST]),
    }),
    _.isEqual
  )

  useEffect(() => {
    if (plansList.length === 0) {
      dispatch(ContractActions.getSelectablePlansList())
    }
  }, [isIndividual])

  const shouldUpdate = (prevValues: any, curValues: any) => {
    if (prevValues.isAuction !== curValues.isAuction) {
      setIsAuction(curValues.isAuction)
    }
    if (prevValues.isIndividual !== curValues.isIndividual) {
      setIsIndividual(curValues.isIndividual)
    }
    if (prevValues.planId !== curValues.planId) return true
    return false
  }

  return (
    <Form.Item
      name="planId"
      rules={[{required: isAuction, message: '出品プランを入力してください'}]}
      label="出品プラン"
      shouldUpdate={shouldUpdate}
      className={classnames(styles.customSelect)}
      {...itemProps}>
      <Select
        notFoundContent={loading ? <Spin size="small" /> : null}
        disabled={!isAuction}
        placeholder={buildPlaceHolder({type: 'select', noPlaceHolder: noPlaceHolder || !isAuction})}
        className={classnames({[styles.readOnly]: readOnly || !isAuction}, styles.customSelect)}>
        {plansList.map((plan: any) => (
          <Select.Option value={plan.id} key={plan.id}>
            {plan.planGrade !== PlanGradeEnum.Unlimited
              ? `${plan.planName}(月に${plan.monthlyPutUpNumber}アイテム出品可能/月額${
                  plan.usageFee * 2
                }円)→キャンペーン中につき50%OFFの${plan.usageFee}円（税込${Math.floor(plan.usageFee * 1.1)}円）`
              : `${plan.planName}(無制限でアイテム出品可能/月額${plan.usageFee * 2}円)→キャンペーン中につき50%OFFの${
                  plan.usageFee
                }円（税込${Math.floor(plan.usageFee * 1.1)}円）`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

const Token: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="token" rules={[{required: true}]} label="リセットコード" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const Password: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="password" rules={[{required: true}]} label="現在のパスワード" {...itemProps}>
    <Input.Password
      iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
      placeholder="パスワードを入力"
      maxLength={32}
      readOnly={readOnly}
    />
  </Form.Item>
)

const NewPassword: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item
    name="newPassword"
    getValueFromEvent={(e) => e.target.value.replace(/\s+/g, '')}
    rules={[
      {required: true},
      {
        pattern: FieldRules.password,
        message: (
          <>
            ·半角英数/8文字以上32文字以下
            <br />
            ·英大小文字、数字をそれぞれ１文字以上含む
          </>
        ),
      },
    ]}
    label="新しいパスワード"
    {...itemProps}>
    <Input.Password
      iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
      placeholder={buildPlaceHolder({noPlaceHolder})}
      maxLength={32}
      readOnly={readOnly}
    />
  </Form.Item>
)

const ConfirmNewPassword: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item
    name="confirm"
    getValueFromEvent={(e) => e.target.value.replace(/\s+/g, '')}
    dependencies={['newPassword']}
    rules={[
      {required: true, message: '新しいパスワードを入力してください'},
      (form) => ({
        validator(rule, value) {
          if (!value || form.getFieldValue('newPassword') === value) {
            return Promise.resolve()
          }
          return Promise.reject(new Error('新しいパスワードと一致しません'))
        },
      }),
    ]}
    label={
      <>
        新しいパスワード
        <br />
        （確認用）
      </>
    }
    {...itemProps}>
    <Input.Password
      iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
      placeholder={buildPlaceHolder({noPlaceHolder})}
      maxLength={32}
      readOnly={readOnly}
    />
  </Form.Item>
)

const IdCardPhoto: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item
    label="写真つき身分証明書"
    className={styles.uploads}
    required
    extra="·古物法に基づき、写真付き身分証書の画像は、正面、裏面、側面(厚みがわかるように）の3つの画像が必要です"
    {...itemProps}>
    <Form.Item
      noStyle
      dependencies={['idCardBack', 'idCardSide']}
      name="idCardFront"
      valuePropName="values"
      rules={[
        (form) => ({
          required: true,
          message: '写真つき身分証明書の画像をアップロードしてください',
          validator(rule) {
            const {idCardFront, idCardBack, idCardSide} = form.getFieldsValue([
              'idCardFront',
              'idCardBack',
              'idCardSide',
            ])
            if (!idCardFront || !idCardBack || !idCardSide) {
              return Promise.reject(new Error(rule.message as string))
            }
            return Promise.resolve()
          },
        }),
      ]}>
      <Upload uploadType={UploadTypeEnum.Private} label="正面" disabled={readOnly} />
    </Form.Item>
    <Form.Item noStyle name="idCardBack" valuePropName="values">
      <Upload uploadType={UploadTypeEnum.Private} label="背面" disabled={readOnly} />
    </Form.Item>
    <Form.Item noStyle name="idCardSide" valuePropName="values">
      <Upload uploadType={UploadTypeEnum.Private} label="側面" disabled={readOnly} />
    </Form.Item>
    <Form.Item noStyle name="cardType" initialValue={IDCardTypeEnum.Other}>
      <React.Fragment />
    </Form.Item>
  </Form.Item>
)

const DatePicker: FC<ItemProps> = ({readOnly, noPlaceHolder, childProps, ...itemProps}) => (
  <Form.Item
    getValueProps={(props) => ({value: Number(props) && dayjs.unix(props)})}
    getValueFromEvent={(props) => props && props?.unix()}
    {...itemProps}>
    <AntdDatePicker format="YYYY/MM/DD" className={classnames(readOnly && styles.readOnly)} {...childProps} />
  </Form.Item>
)

const CardName: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="cardName" rules={[{required: true}]} label="カード名義" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const CardNumber: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item
    name="cardNumber"
    rules={[{required: true}]}
    label="カード番号"
    {...itemProps}
    getValueFromEvent={(e) => e.target.value.replace(/[^0-9.]+/g, '')}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const CardCode: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item
    name="cardCode"
    rules={[{required: true}, {pattern: FieldRules.cardCode, message: '３桁から４桁までの数字を入力してください'}]}
    label="セキュリティコード"
    extra="＊クレジットカードの裏面に記載されている3桁または、表面に記載されている4桁の番号です"
    {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const CardLimit: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item label="カード有効期限" {...itemProps} className={styles.cardLimit}>
    <Form.Item
      noStyle
      getValueProps={(props) => ({value: Number(props) && dayjs().set('month', Number(props) - 1)})}
      getValueFromEvent={(props) => props && String(dayjs(props).get('month') + 1).padStart(2, '0')}
      name="cardLimitMonth"
      dependencies={['cardLimitYear']}
      rules={[
        (form) => ({
          required: true,
          message: '日付を選択してください',
          validator(rule) {
            const {cardLimitMonth, cardLimitYear} = form.getFieldsValue(['cardLimitMonth', 'cardLimitYear'])
            if (!cardLimitMonth || !cardLimitYear) {
              return Promise.reject(new Error(rule.message as string))
            }
            return Promise.resolve()
          },
        }),
      ]}>
      <AntdDatePicker
        dropdownClassName={styles.noPickerHeader}
        format="MM月"
        className={classnames(readOnly && styles.readOnly)}
        picker="month"
        suffixIcon={null}
      />
    </Form.Item>
    <Form.Item
      noStyle
      name="cardLimitYear"
      getValueProps={(props) => ({value: Number(props) && dayjs().set('year', props)})}
      getValueFromEvent={(props) => props && String(dayjs(props).get('year'))}>
      <AntdDatePicker
        format="YYYY年"
        className={classnames(readOnly && styles.readOnly)}
        picker="year"
        disabledDate={(date: any) => dayjs(date).isBefore(dayjs(), 'year')}
        suffixIcon={null}
      />
    </Form.Item>
  </Form.Item>
)

const TermsOfPurchase: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item className={classnames(styles.termsOfPurchase, isMobile && styles.isMobile)} {...itemProps}>
    <Card className={styles.card} title="落札時の送料負担" bordered={false}>
      <FormItem.BearDeliveryFeeOnBid readOnly={readOnly} noPlaceHolder={noPlaceHolder} />
    </Card>
    <Card
      className={styles.card}
      title={
        <>
          現物確認時、商品が到着してから
          <br />
          の査定結果を通知するまでの時間
        </>
      }
      bordered={false}>
      <FormItem.ActualConfirmWithin noPlaceHolder={noPlaceHolder} readOnly={readOnly} />
    </Card>
    <Card className={styles.card} title="取扱可能な配送業者" bordered={false}>
      <FormItem.HandleableDeliveryCompanies noPlaceHolder={noPlaceHolder} readOnly={readOnly} />
    </Card>
    <Card className={styles.card} title="入金までの日数" bordered={false}>
      <FormItem.DaysToPayment noPlaceHolder={noPlaceHolder} readOnly={readOnly} />
    </Card>
  </Form.Item>
)

const TrackNumber: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="trackNumber" label="追跡番号" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const DeliveryCompany: FC<ItemPropsWithForm> = ({readOnly, form, noPlaceHolder, ...itemProps}) => {
  const dispatch = useDispatch()
  const {deliveryCompaniesList, loading} = useSelector(
    (state) => ({
      deliveryCompaniesList: _.get(state, ['content', 'deliveryCompaniesList'], []),
      loading: _.get(state, ['loading', ContentConstants.LIST_DELIVERY_COMPANIES]),
    }),
    _.isEqual
  )

  useMount(() => {
    if (deliveryCompaniesList.length === 0) {
      dispatch(ContentActions.listDeliveryCompanies())
    }
  })

  return (
    <Form.Item name="deliveryCompanyId" label="配送会社" className={classnames(styles.customSelect)} {...itemProps}>
      <Select
        notFoundContent={loading ? <Spin size="small" /> : null}
        placeholder={buildPlaceHolder({type: 'select', noPlaceHolder})}
        className={classnames(styles.customSelect)}>
        {deliveryCompaniesList.map((company: any) => (
          <Select.Option value={company.id} key={company.id}>
            {company.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

const OtherDeliveryCompany: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="deliveryCompanyOther" label="その他配送会社" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

const DisplayId: FC<ItemProps> = ({readOnly, noPlaceHolder, ...itemProps}) => (
  <Form.Item name="displayId" rules={[{required: true}]} label="アプリ表示番号" {...itemProps}>
    <Input placeholder={buildPlaceHolder({noPlaceHolder})} readOnly={readOnly} />
  </Form.Item>
)

// @ts-ignore
export const FormItem: {
  PlaceHolder: FC<PlaceHolderProps>
  SubTitle: FC<SubTitleProps>
  FamilyName: FC<ItemProps>
  GivenName: FC<ItemProps>
  FamilyNamePhonetic: FC<ItemProps>
  GivenNamePhonetic: FC<ItemProps>
  Email: FC<ItemProps>
  LoginEmail: FC<ItemProps>
  IdentityVerificationPhoto: FC<ItemProps>
  IdCardPhoto: FC<ItemProps>
  LegalEntityName: FC<ItemProps>
  LegalEntityNamePhonetic: FC<ItemProps>
  IsIndividual: FC<ItemPropsWithForm>
  DirectorFamilyName: FC<ItemProps>
  DirectorGivenName: FC<ItemProps>
  DirectorFamilyNamePhonetic: FC<ItemProps>
  DirectorGivenNamePhonetic: FC<ItemProps>
  Tel: FC<ItemProps>
  AntiqueLicenseNumber: FC<ItemPropsWithForm>
  SpecialInternationalRegistrationNumber: FC<ItemProps>
  AntiqueLicensePhoto: FC<ItemProps>
  SpecialInternationalRegistrationNumberPhoto: FC<ItemProps>
  Zipcode: FC<ItemPropsWithForm>
  Prefecture: FC<ItemProps>
  City: FC<ItemProps>
  Street: FC<ItemProps>
  Building: FC<ItemProps>
  BankCode: FC<ItemProps>
  BankBranchCode: FC<ItemPropsWithForm>
  BankAccountType: FC<ItemProps>
  BankAccountNo: FC<ItemProps>
  BearDeliveryFeeOnBid: FC<ItemProps>
  ActualConfirmWithin: FC<ItemProps>
  HandleableDeliveryCompanies: FC<ItemProps>
  DaysToPayment: FC<ItemProps>
  IsAuction: FC<ItemPropsWithForm>
  PlanId: FC<ItemPropsWithForm>
  NewPassword: FC<ItemProps>
  ConfirmNewPassword: FC<ItemProps>
  Token: FC<ItemProps>
  Password: FC<ItemProps>
  DatePicker: FC<ItemProps>
  CardName: FC<ItemProps>
  CardNumber: FC<ItemProps>
  CardCode: FC<ItemProps>
  CardLimit: FC<ItemProps>
  TermsOfPurchase: FC<ItemProps>
  TrackNumber: FC<ItemProps>
  DeliveryCompany: FC<ItemPropsWithForm>
  OtherDeliveryCompany: FC<ItemProps>
  SelectPlanId: FC<ItemPropsWithForm>
  DisplayId: FC<ItemProps>
} = Form.Item

FormItem.SubTitle = SubTitle
FormItem.PlaceHolder = PlaceHolder
FormItem.FamilyName = FamilyName
FormItem.GivenName = GivenName
FormItem.FamilyNamePhonetic = FamilyNamePhonetic
FormItem.GivenNamePhonetic = GivenNamePhonetic
FormItem.Email = Email
FormItem.LoginEmail = LoginEmail
FormItem.IdentityVerificationPhoto = IdentityVerificationPhoto
FormItem.IdCardPhoto = IdCardPhoto
FormItem.LegalEntityName = LegalEntityName
FormItem.LegalEntityNamePhonetic = LegalEntityNamePhonetic
FormItem.IsIndividual = IsIndividual
FormItem.DirectorFamilyName = DirectorFamilyName
FormItem.DirectorGivenName = DirectorGivenName
FormItem.DirectorFamilyNamePhonetic = DirectorFamilyNamePhonetic
FormItem.DirectorGivenNamePhonetic = DirectorGivenNamePhonetic
FormItem.Tel = Tel
FormItem.AntiqueLicenseNumber = AntiqueLicenseNumber
FormItem.AntiqueLicensePhoto = AntiqueLicensePhoto
FormItem.SpecialInternationalRegistrationNumber = SpecialInternationalRegistrationNumber
FormItem.SpecialInternationalRegistrationNumberPhoto = SpecialInternationalRegistrationNumberPhoto
FormItem.Zipcode = Zipcode
FormItem.Prefecture = Prefecture
FormItem.City = City
FormItem.Street = Street
FormItem.Building = Building
FormItem.BankCode = BankCode
FormItem.BankBranchCode = BankBranchCode
FormItem.BankAccountType = BankAccountType
FormItem.BankAccountNo = BankAccountNo
FormItem.BearDeliveryFeeOnBid = BearDeliveryFeeOnBid
FormItem.ActualConfirmWithin = ActualConfirmWithin
FormItem.HandleableDeliveryCompanies = HandleableDeliveryCompanies
FormItem.DaysToPayment = DaysToPayment
FormItem.IsAuction = IsAuction
FormItem.PlanId = memo(PlanId)
FormItem.NewPassword = NewPassword
FormItem.ConfirmNewPassword = ConfirmNewPassword
FormItem.Token = Token
FormItem.Password = Password
FormItem.DatePicker = DatePicker
FormItem.CardName = CardName
FormItem.CardNumber = CardNumber
FormItem.CardCode = CardCode
FormItem.CardLimit = CardLimit
FormItem.TermsOfPurchase = TermsOfPurchase
FormItem.TrackNumber = TrackNumber
FormItem.DeliveryCompany = DeliveryCompany
FormItem.OtherDeliveryCompany = OtherDeliveryCompany
FormItem.SelectPlanId = memo(SelectPlanId)
FormItem.DisplayId = DisplayId
