import {call, takeLeading, put, select} from 'redux-saga/effects'

import _ from 'lodash'

import {PaymentAPI} from '@/service'
import {PaymentActions, TableConfigActions} from '@/actions'

import {PaymentConstants} from '../constants'

function* listAwaitingRefundData() {
  try {
    const paging = yield select((state) =>
      _.get(state, ['tableConfig', PaymentConstants.LIST_AWAITING_REFUND_DATA, 'paging'], {})
    )
    const res = yield call(PaymentAPI.listAwaitingRefundData, {paging})
    yield put(PaymentActions.setListAwaitingRefundData({list: res?.items, urls: res?.urls}))
    yield put(
      TableConfigActions.setTableConfig({
        type: PaymentConstants.LIST_AWAITING_REFUND_DATA,
        config: {pagination: {total: res.paging?.totalNumber}},
      })
    )
  } catch (err) {}
}

function* listAwaitingConfirmPaymentData() {
  try {
    const paging = yield select((state) =>
      _.get(state, ['tableConfig', PaymentConstants.LIST_AWAITING_CONFIRM_PAYMENT_DATA, 'paging'], {})
    )
    const res = yield call(PaymentAPI.listAwaitingConfirmPaymentData, {paging})
    yield put(PaymentActions.setListAwaitingConfirmPaymentData({list: res?.items, urls: res?.urls}))
    yield put(
      TableConfigActions.setTableConfig({
        type: PaymentConstants.LIST_AWAITING_CONFIRM_PAYMENT_DATA,
        config: {pagination: {total: res.paging?.totalNumber}},
      })
    )
  } catch (err) {}
}

export const paymentSaga = [
  takeLeading(PaymentConstants.LIST_AWAITING_REFUND_DATA, listAwaitingRefundData),
  takeLeading(PaymentConstants.LIST_AWAITING_CONFIRM_PAYMENT_DATA, listAwaitingConfirmPaymentData),
]
