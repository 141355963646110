import {createAction} from 'redux-actions'

import {PaymentConstants} from '@/store/constants'

export const PaymentActions = {
  listAwaitingRefundData: createAction(PaymentConstants.LIST_AWAITING_REFUND_DATA),
  listAwaitingConfirmPaymentData: createAction(PaymentConstants.LIST_AWAITING_CONFIRM_PAYMENT_DATA),

  setListAwaitingRefundData: createAction(PaymentConstants.SET_LIST_AWAITING_REFUND_DATA),
  setListAwaitingConfirmPaymentData: createAction(PaymentConstants.SET_LIST_AWAITING_CONFIRM_PAYMENT_DATA),
}
