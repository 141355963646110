import React, {FC, memo, useEffect, useState} from 'react'
import {Statistic} from 'antd'
import {StatisticProps} from 'antd/lib/statistic/Statistic'
import dayjs from 'dayjs'

interface Props extends StatisticProps {
  finishRender?: React.ReactNode
}

// @ts-ignore
const Countdown: FC<Props> = ({finishRender, value, ...rest}: Props) => {
  const [isCountdownEnd, setIsCountdownEnd] = useState(false)
  const valuesUsed = Number(String(value).padEnd(13, '0')) || 0

  const onFinish = () => {
    setIsCountdownEnd(true)
  }
  useEffect(() => {
    if (dayjs().isBefore(dayjs(valuesUsed))) {
      setIsCountdownEnd(false)
    } else {
      setIsCountdownEnd(true)
    }
  }, [valuesUsed])

  return finishRender && isCountdownEnd ? (
    finishRender
  ) : (
    <Statistic.Countdown value={valuesUsed} onFinish={onFinish} {...rest} />
  )
}

export default memo(Countdown)
