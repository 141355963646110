import React, {memo} from 'react'
import {FormItem, Form} from '@/components/Form'
import {Row, Col} from 'antd'

import styles from '@/pages/admin/components/AccountForm/styles.module.less'
import {useCompareEffect} from '@/utils'

const AccountForm = ({account}: {account: any}) => {
  const [form] = Form.useForm()

  useCompareEffect(() => {
    if (account) {
      form.setFieldsValue(account)
      form.setFieldsValue({bankBranchCode: account.bankBranchCode})
    }
  }, [account])

  return (
    <Form form={form} layout="horizontal" colon={false} hideRequiredMark className={styles.form} scrollToFirstError>
      <FormItem.SubTitle title="申請者情報" />
      <RowWithCol2
        col1={<FormItem.FamilyName noPlaceHolder readOnly />}
        col2={<FormItem.GivenName noPlaceHolder readOnly />}
      />
      <RowWithCol2
        col1={<FormItem.FamilyNamePhonetic noPlaceHolder readOnly />}
        col2={<FormItem.GivenNamePhonetic noPlaceHolder readOnly />}
      />
      <FormItem.Email noPlaceHolder readOnly />
      <RowWithCol2
        col1={<FormItem.IdentityVerificationPhoto noPlaceHolder readOnly />}
        col2={<FormItem.IdCardPhoto noPlaceHolder readOnly />}
      />
      <FormItem.SubTitle title="会社情報" />
      <FormItem.IsIndividual noPlaceHolder readOnly form={form} />
      <RowWithCol2
        col1={<FormItem.LegalEntityName noPlaceHolder readOnly />}
        col2={<FormItem.LegalEntityNamePhonetic noPlaceHolder readOnly />}
      />
      <RowWithCol2
        col1={<FormItem.DirectorFamilyName noPlaceHolder readOnly />}
        col2={<FormItem.DirectorGivenName noPlaceHolder readOnly />}
      />
      <RowWithCol2
        col1={<FormItem.DirectorFamilyNamePhonetic noPlaceHolder readOnly />}
        col2={<FormItem.DirectorGivenNamePhonetic noPlaceHolder readOnly />}
      />
      <FormItem.Tel noPlaceHolder readOnly />
      <RowWithCol2
        col1={<FormItem.AntiqueLicenseNumber noPlaceHolder readOnly form={form} />}
        col2={<FormItem.SpecialInternationalRegistrationNumber noPlaceHolder readOnly />}
      />
      <RowWithCol2
        col1={<FormItem.AntiqueLicensePhoto noPlaceHolder readOnly />}
        col2={<FormItem.SpecialInternationalRegistrationNumberPhoto noPlaceHolder readOnly />}
      />
      <FormItem.SubTitle title="会社住所情報" />
      <RowWithCol3
        col1={<FormItem.Zipcode noPlaceHolder readOnly form={form} />}
        col2={<FormItem.Prefecture noPlaceHolder readOnly />}
        col3={<FormItem.City noPlaceHolder readOnly />}
      />
      <RowWithCol2
        col1={<FormItem.Street noPlaceHolder readOnly />}
        col2={<FormItem.Building noPlaceHolder readOnly />}
      />

      <FormItem.SubTitle title="入金口座情報" />
      <RowWithCol2
        col1={<FormItem.BankCode noPlaceHolder readOnly />}
        col2={<FormItem.BankBranchCode noPlaceHolder readOnly form={form} />}
      />
      <RowWithCol2
        col1={<FormItem.BankAccountType noPlaceHolder readOnly />}
        col2={<FormItem.BankAccountNo noPlaceHolder readOnly />}
      />
      <FormItem.SubTitle title="買取り条件" />
      <FormItem.TermsOfPurchase noPlaceHolder readOnly />
      <FormItem.SubTitle title="プラン情報" />
      <RowWithCol2
        col1={<FormItem.IsAuction noPlaceHolder readOnly form={form} />}
        col2={<FormItem.PlanId noPlaceHolder readOnly form={form} />}
      />
    </Form>
  )
}

const RowWithCol2 = ({col1, col2}: {col1: React.ReactNode; col2: React.ReactNode}) => (
  <Row className="col2">
    <Col span={8}>{col1}</Col>
    <Col flex={1}>{col2}</Col>
  </Row>
)

const RowWithCol3 = ({col1, col2, col3}: {col1: React.ReactNode; col2: React.ReactNode; col3: React.ReactNode}) => (
  <Row className="col3">
    <Col span={8}>{col1}</Col>
    <Col span={8}>{col2}</Col>
    <Col span={8}>{col3}</Col>
  </Row>
)

export default memo(AccountForm)
