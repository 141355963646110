import {createAction} from 'redux-actions'

import {ContractConstants} from '@/store/constants'
import {Enums} from '@/constants'

export const ContractActions = {
  getPlansList: createAction<{planType: Enums.PlanTypeEnum}>(ContractConstants.GET_PLANS_LIST),
  setPlansList: createAction<{list: any; planType: Enums.PlanTypeEnum}>(ContractConstants.SET_PLANS_LIST),
  getSelectablePlansList: createAction(ContractConstants.GET_SELECTABLE_PLANS_LIST),
  setSelectablePlansList: createAction<{list: any}>(ContractConstants.SET_SELECTABLE_PLANS_LIST),
}
