/* eslint-disable func-names */
import {all, ForkEffect, put} from 'redux-saga/effects'
import {Action} from 'redux-actions'

import {authSaga} from './auth'
import {accountSaga} from './account'
import {contractSaga} from './contract'
import {contentSaga} from './content'
import {orderSaga} from './order'
import {otherSaga} from './other'
import {assessmentSaga} from './assessment'
import {pollingSaga} from './polling'
import {paymentSaga} from './payment'

const effectsLoadingWrapper = (effects: ForkEffect<never>[]) => {
  effects.forEach((effect) => {
    effect.payload.args = effect.payload.args.map((arg) => {
      if (arg.constructor?.name !== 'GeneratorFunction') return arg

      return function* (action: Action<any>) {
        if (action.type.includes('@@')) {
          yield arg(action)
        } else {
          yield put({type: `${action.type}@@LOADING_START`})
          yield arg(action)
          yield put({type: `${action.type}@@LOADING_END`})
        }
      }
    })
  })
  return effects
}

export const rootSaga = function* () {
  yield all(
    effectsLoadingWrapper([
      ...authSaga,
      ...accountSaga,
      ...contractSaga,
      ...contentSaga,
      ...orderSaga,
      ...assessmentSaga,
      ...pollingSaga,
      ...otherSaga,
      ...paymentSaga,
    ])
  )
}
