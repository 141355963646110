import React, {memo} from 'react'
import {useMount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {FormInstance} from 'antd/lib/form'

import _ from 'lodash'

import {ContentActions} from '@/actions'
import {ContentConstants} from '@/store/constants'

import InformationForm from '../../components/InformationForm'

const InformationItem = () => {
  const dispatch = useDispatch()
  const {id} = useParams()
  const {information, loading} = useSelector(
    (state) => ({
      information: _.get(state, ['content', 'informationDetails', id]),
      loading: _.get(state, ['loading', ContentConstants.UPDATE_INFORMATION]),
    }),
    _.isEqual
  )

  const onFinish = (values: any, _form: FormInstance) => {
    const newInformation = {...information, ...values}
    if (information && !_.isEqual(information, newInformation)) {
      dispatch(ContentActions.updateInformation({information: {...information, ...values}}))
    }
  }

  useMount(() => {
    id && dispatch(ContentActions.getInformation({id}))
  })

  return (
    <InformationForm
      title="お知らせ詳細"
      buttonLoading={loading}
      onFinish={onFinish}
      buttonTitle="変更"
      information={information}
    />
  )
}

export default memo(InformationItem)
