import _ from 'lodash'

import {handleActions} from '@/utils'
import {AccountConstants} from '@/store/constants'

const AccountState = {
  me: {} as any,
  myAccountCreation: {} as any,
  fullInfo: {} as any,
  details: {} as any,
  urls: {},
  registrationList: [],
  userList: [],
  myContractInfo: {} as any,
  myPlanInfo: {} as any,
}

const AccountReducer = handleActions(
  {
    [AccountConstants.SET_ME]: (state, {payload = {}}) => {
      const {account = {}, legalEntity = {}} = payload
      state.me = {
        ...account,
        ...legalEntity,
        ...account?.profile,
        legalEntityName: legalEntity.name,
        legalEntityNamePhonetic: legalEntity.namePhonetic,
      }
    },
    [AccountConstants.SET_ACCOUNT_CREATION]: (state, {payload = {}}) => {
      const {account = {}, urls = {}} = payload
      state.details[account?.id] = account
      Object.assign(state.urls, urls)
    },
    [AccountConstants.SET_MY_ACCOUNT_CREATION]: (state, {payload = {}}) => {
      const {account = {}, urls = {}} = payload
      state.myAccountCreation = account || {}
      Object.assign(state.urls, urls)
    },
    [AccountConstants.SET_LIST_ACCOUNT_CREATION]: (state, {payload = {}}) => {
      const {accounts = []} = payload
      state.registrationList = accounts
    },
    [AccountConstants.SET_LIST_ACCOUNTS]: (state, {payload = {}}) => {
      const {accounts = []} = payload
      state.userList = accounts
    },
    [AccountConstants.SET_ACCOUNT_FULL_INFO]: (state, {payload = {}}) => {
      const {account = {}, legalEntity = {}, bankAccount = {}, legalEntitySetting = {}, urls = {}} = payload
      _.set(state.fullInfo, [account?.id, 'flat'], {
        ...account,
        ...legalEntity,
        ...bankAccount,
        ...legalEntitySetting,
        ...account?.profile,
        legalEntityName: legalEntity.name,
        legalEntityNamePhonetic: legalEntity.namePhonetic,
      })
      _.set(state.fullInfo, [account?.userId, 'flat'], {
        ...account,
        ...legalEntity,
        ...bankAccount,
        ...legalEntitySetting,
        ...account?.profile,
        legalEntityName: legalEntity.name,
        legalEntityNamePhonetic: legalEntity.namePhonetic,
      })
      _.set(state.fullInfo, [account?.userId, 'origin'], payload)
      _.set(state.fullInfo, [account?.id, 'origin'], payload)
      Object.assign(state.urls, urls)
    },
    [AccountConstants.SET_LEGAL_ENTITY_CONTRACT]: (state, {payload = {}}) => {
      const {contractInfo} = payload
      state.myContractInfo = contractInfo
    },
  },
  AccountState
)

export {AccountReducer}
