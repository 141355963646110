import {Enums, EnumsText} from '@/constants'
import {getEnumTextByEnum} from '@/utils'
import dayjs from 'dayjs'
import _ from 'lodash'

// TODO: check
export const activeRequestStatusList = [
  Enums.AssessmentRequestStatusEnum.Ongoing,
  Enums.AssessmentRequestStatusEnum.Dealt,
]

export const isTransactionActive = (assessmentRequest: any) =>
  activeRequestStatusList.includes(assessmentRequest?.status)

export const canSellerDeciding = (assessmentRequest: any) => {
  const {status: assessmentRequestStatus, oneChanceDeadline = 0, deadline = 0, decideDeadline = 0, enterOneChance} =
    assessmentRequest || {}
  if (assessmentRequestStatus !== Enums.AssessmentRequestStatusEnum.Ongoing) {
    return false
  }

  if (dayjs().isBefore(dayjs.unix(deadline))) {
    return false
  }

  if (enterOneChance) {
    if (dayjs().isAfter(dayjs.unix(oneChanceDeadline)) && dayjs().isBefore(dayjs.unix(decideDeadline))) {
      return true
    }
  }

  // 初回査定終了––––––決定中
  if (dayjs().isBefore(dayjs.unix(decideDeadline))) {
    return true
  }
  return false
}

// reference: lib/domain/providers/StatusProvider.dart lib/domain/entity/assessmentDetail/AssessmentRequestInfo.dart
export const getDeadlineUsed: (
  assessmentRequest: any,
  assessmentReply: any
) => {deadlineUsed: number; deadlineUsedFinishedText?: string; deadlineUsedPrefixText?: string} = (
  assessmentRequest: any,
  assessmentReply: any
) => {
  const {status: assessmentRequestStatus, oneChanceDeadline = 0, deadline = 0, decideDeadline = 0, enterOneChance} =
    assessmentRequest || {}
  const defaultValue = 0
  if (assessmentRequestStatus !== Enums.AssessmentRequestStatusEnum.Ongoing) return {deadlineUsed: defaultValue}
  if (_.isEmpty(assessmentReply)) {
    /* 出品侧 */
    // FirstAssessing
    if (dayjs().isBefore(dayjs.unix(deadline))) {
      // 初回査定中 (Ongoing && beforeDeadline)
      return {deadlineUsed: deadline, deadlineUsedPrefixText: '初回査定 残り時間'}
    }
    // OneChanceAssessing
    if (enterOneChance && dayjs().isBefore(dayjs.unix(oneChanceDeadline))) {
      // ワンチャン査定中 (Ongoing && afterDeadline && beforeOneChanceDeadline
      return {deadlineUsed: oneChanceDeadline, deadlineUsedPrefixText: 'ワンチャン査定 残り時間'}
    }
    // Deciding
    if (enterOneChance && dayjs().isBefore(dayjs.unix(decideDeadline))) {
      // ワンチャン査定終了–––決定中
      return {deadlineUsed: decideDeadline, deadlineUsedPrefixText: 'ワンチャン査定決定中 残り時間'}
    }
    // Deciding
    if (!enterOneChance && dayjs().isBefore(dayjs.unix(decideDeadline))) {
      // 初回査定終了––––––決定中
      return {deadlineUsed: decideDeadline, deadlineUsedPrefixText: '初回査定決定中 残り時間'}
    }
  } else {
    const {status: assessmentReplyStatus, assessedAt} = assessmentReply
    /* 査定侧 */
    // FirstAssessing
    if (dayjs().isBefore(dayjs.unix(deadline))) {
      // 初回査定中 (Ongoing && beforeDeadline)
      return {deadlineUsed: deadline, deadlineUsedPrefixText: '初回査定 残り時間'}
    }
    // OneChanceAssessing
    if (
      enterOneChance &&
      dayjs().isBefore(dayjs.unix(oneChanceDeadline)) &&
      (assessmentReplyStatus === Enums.AssessmentReplyStatusEnum.FirstTimeReplied ||
        assessmentReplyStatus === Enums.AssessmentReplyStatusEnum.OneChanceReplied)
    ) {
      // ワンチャン査定中 (Ongoing && afterDeadline && beforeOneChanceDeadline
      return {deadlineUsed: oneChanceDeadline, deadlineUsedPrefixText: 'ワンチャン査定 残り時間'}
    }
    // Deciding
    if (enterOneChance && dayjs().isBefore(dayjs.unix(decideDeadline)) && assessedAt) {
      // ワンチャン査定終了–––決定中
      return {deadlineUsed: decideDeadline, deadlineUsedPrefixText: 'ワンチャン査定決定中 残り時間'}
    }
    // Deciding
    if (!enterOneChance && dayjs().isBefore(dayjs.unix(decideDeadline)) && assessedAt) {
      // 初回査定終了––––––決定中
      return {deadlineUsed: decideDeadline, deadlineUsedPrefixText: '初回査定決定中 残り時間'}
    }
  }

  return {deadlineUsed: defaultValue}
}

// as seller: AssessmentRequestStatus => TransactionStatus(AssessmentRequestStatus = Dealt)
// as buyer: AssessmentRequestStatus => AssessmentReplyStatus(AssessmentRequestStatus = Dealt | TransactionDone) => TransactionStatus(assessmentReplyStatus = Dealt)
export const getStatusText = (
  transactionStatus: Enums.TransactionStatusEnum,
  assessmentRequestStatus: Enums.AssessmentRequestStatusEnum,
  assessmentReplyStatus: Enums.AssessmentReplyStatusEnum,
  transactionStatusEnumText: any
) => {
  // as buyer
  if (assessmentReplyStatus) {
    if (
      assessmentRequestStatus === Enums.AssessmentRequestStatusEnum.Dealt ||
      assessmentRequestStatus === Enums.AssessmentRequestStatusEnum.TransactionDone
    ) {
      if (assessmentReplyStatus === Enums.AssessmentReplyStatusEnum.Dealt) {
        return getEnumTextByEnum(transactionStatus, transactionStatusEnumText)
      }
      return getEnumTextByEnum(assessmentReplyStatus, EnumsText.AssessmentReplyStatusEnum)
    }
    return getEnumTextByEnum(assessmentRequestStatus, EnumsText.AssessmentRequestStatusEnum)
  }

  // as seller
  if (assessmentRequestStatus === Enums.AssessmentRequestStatusEnum.Dealt) {
    return getEnumTextByEnum(transactionStatus, transactionStatusEnumText)
  }
  return getEnumTextByEnum(assessmentRequestStatus, EnumsText.AssessmentRequestStatusEnum)
}
