import React, {memo} from 'react'
import {useMount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {Divider} from 'antd'
import _ from 'lodash'

import {EnumsText} from '@/constants'
import {AssessmentActions} from '@/actions'
import LikeTabContainer from '@/components/LikeTabContainer'

import {CommodityInfo, AssessmentInfo, Title, EntityInfo} from '@/pages/customer/components/TransactionUtils'

const BuyDetails = () => {
  const dispatch = useDispatch()
  const {id}: any = useParams()
  const {detail} = useSelector(
    (state) => ({
      detail: _.get(state, ['assessment', 'transactionDetails', id], {}),
    }),
    _.isEqual
  )

  useMount(() => {
    id && dispatch(AssessmentActions.getMyAssessmentDetailForTransaction({id, isSeller: false}))
  })

  return (
    <LikeTabContainer title="査定対応詳細">
      <AssessmentInfo
        assessmentRequestInfo={detail.assessmentRequest}
        assessmentReplyInfo={detail.buyerReply}
        transactionInfo={detail.transaction}
        transactionStatusText={EnumsText.TransactionStatusEnum.FOR_BUYER}
      />
      <Divider />
      <Title>商品情報</Title>
      <CommodityInfo info={detail.item} />
      <Divider />
      <Title>取引会社情報</Title>
      <EntityInfo buyerInfo={detail.buyerReply} sellerInfo={detail.assessmentRequest} />
      <Divider />
      <Title>取引履歴</Title>
    </LikeTabContainer>
  )
}

export default memo(BuyDetails)
