import {handleActions} from '@/utils'
import {AuthConstants} from '@/store/constants'

const AuthState = {
  account: {},
  isLogin: undefined as undefined | boolean | null,
}

const AuthReducer = handleActions(
  {
    [AuthConstants.LOGIN_START]: (state) => {
      state.isLogin = undefined
    },
    [AuthConstants.LOGIN_SUCCESS]: (state, {payload}) => {
      state.account = payload.user
      state.isLogin = true
    },
    [AuthConstants.LOGIN_FAIL]: (state) => {
      state.account = {}
      state.isLogin = false
    },
  },
  AuthState
)

export {AuthReducer}
