import React from 'react'
import {Button} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {useBoolean, useMount, useUnmount} from 'ahooks'
import {Form, FormItem} from '@/components/Form'
import _ from 'lodash'

import {AuthActions, UIActions} from '@/actions'
import {buildType} from '@/utils'
import {AuthConstants} from '@/store/constants'
import SendResetPasswordTokenModal from '@/pages/common/components/sendResetPasswordTokenModal'

import styles from './styles.module.less'

export default () => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => _.get(state, ['loading', AuthConstants.LOGIN_START]))

  const onFinish = (values: any) => {
    dispatch(AuthActions.login(values))
  }

  useMount(() => {
    dispatch(UIActions.resetHeaderTrailing({headerTrailing: 'ログイン'}))
  })
  useUnmount(() => {
    dispatch(UIActions.resetHeaderTrailing({}))
  })

  const [
    sendResetPasswordTokenModalVisible,
    {setTrue: showSendResetPasswordTokenModal, setFalse: hideSendResetPasswordTokenModal},
  ] = useBoolean(false)
  return (
    <div className={styles.loginContainer}>
      <Form className={styles.loginForm} onFinish={onFinish}>
        <div className={styles.title}>ログイン</div>
        <FormItem.LoginEmail className={styles.email} label="" />
        <FormItem.Password label="" />
        <Form.Item>
          <span className={styles.forget} onClick={showSendResetPasswordTokenModal}>
            パスワードを忘れた方
          </span>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className={styles.login} loading={loading}>
            ログイン
          </Button>
        </Form.Item>
        {buildType === 'CUSTOMER' && (
          <Form.Item className={styles.register}>
            <Link to="/account/register/temporary">アカウント新規登録</Link>
          </Form.Item>
        )}
      </Form>
      <SendResetPasswordTokenModal
        visible={sendResetPasswordTokenModalVisible}
        onCancel={hideSendResetPasswordTokenModal}
      />
    </div>
  )
}
