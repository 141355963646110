import {buildType} from '@/utils'

interface MenuList {
  title: string
  key: string
  to?: string
  menuItems: {subTitle: string; key: string; to: string}[]
}

const adminSubMenuList = [
  {
    title: '処理待ち',
    key: 'sub1',
    menuItems: [
      {subTitle: '新規ユーザ登録申請', key: 'sub1-1', to: '/registration'},
      {subTitle: '入金確認依頼', key: 'sub1-2', to: '/confirmPaid'},
      {subTitle: '振込依頼', key: 'sub1-3', to: '/withdrawal'},
      {subTitle: '発送処理待ち', key: 'sub1-4', to: '/platformDeliveryToBuyer'},
      {subTitle: '返送処理待ち', key: 'sub1-5', to: '/platformDeliveryToSeller'},
      {subTitle: '返金処理待ち', key: 'sub1-6', to: '/refund'},
    ],
  },
  {title: '取引情報', key: 'sub2', to: '/transactions', menuItems: []},
  {title: 'ユーザ一覧', key: 'sub3', to: '/users', menuItems: []},
  {title: 'お知らせ一覧', key: 'sub4', to: '/informations', menuItems: []},
]

const customerSubMenuList = [
  {title: 'アカウント本登録', key: 'sub0', to: '/account/register/formal', menuItems: []},
  {
    title: '取引履歴',
    key: 'sub1',
    menuItems: [
      {subTitle: '出品履歴', key: 'sub1-1', to: '/sellHistories'},
      {subTitle: '査定履歴', key: 'sub1-2', to: '/buyHistories'},
    ],
  },
  {
    title: 'アカウント設定',
    key: 'sub2',
    menuItems: [
      {subTitle: 'パスワード変更', key: 'sub2-1', to: '/account/password/change'},
      {subTitle: 'メールアドレス変更', key: 'sub2-2', to: '/account/email/change'},
      {subTitle: 'サブアカウント申請', key: 'sub2-3', to: '/account/subAccount'},
      {subTitle: 'プラン確認', key: 'sub2-4', to: '/account/plan'},
    ],
  },
]

export const menuList: MenuList[] = buildType === 'ADMIN' ? adminSubMenuList : customerSubMenuList
