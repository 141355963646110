import React, {memo} from 'react'
import {Layout} from 'antd'
import classnames from 'classnames'

import {isMobile} from '@/utils'
import {ErrorBoundary} from '@/components/ErrorBoundaries'

import styles from './styles.module.less'

const LayoutContent = ({children}: {children: React.ReactNode}) => {
  return (
    <Layout>
      <Layout.Content className={classnames(styles.content, isMobile && styles.isMobile)}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Layout.Content>
    </Layout>
  )
}
export default memo(LayoutContent)
