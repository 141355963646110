import {Action} from 'redux-actions'
import {takeLatest, call, put} from 'redux-saga/effects'
import _ from 'lodash'

import {history} from '@/routes'
import {UIActions, AuthActions} from '@/actions'
import {AuthAPI} from '@/service'
import {Token} from '@/service/request'
import {backLogin} from '@/utils'

import {AuthConstants} from '../constants'

function* login({payload = {}}: any) {
  const {email, password} = payload
  try {
    yield call(AuthAPI.auth, {email, password})
    const lastView = new URLSearchParams(window?.location?.search).get('callback') || '/'
    history.push(lastView)
  } catch (err) {}
}

function* getAuthMe() {
  try {
    const res = yield call(AuthAPI.getMe)
    const requiredChangePassword = _.get(res, ['user', 'requiredChangePassword'])
    if (requiredChangePassword) {
      // first login
      history.push('/account/password/change')
      yield put(UIActions.showMessage({type: 'warning', message: 'パスワード再設定が必要です'}))
    } else {
      // not first login
      yield put(AuthActions.loginSuccess({user: res.user || {}}))
    }
  } catch (err) {
    yield put(AuthActions.loginFail())
  }
}

function* logout() {
  try {
    yield call(AuthAPI.revoke)
  } catch (err) {
    // todo ログ出力機能
  }
  Token.clearToken()
  backLogin()
}

function* changePassword({payload = {}}: Action<any>) {
  try {
    const {password, newPassword} = payload
    yield call(AuthAPI.changePassword, {password, newPassword})
    yield put(UIActions.showMessage({type: 'success', message: 'パスワードを変更しました'}))
    history.push('/')
  } catch (error) {}
}

function* resetPassword({payload = {}}: Action<any>) {
  try {
    const {token, newPassword} = payload
    yield call(AuthAPI.resetPassord, {token, newPassword})
    yield put(UIActions.showMessage({type: 'success', message: 'パスワードをリセットしました'}))
    history.push('/login')
  } catch (error) {}
}

function* requireResetPassword({payload = {}}: Action<any>) {
  const {email, onSuccess} = payload
  try {
    yield call(AuthAPI.requireResetPassword, {email})
    yield put(
      UIActions.showMessage({
        type: 'success',
        message: '入力いただいたメールアドレス宛てにパスワードリセットメール送付しました',
      })
    )
    onSuccess?.()
  } catch (err) {}
}

export const authSaga = [
  takeLatest(AuthConstants.LOGIN_START, login),
  takeLatest(AuthConstants.LOGOUT_START, logout),
  takeLatest(AuthConstants.GET_ME_START, getAuthMe),
  takeLatest(AuthConstants.RESET_PASSWORD, resetPassword),
  takeLatest(AuthConstants.CHANGE_PASSWORD, changePassword),
  takeLatest(AuthConstants.REQUIRE_RESET_PASSWORD, requireResetPassword),
]
