import React, {memo, FC} from 'react'
import {Button, Table} from 'antd'
import {CSVLink} from 'react-csv'
import classnames from 'classnames'
import {useDispatch, useSelector} from 'react-redux'
import {renderToStaticMarkup} from 'react-dom/server'
import _ from 'lodash'

import {TableProps, TablePaginationConfig} from 'antd/lib/table'
import {ColumnType, SorterResult, TableCurrentDataSource} from 'antd/lib/table/interface'
import {useMount} from 'ahooks'

import {TableConfigActions} from '@/actions'
import {DEFAULT_PAGE_SIZE, PAGE_SIZE_RANGE} from '@/constants'

import styles from './styles.module.less'

type IProps = TableProps<any> & {
  actionType?: string
  csvDownload?: boolean
  csvFilename?: string
}

export default memo(
  ({
    className,
    pagination,
    dataSource,
    columns,
    csvDownload,
    onChange,
    onRow,
    csvFilename,
    actionType = '',
    ...rest
  }: IProps) => {
    const dispatch = useDispatch()
    const {paginationConfig, loading} = useSelector(
      (state) => ({
        paginationConfig: _.get(state, ['tableConfig', actionType, 'pagination'], {}),
        loading: _.get(state, ['loading', actionType]),
      }),
      _.isEqual
    )

    useMount(() => {
      const {pagination: pageConfig, filters, sorter} = paginationConfig
      if (_.isEmpty(paginationConfig)) {
        dispatch(
          TableConfigActions.setTableConfig({
            type: actionType,
            config: {pagination: {current: 1, pageSize: DEFAULT_PAGE_SIZE}},
          })
        )
      }
      // check if keep tableConfig when change the sideBar menu
      onChange?.(pageConfig, filters, sorter, (dataSource || []) as any)
    })

    const handleTableChange: (
      pagination: TablePaginationConfig,
      filters: Record<string, React.ReactText[] | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
      extra: TableCurrentDataSource<any>
    ) => void = (pageConfig, filters, sorter, extra) => {
      dispatch(
        TableConfigActions.setTableConfig({
          type: actionType,
          config: {pagination: pageConfig, filters, sorter},
        })
      )
      onChange?.(pageConfig, filters, sorter, extra)
    }

    return (
      <>
        {csvDownload ? <CSVDownload dataSource={dataSource} columns={columns} filename={csvFilename} /> : null}
        <Table
          className={classnames(styles.table, className, onRow && styles.cursor)}
          onChange={handleTableChange}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          onRow={onRow}
          pagination={
            pagination === false
              ? false
              : {
                  position: ['bottomCenter'],
                  // TODO: check if can change size
                  // showSizeChanger: true,
                  defaultCurrent: paginationConfig.current || 1,
                  defaultPageSize: paginationConfig.pageSize || DEFAULT_PAGE_SIZE,
                  pageSizeOptions: PAGE_SIZE_RANGE.map((size) => String(size)),
                  total: paginationConfig.total || 0,
                  ...pagination,
                }
          }
          {...rest}
        />
      </>
    )
  },
  _.isEqual
)

const CSVDownload: FC<{dataSource?: any[]; columns?: ColumnType<any>[]; filename?: string}> = memo(
  ({dataSource, columns, filename}) => {
    const csvHeaders =
      columns?.map((column, index) => {
        const keyFromColumn =
          (Array.isArray(column.dataIndex) ? column.dataIndex?.join('.') : column.dataIndex) || column.key
        return {
          label: column.title,
          key: `${keyFromColumn}${index}`,
          keyFromColumn,
          render: column.render,
        }
      }) || []

    const csvData =
      dataSource?.map((record, index: number) => {
        const newRecord = {}
        csvHeaders.forEach((header) => {
          // use header.key and headet.keyFromColumn to prevent _.set(a,header.key,b) overwrite problems.(like key = 'a.b' and key = 'a')
          const {keyFromColumn, key} = header
          if (header.render && keyFromColumn) {
            try {
              _.set(
                newRecord,
                key,
                renderToStaticMarkup(header.render(_.get(record, keyFromColumn), record, index) as any).replace(
                  /(<([^>]+)>)/gi,
                  ''
                )
              )
            } catch (error) {
              console.log('renderToStaticMarkup error', error.message)
              _.set(newRecord, key, _.get(record, keyFromColumn))
            }
          } else if (keyFromColumn) {
            _.set(newRecord, key, _.get(record, keyFromColumn))
          }
        })
        return newRecord
      }) || []

    return (
      <div style={{marginBottom: 20, textAlign: 'right'}}>
        <Button type="ghost">
          <CSVLink data={csvData} headers={csvHeaders as any} filename={filename}>
            CSVダウンロード
          </CSVLink>
        </Button>
      </div>
    )
  }
)
