import {handleActions} from '@/utils'
import {ContractConstants} from '@/store/constants'

const ContractState = {
  plansList: {} as any,
}

const ContractReducer = handleActions(
  {
    [ContractConstants.SET_PLANS_LIST]: (state, {payload}) => {
      const {list = [], planType} = payload
      state.plansList[planType] = list
    },
    [ContractConstants.SET_SELECTABLE_PLANS_LIST]: (state, {payload}) => {
      const {list = []} = payload
      state.plansList[0] = list
    },
  },
  ContractState
)

export {ContractReducer}
