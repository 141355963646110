import React, {memo} from 'react'
import {Button} from 'antd'
import {useMount, useUnmount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import {Form, FormItem} from '@/components/Form'
import {UIActions, AccountActions} from '@/actions'
import {AccountConstants} from '@/store/constants'
import SinglePage from '@/components/SinglePage'
import {isMobile} from '@/utils'

const Email = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const loading = useSelector((state) => _.get(state, ['loading', AccountConstants.CHANGE_MY_EMAIL]))

  const onFinish = (values: any) => {
    dispatch(AccountActions.changeMyEmail(values))
  }

  useMount(() => {
    dispatch(UIActions.resetBackBreadcrumb({backWay: '/', backTitle: '戻る'}))
  })

  useUnmount(() => {
    dispatch(UIActions.resetBackBreadcrumb({}))
  })

  return (
    <div>
      <SinglePage
        title="メールアドレス変更"
        bottom={
          <div className="flexVertical">
            <Button type="primary" htmlType="submit" form="email_change" loading={loading}>
              変更
            </Button>
          </div>
        }>
        <FormItem.SubTitle
          title={
            <>
              新しいメールアドレスを入力してください。入力したメールアドレスに確認メールを送付いたします。
              <br />
              確認メールを開き、URLをタップして下さい。
            </>
          }
          style={{color: 'var(--DustyGray9)', left: isMobile ? 0 : -15}}
          withPrefix={false}
        />
        <Form form={form} name="email_change" onFinish={onFinish} defaultStyle="center">
          <FormItem.Email label="新しいメールアドレス" />
        </Form>
      </SinglePage>
    </div>
  )
}

export default memo(Email)
