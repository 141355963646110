import {request} from '@/service/request'
import {VERITRANS_API_KEY} from '@/constants'
// @ts-ignore
import AxiosJsonpAdapter from 'axios-jsonp'

export const OtherAPI = {
  payOrder: ({id, cardToken}: {id: string; cardToken: string}) =>
    request.post(`/api/v1/order/me/order/${id}:pay`, {
      content: {
        creditCardPayment: {
          cardToken,
        },
      },
    }),
  zipCloud: ({zipCode}: {zipCode: string}) =>
    request.get('https://zipcloud.ibsnet.co.jp/api/search', {
      params: {zipcode: zipCode},
      headers: {nt: false, st: false},
      adapter: AxiosJsonpAdapter,
    }),
  payOrderConfirm: ({orderId, version}: {orderId: string; version: string}) =>
    request.post(`/api/v1/order/${orderId}:confirmPaid`, {id: orderId, version}),

  getCreditCardToken: ({
    cardNumber,
    cardExpire,
    securityCode,
  }: {
    cardNumber: string
    cardExpire: string
    securityCode: string
  }) =>
    request.post(
      'https://api.veritrans.co.jp/4gtoken',
      {
        card_number: cardNumber,
        card_expire: cardExpire,
        security_code: securityCode,
        token_api_key: VERITRANS_API_KEY,
        lang: 'ja',
      },
      {headers: {nt: false, st: false}}
    ),
}
