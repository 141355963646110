/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import {createStore, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from 'redux-saga'

import {rootReducer} from './reducers'
import {rootSaga} from './sagas'

const sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]
if (process.env.NODE_ENV !== 'production') {
  const {createLogger} = require('redux-logger')
  middleware.push(
    createLogger({
      collapsed: true,
      predicate: (preState: any, {type}: {type: string}) => !new RegExp('.*@@.*').test(type),
    })
  )
}

const composeEnhancers =
  // @ts-ignore
  process.env.NODE_ENV === 'production' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(rootReducer, {} as any, composeEnhancers(applyMiddleware(...middleware)))

sagaMiddleware.run(rootSaga)
