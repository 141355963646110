import {createAction} from 'redux-actions'
import {ContentConstants} from '@/store/constants'
import {ActionCallback} from '@/utils'

export const ContentActions = {
  listBank: createAction(ContentConstants.LIST_BANK),
  listBankBranch: createAction<{bankCode: string}>(ContentConstants.LIST_BANK_BRANCH),
  listDeliveryCompanies: createAction(ContentConstants.LIST_DELIVERY_COMPANIES),
  listInformation: createAction(ContentConstants.LIST_INFORMATION),
  getInformation: createAction<{id: string}>(ContentConstants.GET_INFORMATION),
  createInformation: createAction<{information: any} & ActionCallback>(ContentConstants.CREATE_INFORMATION),
  updateInformation: createAction<{information: any} & ActionCallback>(ContentConstants.UPDATE_INFORMATION),

  setListBank: createAction<{list: any[]}>(ContentConstants.SET_LIST_BANK),
  setListBankBranch: createAction<{bankCode: string; list: any[]}>(ContentConstants.SET_LIST_BANK_BRANCH),
  setListDeliveryCompanies: createAction<{list: any[]}>(ContentConstants.SET_LIST_DELIVERY_COMPANIES),
  setListInformation: createAction<{list: any[]}>(ContentConstants.SET_LIST_INFORMATION),
  setGetInformation: createAction<{list: any[]}>(ContentConstants.SET_GET_INFORMATION),
}
