export const AuthConstants = {
  LOGIN_START: 'AUTH/LOGIN_START',
  LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
  LOGIN_FAIL: 'AUTH/LOGIN_FAIL',

  GET_ME_START: 'AUTH/GET_ME_START',

  LOGOUT_START: 'AUTH/LOGOUT_START',
  LOGOUT_SUCCESS: 'AUTH/LOGOUT_SUCCESS',

  RESET_PASSWORD: 'AUTH/RESET_PASSWORD',
  CHANGE_PASSWORD: 'AUTH/CHANGE_PASSWORD',
  REQUIRE_RESET_PASSWORD: 'AUTH/REQUIRE_RESET_PASSWORD',
}

export const AccountConstants = {
  REGISTER_START: 'ACCOUNT/REGISTER_START',
  REGISTER_SUCCESS: 'ACCOUNT/REGISTER_SUCCESS',
  UPGRADE_ME: 'ACCOUNT/UPGRADE_ME',
  VERIFY_UPGRADE_ME: 'ACCOUNT/VERIFY_UPGRADE_ME',
  GET_ME: 'ACCOUNT/GET_ME',
  CHANGE_MY_EMAIL: 'ACCOUNT/CHANGE_MY_EMAIL',
  VERIFY_EMAIL: 'ACCOUNT/VERIFY_EMAIL',
  UPDATE_ME: 'ACCOUNT/UPDATE_ME',
  GET_MY_ACCOUNT_CREATION: 'ACCOUNT/GET_MY_ACCOUNT_CREATION',
  LIST_LEGAL_ENTITY_ACCOUNTS: 'ACCOUNT/LIST_LEGAL_ENTITY_ACCOUNTS',
  LIST_ACCOUNTS: 'ACCOUNT/LIST_ACCOUNTS',
  GET_ACCOUNT_FULL_INFO: 'ACCOUNT/GET_ACCOUNT_FULL_INFO',
  LIST_ACCOUNT_CREATION: 'ACCOUNT/LIST_ACCOUNT_CREATION',
  GET_ACCOUNT_CREATION: 'ACCOUNT/GET_ACCOUNT_CREATION',
  REJECT_ACCOUNT_CREATION: 'ACCOUNT/REJECT_ACCOUNT_CREATION',
  APPROVE_ACCOUNT_CREATION: 'ACCOUNT/APPROVE_ACCOUNT_CREATION',
  CREATE_SUB_ACCOUNT: 'ACCOUNT/CREATE_SUB_ACCOUNT',
  GET_LEGAL_ENTITY_CONTRACT: 'ACCOUNT/GET_LEGAL_ENTITY_CONTRACT',
  RESEND_LEGAL_ENTITY_CONTRACT_ORDER: 'ACCOUNT/RESEND_LEGAL_ENTITY_CONTRACT_ORDER',

  SET_ACCOUNT_CREATION: 'ACCOUNT/SET_ACCOUNT_CREATION',
  SET_LIST_ACCOUNT_CREATION: 'ACCOUNT/SET_LIST_ACCOUNT_CREATION',
  SET_LIST_ACCOUNTS: 'ACCOUNT/SET_LIST_ACCOUNTS',
  SET_ACCOUNT_FULL_INFO: 'ACCOUNT/SET_ACCOUNT_FULL_INFO',
  SET_LEGAL_ENTITY_CONTRACT: 'ACCOUNT/SET_LEGAL_ENTITY_CONTRACT',
  SET_ME: 'ACCOUNT/SET_ME',
  SET_MY_ACCOUNT_CREATION: 'ACCOUNT/SET_MY_ACCOUNT_CREATION',
  CHANGE_PLAN: 'ACCOUNT/CHANGE_PLAN',
}

export const UIConstants = {
  SHOW_MESSAGE: 'UI/SHOW_MESSAGE',
  RESET_BACK_BREADCRUMB: 'UI/RESET_BACK_BREADCRUMB',
  RESET_HEADER_TRAILING: 'UI/RESET_HEADER_TRAILING',
}

export const ContractConstants = {
  GET_PLANS_LIST: 'CONTRACT/GET_PLANS_LIST',
  SET_PLANS_LIST: 'CONTRACT/SET_PLANS_LIST',
  GET_SELECTABLE_PLANS_LIST: 'CONTRACT/GET_SELECTABLE_PLANS_LIST',
  SET_SELECTABLE_PLANS_LIST: 'CONTRACT/SET_SELECTABLE_PLANS_LIST',
}

export const ContentConstants = {
  LIST_BANK: 'CONTENT/LIST_BANK',
  LIST_BANK_BRANCH: 'CONTENT/LIST_BANK_BRANCH',
  LIST_DELIVERY_COMPANIES: 'CONTENT/LIST_DELIVERY_COMPANIES',
  LIST_INFORMATION: 'CONTENT/LIST_INFORMATION',
  GET_INFORMATION: 'CONTENT/GET_INFORMATION',
  CREATE_INFORMATION: 'CONTENT/CREATE_INFORMATION',
  UPDATE_INFORMATION: 'CONTENT/UPDATE_INFORMATION',

  SET_LIST_BANK: 'CONTENT/SET_LIST_BANK',
  SET_LIST_BANK_BRANCH: 'CONTENT/SET_LIST_BANK_BRANCH',
  SET_LIST_DELIVERY_COMPANIES: 'CONTENT/SET_LIST_DELIVERY_COMPANIES',
  SET_LIST_INFORMATION: 'CONTENT/SET_LIST_INFORMATION',
  SET_GET_INFORMATION: 'CONTENT/SET_GET_INFORMATION',
}

export const TableConfigConstants = {
  SET_TABLE_CONFIG: '@@TABLE_CONFIG/SET_TABLE_CONFIG',
}

export const OrderConstants = {
  GET_ORDER: 'ORDER/GET_ORDER',
  REFUND_ORDER: 'ORDER/REFUND_ORDER',

  SET_ORDER: 'ORDER/SET_ORDER',
}

export const OtherConstants = {
  PAY_ORDER: 'OTHER/PAY_ORDER',
  ZIP_CLOUD: 'OTHER/ZIP_CLOUD',
  PAY_ORDER_CONFIRM: 'OTHER/PAY_ORDER_CONFIRM',
}

export const AssessmentConstants = {
  LIST_MY_TRANSACTION_HISTORIES_AS_SELLER: 'ASSESSMENT/LIST_MY_TRANSACTION_HISTORIES_AS_SELLER',
  LIST_MY_TRANSACTION_HISTORIES_AS_BUYER: 'ASSESSMENT/LIST_MY_TRANSACTION_HISTORIES_AS_BUYER',
  LIST_ADMIN_TRANSACTION_HISTORIES: 'ASSESSMENT/LIST_ADMIN_TRANSACTION_HISTORIES',
  LIST_ADMIN_TRANSACTION_HISTORIES_FOR_WITHDRAWAL: 'ASSESSMENT/LIST_ADMIN_TRANSACTION_HISTORIES_FOR_WITHDRAWAL',
  LIST_ADMIN_TRANSACTION_HISTORIES_FOR_PLATFORM_DELIVERY_TO_BUYER:
    'ASSESSMENT/LIST_ADMIN_TRANSACTION_HISTORIES_FOR_PLATFORM_DELIVERY_TO_BUYER',
  LIST_ADMIN_TRANSACTION_HISTORIES_FOR_PLATFORM_DELIVERY_TO_SELLER:
    'ASSESSMENT/LIST_ADMIN_TRANSACTION_HISTORIES_FOR_PLATFORM_DELIVERY_TO_SELLER',

  GET_MY_ASSESSMENT_DETAIL_FOR_TRANSACTION: 'ASSESSMENT/GET_MY_ASSESSMENT_DETAIL_FOR_TRANSACTION',
  GET_ASSESSMENT_DETAIL_FOR_TRANSACTION: 'ASSESSMENT/GET_ASSESSMENT_DETAIL_FOR_TRANSACTION',
  GET_ITEM_DETAIL: 'ASSESSMENT/GET_ITEM_DETAIL',
  DECLINE_ASSESSMENT: 'ASSESSMENT/DECLINE_ASSESSMENT',
  DECIDE_BUYER: 'ASSESSMENT/DECIDE_BUYER',
  WITHDRAWAL: 'ASSESSMENT/WITHDRAWAL',
  PLATFORM_DELIVERY_TO_BUYER: 'ASSESSMENT/PLATFORM_DELIVERY_TO_BUYER',
  PLATFORM_DELIVERY_TO_SELLER: 'ASSESSMENT/PLATFORM_DELIVERY_TO_SELLER',
  GET_DELIVERY_HISTORY_INFO: 'ASSESSMENT/GET_DELIVERY_HISTORY_INFO',
  GET_BUYER_DELIVERY_INFO: 'ASSESSMENT/GET_BUYER_DELIVERY_INFO',
  GET_SELLER_DELIVERY_INFO: 'ASSESSMENT/GET_SELLER_DELIVERY_INFO',

  SET_ITEM_DETAIL: 'ASSESSMENT/SET_ITEM_DETAIL',
  SET_LIST_TRANSACTION_HISTORIES: 'ASSESSMENT/SET_LIST_TRANSACTION_HISTORIES',
  SET_ASSESSMENT_DETAIL_FOR_TRANSACTION: 'ASSESSMENT/SET_ASSESSMENT_DETAIL_FOR_TRANSACTION',
  SET_DELIVERY_HISTORY_INFO: 'ASSESSMENT/SET_DELIVERY_HISTORY_INFO',
  SET_DELIVERY_INFO: 'ASSESSMENT/SET_DELIVERY_INFO',
}

export const PollingConstants = {
  POLLING_START: '@@POLLING/POLLING_START',
  POLLING_STOP: '@@POLLING/POLLING_STOP',
}

export const PaymentConstants = {
  LIST_AWAITING_REFUND_DATA: 'PAYMENT/LIST_AWAITING_REFUND_DATA',
  LIST_AWAITING_CONFIRM_PAYMENT_DATA: 'PAYMENT/LIST_AWAITING_CONFIRM_PAYMENT_DATA',

  SET_LIST_AWAITING_REFUND_DATA: 'PAYMENT/SET_LIST_AWAITING_REFUND_DATA',
  SET_LIST_AWAITING_CONFIRM_PAYMENT_DATA: 'PAYMENT/SET_LIST_AWAITING_CONFIRM_PAYMENT_DATA',
}
