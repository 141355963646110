import {request} from '@/service/request'
import {convertTableParams} from '@/utils'

export const AssessmentAPI = {
  // admin
  listTransactionHistories: (payload: any) =>
    request.get('/api/v1/assessment/transaction', {params: convertTableParams(payload)}),
  getAssessmentDetailForTransaction: ({id}: {id: string}) =>
    request.get(`/api/v1/assessment/assessmentRequest/${id}:detailForTransaction`),
  // customer
  listMyTransactionHistories: (payload: any) =>
    request.get('/api/v1/assessment/me/transactionHistories', {params: convertTableParams(payload)}),
  getMyAssessmentDetailForTransaction: ({id, isSeller}: {id: string; isSeller: boolean}) =>
    request.get(`/api/v1/assessment/me/assessmentRequest/${id}:detailForTransaction`, {params: {isSeller}}),
  getItemDetail: ({id}: {id: string}) => request.get(`/api/v1/assessment/item/${id}/detail`),
  decideBuyer: ({id, assessmentReplyId}: {id: string; assessmentReplyId: string}) =>
    request.post(`/api/v1/assessment/assessmentRequest/${id}:decideBuyer`, {id, assessmentReplyId}),
  declineAssessment: ({id}: {id: string}) => request.post(`/api/v1/assessment/me/assessmentRequest/${id}:decline`),
  withdrawal: ({transactionId, version}: {transactionId: string; version: string}) =>
    request.post(`/api/v1/assessment/transaction/${transactionId}:withdrawal`, {
      id: transactionId,
      version,
    }),
  platformDeliveryToBuyer: ({
    transactionId,
    version,
    trackNumber,
    deliveryCompanyId,
    deliveryCompanyOther,
  }: {
    transactionId: string
    version: string
    trackNumber: string
    deliveryCompanyId: string
    deliveryCompanyOther: string
  }) =>
    request.post(`/api/v1/assessment/transaction/${transactionId}:platformDeliveryToBuyer`, {
      id: transactionId,
      version,
      trackNumber,
      deliveryCompanyId,
      deliveryCompanyOther,
    }),
  platformDeliveryToSeller: ({
    transactionId,
    version,
    trackNumber,
    deliveryCompanyId,
    deliveryCompanyOther,
  }: {
    transactionId: string
    version: string
    trackNumber: string
    deliveryCompanyId: string
    deliveryCompanyOther: string
  }) =>
    request.post(`/api/v1/assessment/transaction/${transactionId}:platformDeliveryToSeller`, {
      id: transactionId,
      version,
      trackNumber,
      deliveryCompanyId,
      deliveryCompanyOther,
    }),
  getDeliveryHistoryInfo: ({transactionId}: {transactionId: string}) =>
    request.get(`/api/v1/assessment/transaction/${transactionId}/deliveryInfo`, {
      params: {id: transactionId},
    }),
  getBuyerDeliveryAddress: ({transactionId}: {transactionId: string}) =>
    request.get(`/api/v1/assessment/transaction/${transactionId}/deliveryAddress:buyer`, {
      params: {id: transactionId},
    }),
  getSellerDeliveryAddress: ({transactionId}: {transactionId: string}) =>
    request.get(`/api/v1/assessment/transaction/${transactionId}/deliveryAddress:seller`, {
      params: {id: transactionId},
    }),
}
