import {request, Token} from '@/service/request'
import {GrantTypeEnum} from '@/constants/enums'

export const AuthAPI = {
  auth: ({email, password}: {email: string; password: string}) =>
    request
      .post('/api/v1/auth/token', {email, password, grantType: GrantTypeEnum.PASSWORD}, {headers: {nt: false}})
      .then((tokenInfo: any) => {
        Token.updateToken(tokenInfo)
      }),
  revoke: () => request.post('/api/v1/auth/revoke', {refreshToken: Token.refreshToken}, {headers: {st: false}}),
  token: () =>
    request
      .post(
        '/api/v1/auth/token',
        {token: Token.refreshToken, grantType: GrantTypeEnum.REFRESH_TOKEN},
        {headers: {nt: false, st: false}}
      )
      .then((tokenInfo: any) => {
        Token.updateToken(tokenInfo)
      }),
  getMe: () => request.get('/api/v1/auth/me'),
  resetPassord: ({token, newPassword}: {token: string; newPassword: string}) =>
    request.post('/api/v1/auth/password:reset', {token, newPassword}, {headers: {nt: false}}),
  changePassword: ({password, newPassword}: {password: string; newPassword: string}) =>
    request.put('/api/v1/auth/me:changePassword', {password, newPassword}),
  requireResetPassword: ({email}: {email: string}) =>
    request.post('/api/v1/auth/password:requireReset', {email}, {headers: {nt: false}}),
}
