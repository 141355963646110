import {createAction} from 'redux-actions'

import {AssessmentConstants} from '@/store/constants'
import {ActionCallback} from '@/utils'

export const AssessmentActions = {
  listMyTransactionHistoriesAsSeller: createAction(AssessmentConstants.LIST_MY_TRANSACTION_HISTORIES_AS_SELLER),
  listMyTransactionHistoriesAsBuyer: createAction(AssessmentConstants.LIST_MY_TRANSACTION_HISTORIES_AS_BUYER),
  listAdminTransactionHistories: createAction(AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES),
  listAdminTransactionHistoriesForWithdrawal: createAction(
    AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_WITHDRAWAL
  ),
  listAdminTransactionHistoriesForPlatformDeliveryToBuyer: createAction(
    AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_PLATFORM_DELIVERY_TO_BUYER
  ),
  listAdminTransactionHistoriesForPlatformDeliveryToSeller: createAction(
    AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_PLATFORM_DELIVERY_TO_SELLER
  ),

  getMyAssessmentDetailForTransaction: createAction<{id: string; isSeller: boolean}>(
    AssessmentConstants.GET_MY_ASSESSMENT_DETAIL_FOR_TRANSACTION
  ),
  getAssessmentDetailForTransaction: createAction<{id: string}>(
    AssessmentConstants.GET_ASSESSMENT_DETAIL_FOR_TRANSACTION
  ),
  getItemDetail: createAction<{id: string}>(AssessmentConstants.GET_ITEM_DETAIL),
  declineAssessment: createAction<{id: string; itemId: string}>(AssessmentConstants.DECLINE_ASSESSMENT),
  decideBuyer: createAction<{id: string; assessmentReplyId: string; itemId: string}>(AssessmentConstants.DECIDE_BUYER),
  withdrawal: createAction<{transactionId: string; version: string} & ActionCallback>(AssessmentConstants.WITHDRAWAL),
  platformDeliveryToBuyer: createAction<
    {
      transactionId: string
      version: string
      trackNumber: string
      deliveryCompanyId: string
      deliveryCompanyOther: string
    } & ActionCallback
  >(AssessmentConstants.PLATFORM_DELIVERY_TO_BUYER),
  platformDeliveryToSeller: createAction<
    {
      transactionId: string
      version: string
      trackNumber: string
      deliveryCompanyId: string
      deliveryCompanyOther: string
    } & ActionCallback
  >(AssessmentConstants.PLATFORM_DELIVERY_TO_SELLER),
  getDeliveryHistoryInfo: createAction<{transactionId: string}>(AssessmentConstants.GET_DELIVERY_HISTORY_INFO),
  getBuyerDeliveryInfo: createAction<{transactionId: string}>(AssessmentConstants.GET_BUYER_DELIVERY_INFO),
  getSellerDeliveryInfo: createAction<{transactionId: string}>(AssessmentConstants.GET_SELLER_DELIVERY_INFO),

  setItemDetail: createAction<{detail: any}>(AssessmentConstants.SET_ITEM_DETAIL),
  setAssessmentDetailForTransaction: createAction(AssessmentConstants.SET_ASSESSMENT_DETAIL_FOR_TRANSACTION),
  setListTransactionHistories: createAction<{
    list: any
    type:
      | 'buyHistories'
      | 'sellHistories'
      | 'adminHistories'
      | 'adminHistoriesForWithdrawal'
      | 'adminHistoriesForPlatformDeliveryToBuyer'
      | 'adminHistoriesForPlatformDeliveryToSeller'
  }>(AssessmentConstants.SET_LIST_TRANSACTION_HISTORIES),
  setDeliveryHistoryInfo: createAction(AssessmentConstants.SET_DELIVERY_HISTORY_INFO),
  setDeliveryInfo: createAction(AssessmentConstants.SET_DELIVERY_INFO),
}
