/* eslint-disable no-param-reassign */
import {AxiosRequestConfig} from 'axios'

import {Token} from '@/service/request'
import {AuthAPI} from '@/service'

let refreshTokenTask: Promise<void> | null = null
const refreshTokenAndRequest = (config: AxiosRequestConfig) => {
  if (!Token.refreshToken) {
    throw new Error('NotLogin')
  }
  // refreshTokenが有効の場合
  // 重複申請を避けるため
  refreshTokenTask =
    refreshTokenTask ||
    AuthAPI.token().finally(() => {
      refreshTokenTask = null
    })
  return (
    refreshTokenTask
      .then(() => {
        config.headers.Authorization = `Bearer ${Token.accessToken}`
        return config
      })
      // refresh token fail
      .catch((e: Error) => {
        Token.clearToken()
        throw new Error(e.message)
      })
  )
}
type RequestInterceptor = (value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>
const requestInterceptor: RequestInterceptor = (config) => {
  // do something before request
  const {nt} = config.headers
  if (!nt) return config
  if (!Token.accessToken || Token.expireInTime < new Date().getTime()) {
    return refreshTokenAndRequest(config)
  }
  // キャッシュ利用
  config.headers.Authorization = `Bearer ${Token.accessToken}`
  return config
}

export default requestInterceptor
