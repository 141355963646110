import React, {FC, memo, useMemo} from 'react'
import {Layout, Breadcrumb} from 'antd'
import {history} from '@/routes'
import {useLocation} from 'react-router-dom'
import pathToRegexp from 'path-to-regexp'
import {useSelector} from 'react-redux'
import classnames from 'classnames'
import {isMobile} from '@/utils'
import _ from 'lodash'

import {menuList} from '../constant'
import styles from './styles.module.less'

const LayoutBreadcrumb: FC = ({children}) => {
  const {pathname} = useLocation()
  const {backBreadcrumbTitle, backBreadcrumbBackWay, backBreadcrumbTrailing} = useSelector(
    (state) => ({
      backBreadcrumbTitle: _.get(state, ['ui', 'backBreadcrumbTitle']),
      backBreadcrumbBackWay: _.get(state, ['ui', 'backBreadcrumbBackWay']),
      backBreadcrumbTrailing: _.get(state, ['ui', 'backBreadcrumbTrailing']),
    }),
    _.isEqual
  )
  const breadcrumbList = useMemo(() => {
    const temp: string[] = []
    const isMatch = menuList.some(({title, menuItems = [], to: mainTo}) => {
      temp[0] = title
      if (mainTo && pathToRegexp(mainTo).test(pathname)) {
        temp[1] && temp.pop()
        return true
      }
      return menuItems.some(({subTitle, to}) => {
        temp[1] = subTitle
        return pathToRegexp(to).test(pathname)
      })
    })
    return isMatch && !backBreadcrumbTitle ? temp : []
  }, [pathname, menuList, backBreadcrumbTitle])

  const goBack = () => {
    if (typeof backBreadcrumbBackWay === 'function') {
      backBreadcrumbBackWay()
    } else {
      backBreadcrumbBackWay ? history.push(backBreadcrumbBackWay) : history.goBack()
    }
  }

  const isAboutAccount =
    pathname.includes('/account/') || pathname.includes('/registration/details/') || pathname.includes('/user/details/')

  return (
    <Layout
      className={classnames(
        styles.breadcrumbLayout,
        isAboutAccount && styles.isAboutAccount,
        isMobile && styles.isMobile
      )}>
      <div className={styles.breadcrumbBar}>
        <Breadcrumb separator="" className={styles.breadcrumb} style={{height: isAboutAccount ? 35 : 39}}>
          {breadcrumbList.map((title, index) => (
            <React.Fragment key={title}>
              {index ? <Breadcrumb.Separator>&gt;</Breadcrumb.Separator> : null}
              <Breadcrumb.Item>{title}</Breadcrumb.Item>
            </React.Fragment>
          ))}
          {breadcrumbList.length || isMobile ? null : (
            <Breadcrumb.Item onClick={goBack} prefixCls="custom-back">
              ＜ {backBreadcrumbTitle || '戻る'}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
        {backBreadcrumbTrailing}
      </div>
      {children}
    </Layout>
  )
}

export default memo(LayoutBreadcrumb)
