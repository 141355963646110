import {createAction} from 'redux-actions'

import {PollingConstants} from '@/store/constants'

export const PollingActions = {
  pollingStart: createAction<{type: string; payload?: any; time: number; immediate?: boolean}>(
    PollingConstants.POLLING_START
  ),
  pollingStop: createAction<{type: string}>(PollingConstants.POLLING_STOP),
}
