import React, {memo} from 'react'

import {FormInstance} from 'antd/lib/form'

import {useCompareEffect} from '@/utils'
import {Form, FormItem} from '@/components/Form'
import {Button, Divider} from 'antd'

import dayjs from 'dayjs'
import {PlanGradeEnum} from '@/constants/enums'
import styles from './styles.module.less'

interface Props {
  information?: any
  onFinish?: (values: any, form: FormInstance) => void
}

const PlanForm = ({information, onFinish}: Props) => {
  const [form] = Form.useForm()

  const Finish = (values: any) => {
    onFinish?.(values, form)
  }

  useCompareEffect(() => {
    information && form.setFieldsValue(information)
  }, [information])

  if (information == null || information.currentPlan == null) {
    return (
      <Form
        form={form}
        onFinish={Finish}
        layout="horizontal"
        colon={false}
        hideRequiredMark
        className={styles.form}
        scrollToFirstError>
        <FormItem.SubTitle title="出品有無" />
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-label">出品：</div>
          <div className="ant-col ant-form-item-control">なし</div>
        </div>
        <FormItem.SubTitle title="現在のプラン" />
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-label">プラン名：</div>
          <div className="ant-col ant-form-item-control">プランなし</div>
        </div>
        <Divider style={{marginTop: 0}} />
        <div style={{textAlign: 'center'}}>
          <Button type="primary" htmlType="submit" className={styles.submitButton}>
            変更
          </Button>
        </div>
      </Form>
    )
  }

  let monthlyPutUpNumber
  let planName = ''

  if (information != null && information.currentPlan != null) {
    if (information.currentPlan.planGrade === PlanGradeEnum.Unlimited) {
      monthlyPutUpNumber = '無制限'
    } else {
      monthlyPutUpNumber = information.currentPlan.monthlyPutUpNumber
    }
    planName = information.currentPlan.planName
  }

  return (
    <Form
      form={form}
      onFinish={Finish}
      layout="horizontal"
      colon={false}
      hideRequiredMark
      className={styles.form}
      scrollToFirstError>
      <FormItem.SubTitle title="出品有無" />
      <div className="ant-row ant-form-item">
        <div className="ant-col ant-form-item-label">出品：</div>
        <div className="ant-col ant-form-item-control">あり</div>
      </div>
      <FormItem.SubTitle title="現在のプラン" />
      <div className="ant-row ant-form-item">
        <div className="ant-col ant-form-item-label">プラン名：</div>
        <div className="ant-col ant-form-item-control">{planName}</div>
      </div>
      <div className="ant-row ant-form-item">
        <div className="ant-col ant-form-item-label">料金（税込み）：</div>
        <div className="ant-col ant-form-item-control">
          {information.currentPlan != null
            ? `${Number(information.currentPlan.usageFeeIncludesTax * 2).toLocaleString('ja-JP', {
                style: 'currency',
                currency: 'JPY',
              })}→キャンペーン中につき50%OFFの${Number(information.currentPlan.usageFeeIncludesTax).toLocaleString(
                'ja-JP',
                {
                  style: 'currency',
                  currency: 'JPY',
                }
              )}`
            : ''}
        </div>
      </div>
      <div className="ant-row ant-form-item">
        <div className="ant-col ant-form-item-label">出品可能数：</div>
        <div className="ant-col ant-form-item-control">{monthlyPutUpNumber}</div>
      </div>
      <div className="ant-row ant-form-item">
        <div className="ant-col ant-form-item-label">現在のアカウント数：</div>
        <div className="ant-col ant-form-item-control">
          {information.currentPlan != null ? information.currentAccountNumber : ''}
        </div>
      </div>
      <div className="ant-row ant-form-item">
        <div className="ant-col ant-form-item-label">無料のアカウント数：</div>
        <div className="ant-col ant-form-item-control">
          {information.currentPlan != null ? information.currentPlan.freeAccountNumber : ''}
        </div>
      </div>
      <div className="ant-row ant-form-item">
        <div className="ant-col ant-form-item-label">最大利用アカウント数：</div>
        <div className="ant-col ant-form-item-control">
          {information.currentPlan != null ? information.currentPlan.maxAccountNumber : ''}
        </div>
      </div>
      <div className="ant-row ant-form-item">
        <div className="ant-col ant-form-item-label">利用期限：</div>
        <div className="ant-col ant-form-item-control">
          {information.currentPlan != null ? dayjs.unix(information.expiredAt).format('YYYY/MM/DD') : ''}
        </div>
      </div>
      <Divider style={{marginTop: 0}} />
      <div style={{textAlign: 'center'}}>
        <Button type="primary" htmlType="submit" className={styles.submitButton}>
          変更
        </Button>
      </div>
    </Form>
  )
}

export default memo(PlanForm)
