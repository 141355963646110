import _ from 'lodash'

import {handleActions} from '@/utils'
import {TableConfigConstants} from '@/store/constants'

const TableConfigState: {
  [actionName: string]: any
} = {}

const TableConfigReducer = handleActions(
  {
    [TableConfigConstants.SET_TABLE_CONFIG]: (state, {payload = {}}) => {
      const {type = '', config = {}} = payload
      config.pagination?.current && _.set(config, ['paging', 'currentPage'], config.pagination.current)
      config.pagination?.pageSize && _.set(config, ['paging', 'perOfPage'], config.pagination.pageSize)
      state[type] = _.merge(state[type], config)
    },
  },
  TableConfigState
)

export {TableConfigReducer}
