import React from 'react'

import {ColumnProps} from 'antd/lib/table'
import dayjs from 'dayjs'
import _ from 'lodash'

import {EnumsText} from '@/constants'
import Image from '@/components/Image'
import Countdown from '@/components/Countdown'

import {getDeadlineUsed, getStatusText, isTransactionActive} from '../components/TransactionUtils'

export const columns: ColumnProps<any>[] = [
  {
    title: '画像',
    dataIndex: ['item', 'mainImage', 'thumbUrl'],
    render: (url) => <Image src={url} width={60} height={60} inline />,
  },
  {
    title: '商品カテゴリ',
    dataIndex: ['item', 'categoryName'],
  },
  {
    title: '依頼日付',
    dataIndex: ['assessmentRequest', 'createdAt'],
    render: (text) => (
      <>
        {dayjs.unix(text).format('YYYY/MM/DD')}
        <br />
        {dayjs.unix(text).format('HH:mm')}
      </>
    ),
  },
  {
    title: '締め切り',
    dataIndex: 'assessmentRequest',
    render: (assessmentRequest, {assessmentReply}) => {
      const {deadlineUsed} = getDeadlineUsed(assessmentRequest, assessmentReply)
      return (
        <Countdown
          value={deadlineUsed}
          valueStyle={{fontFamily: 'HiraginoSansGB-W3', fontSize: 12, color: 'var(--MineShaft3)'}}
          finishRender="査定終了"
        />
      )
    },
  },
  {
    title: 'ステータス',
    dataIndex: 'status',
    render: (transactionStatus, record) =>
      getStatusText(
        transactionStatus,
        record?.assessmentRequest?.status,
        record?.assessmentReply?.status,
        EnumsText.TransactionStatusEnum.FOR_BUYER
      ),
  },
  {
    title: '査定額',
    dataIndex: 'price',
    render: (__, record) => {
      const price =
        Number(_.get(record, ['assessmentReply', 'oneChanceBiddingPrice'], 0)) ||
        Number(_.get(record, ['assessmentReply', 'firstBiddingPrice'], 0))
      return (
        <span style={{color: isTransactionActive(record.assessmentRequest) ? 'var(--Lipstick)' : 'var(--DustyGray9)'}}>
          {price.toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'})}
        </span>
      )
    },
  },
  {
    title: '最高査定金額',
    dataIndex: ['assessmentRequest', 'maxBiddingPrice'],
    render: (text, record) => (
      <span style={{color: isTransactionActive(record.assessmentRequest) ? 'var(--Lipstick)' : 'var(--DustyGray9)'}}>
        {Number(text).toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'})}
      </span>
    ),
  },
]
