import React, {memo} from 'react'

import {Input, Button, Divider} from 'antd'
import {FormInstance} from 'antd/lib/form'

import dayjs from 'dayjs'

import {useCompareEffect} from '@/utils'
import LikeTabContainer from '@/components/LikeTabContainer'
import {Form, FormItem} from '@/components/Form'

import styles from './styles.module.less'

interface Props {
  information?: any
  onFinish?: (values: any, form: FormInstance) => void
  title: string
  buttonTitle?: string
  buttonLoading?: boolean
}

const InformationForm = ({information, buttonLoading, onFinish, title, buttonTitle}: Props) => {
  const [form] = Form.useForm()

  const Finish = (values: any) => {
    onFinish?.(values, form)
  }

  useCompareEffect(() => {
    information && form.setFieldsValue(information)
  }, [information])

  const disabledStartAtDate = (current: number) =>
    // Can not select days after endAt and endAt
    current && dayjs(current).unix() > dayjs.unix(form.getFieldValue('endAt')).unix()

  const disabledEndAtDate = (current: number) =>
    // Can not select days before startAt and startAt
    current && dayjs(current).unix() < dayjs.unix(form.getFieldValue('startAt')).unix()

  return (
    <LikeTabContainer title={title}>
      <Form
        labelCol={{flex: '130px'}}
        wrapperCol={{flex: '492px'}}
        colon={false}
        form={form}
        onFinish={Finish}
        hideRequiredMark
        className={styles.form}>
        <Form.Item label="表示期間">
          <FormItem.DatePicker
            noStyle
            name="startAt"
            childProps={{
              placeholder: '掲載開始日時',
              showTime: {format: 'HH:mm:ss', defaultValue: dayjs('00:00:00', 'HH:mm:ss')},
              format: 'YYYY/MM/DD HH:mm:ss',
              disabledDate: disabledStartAtDate,
              style: {marginRight: 20},
            }}
          />
          <FormItem.DatePicker
            noStyle
            name="endAt"
            childProps={{
              placeholder: '掲載終了日時',
              showTime: {format: 'HH:mm:ss', defaultValue: dayjs('23:59:59', 'HH:mm:ss')},
              format: 'YYYY/MM/DD HH:mm:ss',
              disabledDate: disabledEndAtDate,
            }}
          />
        </Form.Item>
        <Form.Item label="お知らせタイトル" name="informationTitle" rules={[{required: true}]}>
          <Input.TextArea autoSize={{minRows: 1}} placeholder="入力してください" />
        </Form.Item>
        <Form.Item label="お知らせ内容" name="informationBody" rules={[{required: true}]}>
          <Input.TextArea autoSize={{minRows: 10, maxRows: 10}} placeholder="入力してください" />
        </Form.Item>
        <Divider style={{marginTop: 0}} />
        <div style={{textAlign: 'center'}}>
          <Button type="primary" htmlType="submit" className={styles.submitButton} loading={buttonLoading}>
            {buttonTitle}
          </Button>
        </div>
      </Form>
    </LikeTabContainer>
  )
}

export default memo(InformationForm)
