import {combineReducers} from 'redux'
import {LoadingReducer as loading} from './loading'
import {AuthReducer as auth} from './auth'
import {UIReducer as ui} from './ui'
import {AccountReducer as account} from './account'
import {ContractReducer as contract} from './contract'
import {ContentReducer as content} from './content'
import {TableConfigReducer as tableConfig} from './tableConfig'
import {OrderReducer as order} from './order'
import {AssessmentReducer as assessment} from './assessment'
import {PollingReducer as polling} from './polling'
import {PaymentReducer as payment} from './payment'

export const rootReducer = combineReducers({
  auth,
  ui,
  account,
  loading,
  contract,
  content,
  tableConfig,
  order,
  assessment,
  polling,
  payment,
})
