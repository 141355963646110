import React, {memo, useState, useEffect, useCallback, useMemo, useRef} from 'react'
import {Upload, Progress, Image as AntdImage} from 'antd'
import {UploadProps} from 'antd/lib/upload'
import classnames from 'classnames'
import _ from 'lodash'

import {UploadTypeEnum} from '@/constants/enums'
import {PlusOutlined} from '@ant-design/icons'
import {useBoolean} from 'ahooks'
import {RcCustomRequestOptions, RcFile, UploadChangeParam, UploadFile} from 'antd/lib/upload/interface'
import {FileAPI} from '@/service'
import {useDispatch, useSelector} from 'react-redux'
import {UIActions} from '@/actions'
import {store} from '@/store'

import styles from './styles.module.less'

type IProps = Omit<UploadProps<any>, 'onChange'> & {
  uploadType?: UploadTypeEnum
  label?: string
  values?: any
  onChange?: (fieldId?: string) => void
}

const isImageFileType = (type: string): boolean => type.includes('image/')

const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB

const transformFile: <T>(file: File, type: 'readAsArrayBuffer' | 'readAsDataURL') => Promise<T> = (file, type) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader[type]?.(file)
    reader.onload = () => resolve(reader.result as any)
    reader.onerror = () => {
      const errMessage = `${file.name} transform file fail`
      store.dispatch(UIActions.showMessage({message: errMessage, type: 'error'}))
      reject(new Error(errMessage))
    }
  })
}

const customRequest = async ({file, onSuccess, onError, onProgress, data}: RcCustomRequestOptions) => {
  const contentLength = file.size
  const {uploadType} = data as {uploadType: UploadTypeEnum}
  try {
    const {uploadUrl, fileId} = ((await FileAPI.presignedUpload({
      uploadType,
      contentLength,
    })) as unknown) as {uploadUrl: string; fileId: string}
    const fileBinary = await transformFile<ArrayBuffer>(file, 'readAsArrayBuffer')

    await FileAPI.upload({
      uploadUrl,
      uploadType,
      data: fileBinary,
      onUploadProgress: (event) => {
        const {loaded, total} = event
        const percent = Number(Math.round((loaded / total) * 100).toFixed(2))
        event.percent = percent
        onProgress(event, file)
      },
    })
    await FileAPI.finishUpload({uploadType, fileId})
    onSuccess({fileId}, file)
  } catch (error) {
    onError(error)
  }
}

export default memo(
  ({
    className,
    onChange: triggerChange,
    label,
    disabled,
    values, // pass from Form
    uploadType = UploadTypeEnum.Public,
    ...rest
  }: IProps) => {
    const dispatch = useDispatch()
    const [loading, {setTrue: setLoadingTrue, setFalse: setLoadingFalse}] = useBoolean(false)
    const [imageUrl, setImageUrl] = useState('')
    const lastImageUrlRef = useRef<string>('')
    const [uploadPercent, setUploadPercent] = useState(0)
    const urls = useSelector((state: any) => _.get(state, ['account', 'urls'], {}), _.isEqual)
    // save fileId
    const [value, setValue] = useState<string>()

    const beforeUpload = async (file: RcFile) => {
      if (!isImageFileType(file.type)) {
        dispatch(UIActions.showMessage({type: 'error', message: `${file.name} is not a png file`}))
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(`${file.name} is not a png file`)
      }
      if (file.size >= MAX_FILE_SIZE) {
        dispatch(UIActions.showMessage({type: 'error', message: `${file.name} size is over 10M`}))
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(`${file.name} size is over 10M`)
      }

      setLoadingTrue()
      setUploadPercent(0)

      try {
        const url = await transformFile<string>(file, 'readAsDataURL')
        lastImageUrlRef.current = imageUrl
        setImageUrl(url)
      } catch (error) {
        console.error(`transform dataUrl error ${error.message}`)
      }

      return Promise.resolve()
    }

    const onProgress = ({percent}: {percent: number}, _file: File) => {
      setUploadPercent(percent)
    }

    const onSuccess = ({fileId}: {fileId: string}) => {
      setValue(fileId)
      setLoadingFalse()
      triggerChange?.(fileId)
    }

    const onError = () => {
      setImageUrl(lastImageUrlRef.current)
      setLoadingFalse()
      triggerChange?.('')
    }

    const onChange = (_info: UploadChangeParam<UploadFile<any>>) => {
      triggerChange?.(value)
    }

    useEffect(() => {
      if (!imageUrl && values) {
        const urlUsed = _.get(urls, [values, 'thumbnailUrl']) || _.get(urls, [values, 'url'])
        setImageUrl(urlUsed)
      }
    }, [values])

    const bigImageUrl = useMemo(
      () => _.get(urls, [values, 'url']) || _.get(urls, [values, 'thumbnailUrl']) || imageUrl,
      [imageUrl, values]
    )

    const Content = useCallback(() => {
      if (imageUrl) {
        const size = 80
        return (
          <AntdImage
            src={bigImageUrl}
            width={size}
            height={size}
            preview={disabled || Boolean(imageUrl)}
            alt=""
            placeholder={<AntdImage src={imageUrl} alt="" preview={false} placeholder width={size} height={size} />}
          />
        )
      }
      if (disabled) return null
      return (
        <>
          <PlusOutlined style={{fontSize: 20, color: 'var(--Alto7)'}} />
          <div className={styles.label}>{label || 'Upload'}</div>
        </>
      )
    }, [imageUrl, label, disabled, bigImageUrl])

    return (
      <Upload
        disabled={disabled || loading || Boolean(imageUrl)}
        className={classnames(styles.upload, className)}
        listType="picture-card"
        showUploadList={false}
        customRequest={customRequest}
        // @ts-ignore
        onSuccess={onSuccess}
        // @ts-ignore
        onError={onError}
        onProgress={onProgress}
        beforeUpload={beforeUpload}
        onChange={onChange}
        data={{uploadType}}
        {...rest}>
        <div style={{position: 'relative'}}>
          <Content />
          {loading && <Progress percent={uploadPercent} size="small" showInfo={false} className={styles.progress} />}
        </div>
      </Upload>
    )
  }
)
