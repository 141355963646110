import React from 'react'
import {Form as AntdForm} from 'antd'
import {FormProvider as AntdFormProvider} from 'antd/lib/form/context'
import {FormInstance, FormProps} from 'antd/lib/form'
import {useResponsive} from 'ahooks'
import classnames from 'classnames'
import _ from 'lodash'

import {getConvertValues, convertFields, validateMessages} from './FormUtil'
import {FormItem} from './FormItem'
import formStyles from './form.module.less'

interface Props {
  defaultStyle?: 'around' | 'center'
}

interface AntdFormWithCustomProps
  extends React.ForwardRefExoticComponent<FormProps & React.RefAttributes<FormInstance> & Props> {
  useForm: typeof AntdForm.useForm
  Item: typeof AntdForm.Item
  List: typeof AntdForm.List
  Provider: typeof AntdFormProvider
}

// @ts-ignore
const Form: AntdFormWithCustomProps = ({form, onFinish, initialValues, className, defaultStyle, ...formProps}) => {
  const responsive = useResponsive()

  const customOnFinish = (values: {[field: string]: string}) => {
    const filedsValue = form?.getFieldsValue(convertFields)
    const convertValues = getConvertValues(filedsValue)
    const newValues = {...values, ...convertValues}
    onFinish?.(newValues)
  }
  const customInitialValues = () => {
    if (!initialValues) return initialValues
    const filedsValue = _.pick(initialValues, convertFields)
    const convertValues = getConvertValues(filedsValue, true)
    const newInitialValues = {...initialValues, ...convertValues}
    return newInitialValues
  }

  const defaultStyleCenterProps =
    defaultStyle === 'center'
      ? {
          labelCol: {
            xs: {span: 24},
            sm: responsive.md ? {span: 10} : {span: 24},
          },
          wrapperCol: {xs: {span: 24}, sm: {span: 14}},
          colon: false,
          layout: (responsive.md ? 'horizontal' : 'vertical') as 'vertical',
          hideRequiredMark: true,
        }
      : {}

  return (
    <AntdForm
      scrollToFirstError
      validateMessages={validateMessages}
      onFinish={customOnFinish}
      form={form}
      initialValues={customInitialValues()}
      className={classnames(className, defaultStyle && formStyles[defaultStyle])}
      {...defaultStyleCenterProps}
      {...formProps}
    />
  )
}

Form.useForm = AntdForm.useForm
Form.useForm = AntdForm.useForm
Form.Item = AntdForm.Item
Form.List = AntdForm.List
Form.Provider = AntdFormProvider

export {FormItem, Form}
