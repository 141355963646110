import React, {useEffect} from 'react'
import {Button} from 'antd'
import {useBoolean, useMount, useUnmount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'
import {Form, FormItem} from '@/components/Form'

import {isMobile} from '@/utils'
import SinglePage from '@/components/SinglePage'
import {UIActions, AccountActions} from '@/actions'
import {AccountConstants} from '@/store/constants'

import _ from 'lodash'

const SubAccount = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [isConfirming, {setTrue: setIsConfirming, setFalse: setIsNotConfirming}] = useBoolean(false)
  const {loading, myContractInfo} = useSelector(
    (state) => ({
      loading: _.get(state, ['loading', AccountConstants.CREATE_SUB_ACCOUNT]),
      myContractInfo: _.get(state, ['account', 'myContractInfo'], {}),
    }),
    _.isEqual
  )

  // maxAccountNumber = mainAccount(1) + subAccount(x)
  const defaultValue = '--'
  const maxAccountNumber = _.get(myContractInfo, ['currentPlan', 'maxAccountNumber'], defaultValue)
  const currentAccountNumber = _.get(myContractInfo, ['currentAccountNumber'], defaultValue)
  const hasSurplusAccount = maxAccountNumber > currentAccountNumber
  const currentSubAccountNumber = currentAccountNumber === defaultValue ? defaultValue : currentAccountNumber - 1
  const maxSubAccountNumber = maxAccountNumber === defaultValue ? defaultValue : maxAccountNumber - 1

  useMount(() => {
    dispatch(AccountActions.getLegalEntityContract())
    dispatch(UIActions.resetBackBreadcrumb({backWay: '/', backTitle: '戻る'}))
  })
  useUnmount(() => {
    dispatch(UIActions.resetBackBreadcrumb({}))
  })

  useEffect(() => {
    document.body.scrollIntoView?.()
  }, [isConfirming])

  const backToNotConfirming = () => {
    setIsNotConfirming()
    dispatch(UIActions.resetBackBreadcrumb({backWay: '/', backTitle: '戻る'}))
  }

  const setBackBreadcrumbWhenIsComfiming = () => {
    dispatch(
      UIActions.resetBackBreadcrumb({
        backTitle: '戻る',
        backWay: backToNotConfirming,
      })
    )
  }

  const onFinish = (values: {[field: string]: string}) => {
    if (isConfirming) {
      dispatch(AccountActions.createSubAccount({subAccountInfo: values}))
    } else {
      setIsConfirming()
      setBackBreadcrumbWhenIsComfiming()
    }
  }

  return (
    <div>
      <SinglePage
        title={isConfirming ? 'アカウント追加申請確認' : 'アカウント追加申請'}
        bottom={
          <div className="flexVertical">
            <div className="buttonGroup">
              {isConfirming ? <Button onClick={backToNotConfirming}>戻る</Button> : null}
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                form="create_subAccount"
                disabled={!hasSurplusAccount}>
                {isConfirming ? '申請' : '確認'}
              </Button>
            </div>
          </div>
        }>
        <Form form={form} name="create_subAccount" layout="vertical" onFinish={onFinish} defaultStyle="around">
          <FormItem.SubTitle
            title={
              <>
                {`現在、${currentSubAccountNumber}個のサブアカウントが利用中です。`}
                <br />
                {`サブアカウントは、${maxSubAccountNumber}個まで無料でご利用頂けます。`}
                <br />
                {`サブアカウントを${maxSubAccountNumber}個以上ご利用になられる場合は、追加料金がかかります。`}
              </>
            }
            withPrefix={false}
            style={{color: 'var(--DustyGray9)', left: isMobile ? 0 : -15}}
          />

          <FormItem.SubTitle title="申請者情報" />
          <FormItem.FamilyName label="対象者姓" readOnly={isConfirming} />
          <FormItem.GivenName label="対象者名" readOnly={isConfirming} />
          <FormItem.PlaceHolder inline />
          <FormItem.PlaceHolder block />
          <FormItem.FamilyNamePhonetic label="対象者姓（カナ）" readOnly={isConfirming} />
          <FormItem.GivenNamePhonetic label="対象者名（カナ）" readOnly={isConfirming} />
          <FormItem.PlaceHolder inline />
          <FormItem.PlaceHolder block />
          <FormItem.Email readOnly={isConfirming} />
          <FormItem.PlaceHolder block />
          <FormItem.IdentityVerificationPhoto readOnly={isConfirming} />
          <FormItem.IdCardPhoto readOnly={isConfirming} />
          <FormItem.PlaceHolder inline />
        </Form>
      </SinglePage>
    </div>
  )
}

export default SubAccount
