import {request} from '@/service/request'
import {convertTableParams} from '@/utils'
import {MailVerifyTypeEnum} from '@/constants/enums'

export const AccountAPI = {
  register: (payload: any) => request.post('/api/v1/account:register', payload, {headers: {nt: false}}),
  upgradeMe: (payload: any) => request.post('/api/v1/account/me/accountCreation:upgrade', payload),
  verifyUpgradeMe: (payload: any) => request.post('/api/v1/account/me/accountCreation:verifyUpgrade', payload),

  getMe: () => request.get('/api/v1/account/me'),
  changeMyEmail: ({email}: {email: string}) => request.post('/api/v1/account/me:changeEmail', {email}),
  emailVerify: ({token, verifyType}: {token: string; verifyType: MailVerifyTypeEnum}) =>
    request.get('/api/v1/account/mail:verify', {params: {token, verifyType}, headers: {nt: false}}),
  updateMe: (payload: any) => request.put('/api/v1/account/me', payload),
  getMyAccountCreation: () => request.get('/api/v1/account/me/accountCreation'),
  listLegalEntityAccounts: (payload: any) => request.get('/api/v1/account:user', {params: payload}),
  listAccounts: (payload: any) => request.get('/api/v1/account', {params: convertTableParams(payload)}),
  getAccountFullInfo: ({accountId}: {accountId: string}) =>
    request.get(`/api/v1/account/${accountId}/fullInfo`, {headers: {st: false}}),
  getUserFullInfo: ({userId}: {userId: string}) => request.get(`/api/v1/user/${userId}/fullInfo`),
  listAccountCreation: (payload: any) =>
    request.get('/api/v1/account/accountCreation', {params: convertTableParams(payload)}),
  getAccountCreation: ({id}: {id: string}) => request.get(`/api/v1/account/accountCreation/${id}`),
  rejectAccountCreation: (payload: any) => request.post('/api/v1/account/accountCreation:reject', payload),
  approveAccountCreation: (payload: any) => request.post('/api/v1/account/accountCreation:approve', payload),
  createSubAccount: (payload: any) => request.post('/api/v1/account/me/subAccount', payload),
  getLegalEntityContract: () => request.get('/api/v1/account/me/legalEntity/contract'),
  resendLegalEntityContractOrder: () => request.post('/api/v1/account/me/legalEntity/contract:resendOrder'),
  changePlan: ({planId}: {planId: string}) => request.put(`/api/v1/account/me/legalEntity/contract`, {planId}),
}
