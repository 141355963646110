import {request} from '@/service/request'
import {UploadTypeEnum} from '@/constants/enums'
import {getEnumTextByEnum} from '@/utils'
import {EnumsText} from '@/constants'

export const FileAPI = {
  presignedUpload: (payload: {uploadType: UploadTypeEnum; contentLength: number}) =>
    request.post('/api/v1/file:upload', payload, {headers: {nt: false}}),
  finishUpload: (payload: {uploadType: UploadTypeEnum; fileId: string}) =>
    request.post('/api/v1/file:finish', payload, {headers: {nt: false}}),
  upload: ({
    uploadUrl,
    data,
    uploadType,
    onUploadProgress,
  }: {
    uploadUrl: string
    uploadType: UploadTypeEnum
    data: ArrayBuffer
    onUploadProgress?: (progressEvent: any) => void
  }) =>
    request.put(uploadUrl, data, {
      headers: {
        'x-amz-acl': getEnumTextByEnum(uploadType, EnumsText.UploadTypeEnum),
        nt: false,
        st: false,
      },
      onUploadProgress,
    }),
}
