import React, {memo} from 'react'
import {Button} from 'antd'
import {useMount, useUnmount, useBoolean} from 'ahooks'
import {useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import pathToRegexp from 'path-to-regexp'
import _ from 'lodash'

import {Form, FormItem} from '@/components/Form'
import {AuthActions, UIActions} from '@/actions'
import {AuthConstants} from '@/store/constants'
import SinglePage from '@/components/SinglePage'
import SendResetPasswordTokenModal from '@/pages/common/components/sendResetPasswordTokenModal'

import styles from './styles.module.less'

enum ActionTypeEnum {
  RESET = 'reset',
  CHANGE = 'change',
}

const Password = () => {
  const {pathname, search} = useLocation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const loading = useSelector(
    (state) =>
      _.get(state, ['loading', AuthConstants.RESET_PASSWORD]) ||
      _.get(state, ['loading', AuthConstants.CHANGE_PASSWORD])
  )
  const [
    sendResetPasswordTokenModalVisible,
    {setTrue: showSendResetPasswordTokenModal, setFalse: hideSendResetPasswordTokenModal},
  ] = useBoolean(false)

  // /account/password/reset
  // /account/password/change
  const parser = pathToRegexp('/account/password/:actionType').exec(pathname)
  const actionType = parser?.[1] as ActionTypeEnum
  const defaultToken = new URLSearchParams(search).get('token')
  const formName = `password_${actionType}`

  useMount(() => {
    dispatch(
      UIActions.resetBackBreadcrumb({backWay: actionType === ActionTypeEnum.RESET ? '/login' : '/', backTitle: '戻る'})
    )
  })
  useUnmount(() => {
    dispatch(UIActions.resetBackBreadcrumb({}))
  })

  const onFinish = (values: any) => {
    if (actionType === ActionTypeEnum.RESET) {
      dispatch(AuthActions.resetPassword(values))
    }
    if (actionType === ActionTypeEnum.CHANGE) {
      dispatch(AuthActions.changePassword(values))
    }
  }

  return (
    <div>
      <SinglePage
        title={actionType === ActionTypeEnum.CHANGE ? 'パスワード変更' : 'パスワードリセット'}
        bottom={
          <div className="flexVertical">
            <Button type="primary" htmlType="submit" form={formName} loading={loading}>
              変更
            </Button>
            {actionType === ActionTypeEnum.RESET && (
              <span className={styles.sendAgain} onClick={showSendResetPasswordTokenModal}>
                リセットコードを再送する
              </span>
            )}
          </div>
        }>
        {actionType === ActionTypeEnum.RESET && (
          <FormItem.SubTitle
            style={{textAlign: 'center', color: 'var(--DustyGray9)'}}
            title="リセットコードを入力し、新しいパスワードを設定して下さい"
            withPrefix={false}
          />
        )}
        <Form form={form} name={formName} onFinish={onFinish} defaultStyle="center">
          {actionType === ActionTypeEnum.RESET && (
            <FormItem.Token style={{marginTop: 50}} initialValue={defaultToken} />
          )}
          {actionType === ActionTypeEnum.CHANGE && <FormItem.Password style={{marginTop: 70}} />}
          <FormItem.NewPassword />
          <FormItem.ConfirmNewPassword />
        </Form>
      </SinglePage>
      <SendResetPasswordTokenModal
        visible={sendResetPasswordTokenModalVisible}
        onCancel={hideSendResetPasswordTokenModal}
      />
    </div>
  )
}

export default memo(Password)
