import {RouteProps, RouteComponentProps} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useMount} from 'ahooks'
import {AuthActions} from '@/actions'
import {backLogin} from '@/utils'

import _ from 'lodash'

const AuthWrapper = (props: RouteProps & RouteComponentProps) => {
  const dispatch = useDispatch()
  const isLogin = useSelector((state) => _.get(state, ['auth', 'isLogin']))
  useMount(() => {
    !isLogin && dispatch(AuthActions.getMe())
  })

  if (isLogin === true) return props.children

  if (isLogin === false) return backLogin(true)

  return 'ローディング中です。しばらくお待ちください。'
}

export default AuthWrapper
